import React from 'react'

function InfoHotspotIcon() {
  return (
    <svg
      // width="50" height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="25"
        cy="25"
        r="23.5"
        fill="#2F6785"
        fillOpacity="0.7"
        stroke="white"
        strokeWidth="3"
      />
      <circle cx="25" cy="25" r="18.5" fill="#2F6785" fillOpacity="0.7" stroke="white" />
      <path
        d="M25 41C16.1634 41 9 33.8366 9 25C9 16.1634 16.1634 9 25 9C33.8366 9 41 16.1634 41 25C40.9903 33.8325 33.8325 40.9903 25 41ZM21.784 23.384V26.6H23.384V33H28.216V29.8H26.6L26.616 23.3856L21.784 23.384ZM23.384 17V20.2304H26.616V17H23.384Z"
        fill="white"
      />
    </svg>
  )
}

export default InfoHotspotIcon
