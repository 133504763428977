import React from 'react'
import PropTypes from 'prop-types'

function InfoHSDescription({ desc }) {
  return desc
    .split(/\r?\n/)
    ?.filter((x) => x != '')
    .map((pair, index) => {
      let idk = pair.split(':')
      return (
        <>
          {/* {console.log('dec: ' + pair.includes(':'))} */}
          {pair.includes(':') ? (
            <div
              style={{ marginTop: '1vh', marginBottom: 0, display: 'flex' }}
              key={index}
              id="disableScroll"
            >
              <b style={{ color: 'white', width: '50%' }}>{idk[0]}</b>
              <div style={{ textAlign: 'right' }}>{idk[0] ? `:` : ''}</div>
              <div
                style={{
                  textAlign: 'right',
                  margin: 0,
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '59%',
                  // whiteSpace: 'nowrap',
                  // background: 'blue',
                }}
              >
                {idk[1]}
                {/* {idk[1] ? ': ' + idk[1] : ''} */}
              </div>
            </div>
          ) : (
            <div key={index} id="disableScroll">
              {idk[0]}
            </div>
          )}
        </>
      )
    })
}

export default InfoHSDescription

InfoHSDescription.propTypes = {
  desc: PropTypes.object,
}
