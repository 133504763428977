import { animated } from '@react-spring/three'
import React, { useEffect, useRef, useState } from 'react'
import { BackSide } from 'three'
import { config, useSpring } from '@react-spring/core'
import PropTypes from 'prop-types'
import { useTexture } from '@react-three/drei'
import { invalidate, useFrame, useThree } from '@react-three/fiber'
import { usePanoramaTransition } from '../stores'

export default function AnimatedPanorama({ texture, position }) {
  const { camera } = useThree()
  const material = useRef()
  const [localTexture, setLocalTexture] = useState(null)
  const defaultTexture = useTexture('textures/panorama/default.png')
  const { setIsLoading } = usePanoramaTransition()
  // const [render, setRender] = useState(false)

  useFrame(() => {
    invalidate()
  })
  const [styles, api] = useSpring(() => ({
    opacity: 0,
  }))

  useEffect(() => {
    api.start({ opacity: 0, config: { duration: 350 } })
    // setRender(false)
    if (localTexture == null) {
      setLocalTexture(texture)
    } else {
      setTimeout(() => {
        setLocalTexture(texture)
      }, 500)
    }
  }, [texture])

  useEffect(() => {
    document.addEventListener('mousewheel', onDocumentMouseWheel)
    return () => {
      document.removeEventListener('mousewheel', onDocumentMouseWheel)
    }
  }, [localTexture])

  function onDocumentMouseWheel(event) {
    // camera.zoom = camera.zoom - event.deltaY / 40
    var fovMAX = 75
    var fovMIN = 20

    if (event.target.getAttribute('id') === 'disableScroll') return

    camera.fov -= event.wheelDeltaY * 0.05
    camera.fov = Math.max(Math.min(camera.fov, fovMAX), fovMIN)
    camera.updateProjectionMatrix()
  }

  useEffect(() => {
    if (localTexture != null) {
      camera.position.set(position[0], position[1], position[2])
      camera.fov = 75
      // camera.zoom = 1
      camera.updateProjectionMatrix()
      // setRender(true)
      api.start({ opacity: 1, config: config.default })
      setIsLoading(false)
    }
  }, [localTexture])

  return (
    <mesh name="panorama" rotation={[0, 0, 0]} scale={[1, 1, -1]}>
      <sphereBufferGeometry attach="geometry" args={[300, 60, 40]} />
      <animated.meshBasicMaterial
        attach="material"
        ref={material}
        side={BackSide}
        map={localTexture != null ? localTexture : defaultTexture}
        transparent
        opacity={styles.opacity}
        // opacity={render ? 1 : 0}
      />
    </mesh>
  )
}

AnimatedPanorama.propTypes = {
  texture: PropTypes.object,
  position: PropTypes.array,
}
