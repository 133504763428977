import React from 'react'

export default function BasicMeshHotspot() {
  return (
    <mesh>
      <sphereBufferGeometry args={[1, 250, 250]} attach="geometry" />
      <meshBasicMaterial attach="material" color="#00F5D4" roughness={100} />
    </mesh>
  )
}
