import React from 'react'
import { isMobileOnly } from 'react-device-detect'

function CloseIcon() {
  return (
    <svg
      // height="20" width="20"
      style={{ width: isMobileOnly ? '3.3vh' : '3vh', height: isMobileOnly ? '3.3vh' : '3vh' }}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.21954 14.7188C4.07892 14.8595 3.99995 15.0503 4 15.2492C4.00005 15.4481 4.07911 15.6389 4.21979 15.7795C4.36047 15.9201 4.55124 15.999 4.75015 15.999C4.94905 15.999 5.13979 15.9199 5.28041 15.7792L9.91084 11.1493C9.92245 11.1376 9.93624 11.1284 9.95142 11.1221C9.96661 11.1158 9.98289 11.1126 9.99933 11.1126C10.0158 11.1126 10.032 11.1158 10.0472 11.1221C10.0624 11.1284 10.0762 11.1376 10.0878 11.1493L14.7182 15.7802C14.7879 15.8499 14.8705 15.9051 14.9615 15.9428C15.0525 15.9806 15.15 16 15.2485 16C15.347 16 15.4445 15.9806 15.5355 15.943C15.6265 15.9053 15.7092 15.8501 15.7789 15.7805C15.8485 15.7108 15.9038 15.6282 15.9415 15.5372C15.9792 15.4462 15.9986 15.3487 15.9987 15.2502C15.9987 15.1517 15.9793 15.0542 15.9416 14.9632C15.904 14.8722 15.8487 14.7895 15.7791 14.7198L11.1492 10.0879C11.1375 10.0763 11.1283 10.0625 11.122 10.0473C11.1157 10.0321 11.1125 10.0159 11.1125 9.99943C11.1125 9.98299 11.1157 9.96671 11.122 9.95153C11.1283 9.93634 11.1375 9.92255 11.1492 9.91094L15.7801 5.28051C15.9207 5.1397 15.9996 4.94879 15.9995 4.74979C15.9994 4.5508 15.9202 4.36 15.7794 4.21939C15.6386 4.07878 15.4476 3.99986 15.2487 4C15.0497 4.00014 14.8589 4.07933 14.7182 4.22014L10.0878 8.84957C10.0762 8.86121 10.0624 8.87044 10.0472 8.87674C10.032 8.88304 10.0158 8.88629 9.99933 8.88629C9.98289 8.88629 9.96661 8.88304 9.95142 8.87674C9.93624 8.87044 9.92245 8.86121 9.91084 8.84957L5.28041 4.22014C5.21078 4.15048 5.12812 4.09522 5.03714 4.05751C4.94615 4.0198 4.84864 4.00038 4.75015 4.00035C4.55124 4.00031 4.36047 4.07928 4.21979 4.21989C4.07911 4.3605 4.00005 4.55124 4 4.75015C3.99995 4.94905 4.07892 5.13983 4.21954 5.28051L8.84947 9.91094C8.86111 9.92255 8.87034 9.93634 8.87664 9.95153C8.88294 9.96671 8.88619 9.98299 8.88619 9.99943C8.88619 10.0159 8.88294 10.0321 8.87664 10.0473C8.87034 10.0625 8.86111 10.0763 8.84947 10.0879L4.21954 14.7188Z"
        fill="#F3F4F6"
      />
    </svg>
  )
}

export default CloseIcon
