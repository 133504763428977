import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'

import BGSplash from '../../assets/images/BGSplash.jpg'
import CloseIcon from '../../assets/svgs/CloseIcon'
import NewUserForm from '../../core/NewuserForm'
import { isMobileOnly } from 'react-device-detect'
import { cameraStore, useHud } from '../../stores'
import { useLocation } from 'wouter'
import AuthUserUI from './AuthUserUI'
import { fadeIn, fadeOut, slideInRight, slideOutRight } from 'react-animations'
import { sendEvent } from '../../../../helper/google-analytics'

function ProfileUI() {
  const { setFullScreen, setProfileUI, setDisclaimer } = useHud()
  const [, setLocation] = useLocation()
  const [slideAnim, setSlideAnim] = useState(false)
  const [fscreen, setFScreen] = useState(false)
  const cameraState = cameraStore()

  function slideOutAnim() {
    setSlideAnim(true)
    setFScreen(true)
    // setFade(true)
    // setFullScreen(true)
  }

  function closeMenu() {
    setProfileUI(false)
    setFullScreen(false)
    setSlideAnim(false)
    setFScreen(false)
    cameraState.setRotate(true)
    sendEvent(`Close button clicked`, 'Profile Section', 'Close Button')
  }

  function showDisclaimer() {
    setProfileUI(false)
    setDisclaimer(true)
    sendEvent(`Disclaimer button clicked`, 'Profile Section', 'Disclaimer Button')
  }

  let HandleLogout = () => {
    localStorage.clear()
    sessionStorage.clear()
    setLocation('/login')
    setProfileUI(false)
    setFullScreen(false)
    sendEvent(`Logout button clicked`, 'Profile Section', 'Logout Button')
  }
  function onDisabledDivWheel(event) {
    // Prevent the event from bubbling up to the document
    event.stopPropagation()
  }

  return (
    <div id="disableScroll" style={{ width: '100%', height: '100%' }}>
      <div
        className={css(styles.fullScreenContainer, fscreen ? styles.fadeOut : styles.fadeIn)}
        onWheel={onDisabledDivWheel}
      >
        <div className={css(styles.profileContainer, slideAnim ? styles.slidOut : styles.slideIn)}>
          <div className={css(styles.backgroundContainer)}></div>
          <div className={css(styles.content)}>
            <div className={css(styles.header)}>
              <div className={css(styles.titleContainer)}>
                <span className={css(styles.title02)}>
                  {sessionStorage.getItem('user-type') === 'manager' ||
                  localStorage.getItem('user-type') === 'manager'
                    ? 'Admin'
                    : 'About Us'}
                </span>
                {sessionStorage.getItem('user-type') === 'manager' ||
                localStorage.getItem('user-type') === 'manager' ? (
                  <div className={css(styles.buttonContainer)}>
                    <button className={css(styles.title01)} onClick={HandleLogout}>
                      LOG OUT
                    </button>
                  </div>
                ) : null}
              </div>
              <div className={css(styles.CloseButtonContainer)}>
                <button
                  className={css(styles.button)}
                  onClick={() => slideOutAnim(setTimeout(() => closeMenu(), 500))}
                >
                  <div
                    className={css(styles.svg)}
                    style={{ width: isMobileOnly ? '2vw' : '1.2vw' }}
                  >
                    <CloseIcon />
                  </div>
                </button>
              </div>
            </div>
            <div className={css(styles.bodyContent)}>
              {sessionStorage.getItem('user-type') === 'manager' ||
              localStorage.getItem('user-type') === 'manager' ? (
                <NewUserForm setSlideAnim={setSlideAnim} setFScreen={setFScreen} />
              ) : (
                <AuthUserUI setSlideAnim={setSlideAnim} setFScreen={setFScreen} />
              )}
            </div>
            <div className={css(styles.footer)}>
              <div className={css(styles.contentContainer)}>
                {/* <div className={css(styles.imgContainer)}>
              <img src="/images/GodrejLogo.svg" className={css(styles.svgImg)} />
            </div> */}
                <button
                  className={css(styles.button, styles.buttonContent)}
                  onClick={showDisclaimer}
                >
                  Disclaimer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileUI

const styles = StyleSheet.create({
  fullScreenContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.01)',
    backdropFilter: 'blur(3px)',
    pointerEvents: 'none',
    margin: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  fadeIn: {
    animationName: fadeIn,
    animationDuration: '0.5s',
  },
  fadeOut: {
    animationName: fadeOut,
    animationDuration: '1s',
  },
  slideIn: {
    animationName: slideInRight,
    animationDuration: '0.9s',
    // animationDelay: '2s',
  },
  slidOut: {
    animationName: slideOutRight,
    // animationDelay: '2s',
    animationDuration: '0.5s',
  },
  profileContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: '66.57vh',
  },
  backgroundContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
    backgroundImage: `url(${BGSplash})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingLeft: '3%',
    paddingRight: '3%',
    paddingTop: '4%',
    paddingBottom: '4%',
    pointerEvents: 'auto',
    opacity: 0.8,
  },
  content: {
    // width: '44.98vh',
    // height: 'auto',
    position: 'absolute',
    top: '6.6vh',
    right: 0,
    height: '100%',
    width: '100%',
    paddingLeft: '6.4vh',
    paddingRight: '6.4vh',
    // paddingTop: '4%',
    pointerEvents: 'auto',
    // display: 'flex',
    // justifyContent: 'center',
    // marginLeft: '4.4vh',
    // background: 'blue',
  },
  header: {
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
    borderBottom: '0.3vh solid #1E4F69',
    paddingBottom: '3%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // background: 'red',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: '#ffffff',
    // background: 'red',
  },
  title01: {
    fontSize: '2.5vh',
    pointerEvents: 'auto',
    fontFamily: 'WorkSans-Regular',
    padding: '0.8vh',
    border: '0.3vh solid #ffffff',
    borderRadius: '0.8vh',
    color: '#ffffff',
    background: 'none',
    ':hover': {
      cursor: 'pointer',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '0.6vh',
  },
  title02: {
    fontSize: '5.5vh',
    fontFamily: 'WorkSans-ExtraBold',
  },
  button: {
    background: 'none',
    border: 'none',
    height: 'auto',
    width: 'auto',
    textAlign: 'center',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
  },
  svg: {
    height: 'auto',
    // width: '1.2vw',
  },
  bodyContent: {
    marginTop: `${isMobileOnly ? '5%' : '8%'}`,
  },
  footer: {
    position: 'absolute',
    bottom: '10%',
    marginBottom: '3%',
    width: '81%',
    // background: 'red',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonContent: {
    fontSize: '2.1vh',
    fontFamily: 'WOrkSans-Medium',
    padding: 0,
    margin: 0,
    color: '#ffffff',
  },
  imgContainer: {
    width: `${isMobileOnly ? '28vh' : '33vh'}`,
    height: 'auto',
    paddingTop: '3%',
    // background: 'red',
  },
  svgImg: {
    width: '100%',
    height: 'auto',
  },
})
