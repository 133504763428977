import React from 'react'
import InfoSection from '../ui/InfoSection'
// import IntroDescription from '../ui/IntroDescription'
// import { useLocation } from 'wouter'

function LeftGridContent() {
  // const [location] = useLocation()
  // <IntroDescription />

  return (
    <>
      <InfoSection />
      {/* {location != '/' && <InfoSection />} */}
    </>
  )
}

export default LeftGridContent
