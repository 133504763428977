import React from 'react'
import { useLocation } from 'wouter'
import { useHud, useLandscapeMode, useLoader, useStatic } from '../stores'
import HotspotManager from './HotspotManager'
import TextureManager from './TextureManager'
import PanoramaOrbitControls from './PanoramaOrbitControls'
import PanoramaPreloader from './PanoramaPreloader'
import { useHotspotDebugger } from '../context/debugs/debugStores'
import { LogCustom } from './CustomLogger'
import TransitionScreen from './TransitionScreen'

function PanoramaBuilder() {
  LogCustom('PanoramaBuilder: ', 'In PanoramaBuilder')
  const { routes } = useStatic()
  const [location] = useLocation()
  const { enabled: debuggerEnabled } = useHotspotDebugger()
  const { welcomePopup } = useHud()
  const { state } = useLoader()
  const { status } = useLandscapeMode()

  return (
    <>
      {routes[location] != null && (
        <>
          <TextureManager
            texture={routes[location].panorama}
            position={routes[location].position}
          />
          {state.isDone && status && !welcomePopup && (
            <HotspotManager hotspots={routes[location].hotspots} />
          )}
          <PanoramaPreloader preload={routes[location].preload} />
        </>
      )}
      {!debuggerEnabled && <PanoramaOrbitControls />}
      <TransitionScreen />
    </>
  )
}
export default PanoramaBuilder
