import React, { useEffect } from 'react'
import { useLocation } from 'wouter'
import { useAutoTour, useHud } from '../stores'

function BackgroundAudio() {
  const { welcomePopup, isAudio } = useHud()
  const [location] = useLocation()
  const { autoTour } = useAutoTour()
  const audio = document.getElementById('bgAudio')

  useEffect(() => {
    if (audio != null) {
      audio.volume = 0.2
    }
  }, [audio])

  useEffect(() => {
    if (audio != null) {
      if (isAudio) {
        if (autoTour) {
          audio.volume = 0.05
          // audio.muted = false
        } else {
          audio.volume = 0.2
          // audio.muted = false
        }
      } else {
        var fadeAudio = setInterval(function () {
          // Only fade if past the fade out point or not at zero already
          if (audio.volume != 0.0) {
            audio.volume = Math.max(0, audio.volume - 0.3)
          }
          // When volume at zero stop all the intervalling
          if (audio.volume === 0.0) {
            clearInterval(fadeAudio)
          }
        }, 200)
        // audio.muted = true
        // audio.volume = 0
      }
      // console.log('audio:  ' + audio.volume)
    }
  }, [isAudio, autoTour])

  return (
    <>
      {!welcomePopup && location != '/login' && location != '/fallback' && (
        <audio id="bgAudio" autoPlay loop>
          <source src="/audio/BGMusic.mp3" type="audio/mp3"></source>
        </audio>
      )}
    </>
  )
}

export default BackgroundAudio
