import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { cameraStore, useHud, usePanoramaTransition, useStatic } from '../../stores'
import { useLocation } from 'wouter'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { fadeIn, fadeOut } from 'react-animations'
import RoundClose from '../../assets/svgs/RoundClose'
import { sendEvent } from '../../../../helper/google-analytics'

function Map() {
  const { setMap, setFullScreen, setPanoramaDesc } = useHud()
  const { routes } = useStatic()
  const [location, setLocation] = useLocation()
  const [slideAnim, setSlideAnim] = useState(false)
  const [fscreen, setFScreen] = useState(false)
  const { setIsLoading } = usePanoramaTransition()
  const [hover, setHover] = useState(null)
  const cameraState = cameraStore()

  function slideOutAnim() {
    setSlideAnim(true)
    setFScreen(true)
    // console.log("fadeAnim")
  }

  function closeMenu() {
    setMap(false)
    setFullScreen(false)
    setSlideAnim(false)
    setFScreen(true)
    cameraState.setRotate(true)
  }

  function onHoverEnter(item) {
    setHover(item)
  }
  function onHoverExit() {
    setHover(null)
  }

  // useEffect(() => {
  //   console.log('loc', location === item)
  //   console.log('split: ', item.includes(location.split('/').slice(0, 2).join('/')))
  // }, [hover])

  return (
    <>
      <div id="disableScroll" style={{ width: '100%', height: '100%' }}>
        <div className={css(styles.fullScreenContainer, fscreen ? styles.fadeOut : styles.fadeIn)}>
          <div className={css(styles.mapContainer, slideAnim ? styles.slidOut : styles.slideIn)}>
            <div className={css(styles.bodyContent)}>
              <div
                className={css(styles.mapContent)}
                style={{
                  width: `${isMobileOnly ? '70%' : '92%'}`,
                  height: `${isMobileOnly ? '48vh' : '66vh'}`,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  // background: 'red',
                  display: 'flex',
                  justifyContent: 'center',
                  overflow: 'hidden',
                }}
              >
                <img
                  src="/images/map.png"
                  // width="4800"
                  // height="2184"
                  style={{
                    // minWidth: `${isMobileOnly ? '100%' : '100%'}`,
                    // height: '100%',
                    width: '100%',
                    height: 'auto',
                  }}
                />

                {/* //! Hotspots Mapping */}
                {Object.keys(routes).map(function (item, index) {
                  var route = routes[item]

                  // console.log('in map: ' + target)
                  if (route.mapMarkerPos != null) {
                    let leftx = route.mapMarkerPos.desktop.x
                    let topy = route.mapMarkerPos.desktop.y
                    let x = route.mapMarkerPos.mobile.x
                    let y = route.mapMarkerPos.mobile.y
                    let areaName = route.mapMarkerPos.name
                    let areaNumb = route.mapMarkerPos.areaNumber
                    // target = route.mapMarkerPos.target
                    return (
                      <>
                        <div
                          key={index}
                          className={css(styles.markerContainer01)}
                          style={{
                            left: `${isMobileOnly ? x : leftx}`,
                            top: `${isMobileOnly ? y : topy}`,
                          }}
                          onClick={() => {
                            setLocation(route.mapMarkerPos.target)
                            setPanoramaDesc(true)
                            setMap(false)
                            setFullScreen(false)
                            setIsLoading(true)
                            sendEvent(`${areaName} hotspot clicked`, 'Map', 'Map Hotspots')
                          }}
                        >
                          <div
                            className={css(styles.markerSvg)}
                            onMouseEnter={() => {
                              onHoverEnter(item)
                            }}
                            onMouseLeave={() => onHoverExit(item)}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={
                                location === item &&
                                item.includes(location.split('/').slice(0, 2).join('/')) &&
                                location != '/'
                                  ? '/images/ActiveHS.svg'
                                  : '/images/hotspotImg.png'
                              }
                              className={css(styles.image)}
                            />
                            <div className={css(styles.areaNumb)}>{areaNumb}</div>
                          </div>

                          <div style={{ display: 'flex' }}>
                            <div
                              className={css(styles.markerContent)}
                              style={{
                                backgroundColor:
                                  location === item &&
                                  item.includes(location.split('/').slice(0, 2).join('/')) &&
                                  location != '/'
                                    ? '#AA2555'
                                    : '#2F6785',
                                opacity: hover === item ? 1 : 0,
                              }}
                            >
                              {areaName}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }
                })}
                {/* //! close button */}
                <div className={css(styles.header)}>
                  <button
                    className={css(styles.button)}
                    onClick={() => {
                      slideOutAnim(setTimeout(() => closeMenu(), 500))
                      sendEvent('Closed map', 'Map', 'Close Button')
                    }}
                  >
                    <RoundClose />
                  </button>
                </div>
              </div>
              {/* //! Map content info */}
              <div
                style={{
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  // flexDirection: 'column',
                  // margin: 0,
                  // marginTop: '3vh',
                  padding: 0,
                  fontSize: `${isMobile ? '1.56vh' : '2vh'}`,
                  fontFamily: 'WorkSans-Regular',
                  lineHeight: '150%',
                  whiteSpace: 'nowrap',
                  marginRight: `${isMobile ? '3vw' : '7vw'}`,
                  marginTop: '-2.5vh',
                }}
              >
                <ol style={{ marginRight: `${isMobile ? '1vh' : '2vh'}` }}>
                  <li>Security Gate</li>
                  <li>Gallery</li>
                  <li>Plate Receipt Area</li>
                  <li>Marking and Cutting</li>
                </ol>
                <ol start={5} style={{ marginRight: `${isMobile ? '1vh' : '2vh'}` }}>
                  <li>Plate Rolling</li>
                  <li>Shell Long Seam Welding</li>
                  <li>Dished-end Welding</li>
                  <li>Assembly Section</li>
                </ol>
                <ol start={9} style={{ marginRight: `${isMobile ? '1vh' : '2vh'}` }}>
                  <li>Machine Shop</li>
                  <li>Nozzle Shop</li>
                  <li>Training Center</li>
                  <li>Non-Destructive Testing</li>
                </ol>
                <ol start={13}>
                  <li>Finished Equipment</li>
                  <li>Extended Fabrication Yard</li>
                  <li>Jetty</li>
                  <li>Radiographic Testing Room</li>
                </ol>
              </div>
              <div className={css(styles.title01)}>
                <div className={css(styles.desc02)}>
                  <a
                    href="https://goo.gl/maps/8mL4BwndtKK96Mt88"
                    className={css(styles.link)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span style={{ fontSize: '2.5vh', fontFamily: 'WorkSans-Medium' }}>DAHEJ</span>
                    &nbsp;GUJARAT, INDIA
                  </a>
                </div>
                <span>PLANT LAYOUT</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Map

const styles = StyleSheet.create({
  image: {
    height: '100%',
    width: '100%',
  },
  fullScreenContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(8px)',
    zIndex: 1,
    pointerEvents: 'none',
    margin: 0,
  },
  fadeIn: {
    animationName: fadeIn,
    animationDuration: '1s',
  },
  fadeOut: {
    animationName: fadeOut,
    animationDuration: '1s',
  },
  slideIn: {
    animationName: fadeIn,
    animationDuration: '0.9s',
  },
  slidOut: {
    animationName: fadeOut,
    animationDuration: '0.5s',
  },
  mapContainer: {
    position: 'absolute',
    bottom: '2%',
    right: '1%',
    left: '1%',
    top: `${isMobileOnly ? '16%' : '11vh'}`,
    backgroundColor: '#1F1F1F',
    backdropFilter: 'blur(8px)',
    borderRadius: '0.4vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  header: {
    position: 'absolute',
    top: `${isMobileOnly ? '0vh' : '1.5vh'}`,
    right: '1.5vh',
    // backgroundColor: '#1f1f1f',
    // background: 'red',
  },
  title: {
    fontSize: '4vh',
    lineHeight: '125%',
    color: '#ffffff',
    fontFamily: 'WorkSans-Regular',
  },
  button: {
    height: '3.5vh',
    width: '3.5vh',
    background: 'none',
    border: 0,
    margin: 0,
    padding: 0,
    outline: 'none',
    display: 'inline-flex',
    pointerEvents: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  svg: {
    height: `${isMobileOnly ? '3.8vh' : '3.3vh'}`,
    width: `${isMobileOnly ? '3.8vh' : '3.3vh'}`,
    // background: 'red',
  },
  bodyContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: '3vh',
    border: '0.2vh solid rgba(255, 255, 255, 0.3)',
    borderRadius: '0.8vh',
    height: '100%',
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
  },
  mapContent: {
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 0,
    pointerEvents: 'none',
    // backgroundPosition: 'center',
  },
  markerContainer01: {
    position: 'absolute',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'auto',
    // background: ' red',
    // ':hover': {
    //   cursor: 'pointer',
    // },
  },
  footerContent: {
    marginRight: 23,
  },
  title01: {
    fontSize: '6vh',
    fontFamily: 'WorkSans-ExtraBold',
    color: '#ffffff',
    whiteSpace: 'nowrap',
    padding: '0 0.5vh',
    position: 'absolute',
    bottom: 0,
    left: '8vh',
    backgroundColor: '#1F1F1F',
    width: '46.5vh',
    display: 'flex',
    flexDirection: 'column',
    // background: 'blue',
  },
  desc01: {
    color: '#ffffff',
    fontFamily: 'WorkSans-Medium',
    fontSize: '3vh',
  },
  desc02: {
    color: '#ffffff',
    fontFamily: 'WorkSans-Light',
    fontSize: '1.9vh',
    // marginLeft: '2%',
  },
  visibleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 152,
    // backgroundColor: 'red',
  },
  markerContainer: {
    justifyContent: 'space-between',
  },
  markerContent: {
    // cursor: 'pointer',
    padding: '1vh',
    whiteSpace: 'nowrap',
    color: '#ffffff',
    textAlign: 'center',
    borderRadius: '0.4vw',

    border: '1px solid #ffffff',
    marginTop: '2%',
    fontFamily: 'Worksans-SemiBold',
    fontSize: '1.8vh',
  },
  markerSvg: {
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    width: '2.08vw',
    height: '2.08vw',
  },
  markerInfo: {
    fontFamily: 'WorkSans-Regular',
    fontSize: 12,
    lineHeight: '125%',
    padding: 16,
    backgroundColor: '#224757',
    color: '#ffffff',
    borderRadius: 4,
    textAlign: 'center',
    marginTop: '2%',
  },
  color: {
    color: '#C73B74',
  },
  areaNumb: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // left: 5,
    color: '#ffffff',
    textAlign: 'center',
    borderRadius: '0.4vw',
    fontFamily: 'Worksans-SemiBold',
    fontSize: '2.2vh',
  },
  mapMarker: {
    float: 'right',
    '@media (max-width: 576px) and (orientation: landscape)': {
      width: '2.5vw',
    },
    '@media (min-width: 768px) and (orientation: landscape)': {
      width: '2.5vw',
    },
    '@media (min-width: 992px) and (orientation: landscape)': {
      width: '2vw',
    },
    '@media (min-width: 1200px)': {
      width: '2vw',
    },
    '@media (min-width: 1400px)': {
      width: '1.5vw',
    },
    '@media (min-width: 2560px)': {
      width: '1.5vw',
    },
  },
})
