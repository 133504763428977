import React from 'react'
import { isMobileOnly } from 'react-device-detect'

function SoundOnIcon() {
  return (
    <svg
      style={{ width: isMobileOnly ? '4.3vh' : '3.7vh', height: isMobileOnly ? '4.3vh' : '3.7vh' }}
      // width="33"
      // height="28"
      viewBox="0 0 33 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.73768 16.5961C1.6681 14.8135 1.6681 12.5865 2.73768 10.8038V10.8038C3.06407 10.2598 3.60479 9.87879 4.22685 9.75438L6.76656 9.24644C6.91787 9.21618 7.05426 9.13503 7.15304 9.01649L11.6717 3.59408C12.8543 2.17501 13.4455 1.46548 13.9732 1.65652C14.5009 1.84756 14.5009 2.77116 14.5009 4.61838L14.5009 22.7815C14.5009 24.6288 14.5009 25.5524 13.9732 25.7434C13.4455 25.9344 12.8543 25.2249 11.6717 23.8058L7.15304 18.3834C7.05426 18.2649 6.91787 18.1837 6.76656 18.1535L4.22685 17.6455C3.60479 17.5211 3.06407 17.1401 2.73768 16.5961V16.5961Z"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M21.3046 8.39628C22.704 9.79565 23.4936 11.6914 23.5014 13.6704C23.5092 15.6494 22.7345 17.5513 21.3462 18.9616"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M27.4877 5.21422C29.7267 7.45322 30.99 10.4864 31.0025 13.6528C31.015 16.8192 29.7756 19.8622 27.5543 22.1188"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default SoundOnIcon
