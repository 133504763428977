export const logEnabled = false

export const gaConfig = {
  enabled: true,
  code: 'G-PMVHFFH867',
}

export const baseURL = 'https://api.processequipment.virtualtour.godrej.com/api'

export const magicLinkDomainURL = 'https://processequipment.virtualtour.godrej.com'
// const domainURL = 'http://localhost:3000?token='
// http://d1ly1t5h3vavb9.cloudfront.net/
// https://processequipment.virtualtour.godrej.com

export const content = {
  '/': {
    title: 'Box 01',
    description: 'Box 01 is selected',
  },
  '/box2': {
    title: 'Box 02',
    description: 'Box 02 is selected',
  },
  '/box3': {
    title: 'Box 03',
    description: 'Box 03 is selected',
  },
}

// Tween points
export const TweenPoints = {
  camPosition: {
    position: [-10, 10, -15],
    control: [0, 0, 0],
    name: 'cubePositionName2',
  },
}
