import React from 'react'
import PropTypes from 'prop-types'
import { Html } from '@react-three/drei'
import { css } from 'aphrodite'
import { globalStyles } from '../../context/GlobalStyles'

export default function HTMLToCanvasSprite({
  children,
  onHoverEnter,
  onHoverExit,
  onClick,
  position,
  debug,
}) {
  return (
    <group position={position}>
      <Html
        className={css(debug ? globalStyles.nonInteraction : globalStyles.interactable)}
        center
        sprite
      >
        <div onClick={onClick} onMouseEnter={onHoverEnter} onMouseLeave={onHoverExit}>
          {children}
        </div>
      </Html>
    </group>
  )
}

HTMLToCanvasSprite.propTypes = {
  children: PropTypes.element,
  onHoverEnter: PropTypes.func,
  onHoverExit: PropTypes.func,
  onClick: PropTypes.func,
  position: PropTypes.array,
  debug: PropTypes.bool,
}
