import { Html } from '@react-three/drei'
import React, { useState, useEffect } from 'react'
import { useAutoTour, useHud, useLandscapeMode, useLoader, usePanoramaTransition } from '../stores'
import { css, StyleSheet } from 'aphrodite'
// import BGSplash from '../assets/images/BGSplash.jpg'
import { fadeIn } from 'react-animations'
import { fadeOut } from 'react-animations'
import { sendEvent } from '../../../helper/google-analytics'

function WelcomePopup() {
  const { welcomePopup, setWelcomePopup } = useHud()
  const [render, setRender] = useState(false)
  const { state } = useLoader()
  const { status } = useLandscapeMode()
  const [hover, setHover] = useState(false)
  const { setAutoStartTour } = useAutoTour()
  const { isLoading } = usePanoramaTransition()

  useEffect(() => {
    if (welcomePopup) setRender(true)
  }, [welcomePopup])

  const onAnimationEnd = () => {
    if (!welcomePopup) setRender(false)
  }

  return (
    <>
      {render && !isLoading && state.isDone && status && (
        <Html fullscreen>
          <div id="disableScroll" style={{ width: '100%', height: '100%' }}>
            <div
              className={css(
                styles.fullScreenContainer
                // welcomePopup ? styles.fadeInAnim : styles.fadeOutAnim
              )}
              // onAnimationEnd={onAnimationEnd}
            >
              <div
                className={css(
                  styles.infoContainer,
                  welcomePopup ? styles.fadeInAnim : styles.fadeOutAnim
                )}
                onAnimationEnd={onAnimationEnd}
              >
                <div className={css(styles.imgContainer)}>
                  <img src="/images/BGSplash.jpg" className={css(styles.image)} />
                </div>
                <div className={css(styles.contentContainer)}>
                  <div className={css(styles.title01)}>Welcome to the</div>
                  <div className={css(styles.title02)}>Virtual tour</div>
                  <div className={css(styles.title03)}>Godrej Process Equipment</div>
                  <div className={css(styles.title04)}>Dahej, Gujarat, India</div>
                  <div className={css(styles.buttonContainer)}>
                    <button
                      className={css(styles.button)}
                      onClick={() => {
                        setWelcomePopup(false)
                        setAutoStartTour(true)
                        sendEvent(
                          `Start the tour button clicked`,
                          'Welcome Popup',
                          'Start the Tour Button'
                        )
                      }}
                      onMouseEnter={() => {
                        setHover(true)
                      }}
                      onMouseLeave={() => {
                        setHover(false)
                      }}
                      style={{
                        background: hover ? '#ffffff' : 'none',
                        color: hover ? '#224957' : '#ffffff',
                      }}
                    >
                      Start the Tour
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Html>
      )}
    </>
  )
}

export default WelcomePopup

const styles = StyleSheet.create({
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '2s',
    animationFillMode: 'forwards',
  },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
  },
  fullScreenContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    // backgroundColor: 'rgba(0, 0, 0, 0.8)',
    // backdropFilter: 'blur(8px)',
    // zIndex: 1,
    pointerEvents: 'none',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 17685591,
  },
  infoContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    height: '75vh',
    width: '120vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // backgroundImage: `url(${BGSplash})`,
    borderRadius: '0.8vh',
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    // opacity: 0.8,
  },
  imgContainer: {
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    opacity: 0.8,
    borderRadius: '0.8vh',
  },
  contentContainer: {
    position: 'absolute',
    textAlign: 'center',
  },
  buttonContainer: {
    color: '#ffffff',
    pointerEvents: 'auto',
    marginTop: '15vh',
  },
  title01: {
    color: '#ffffff',
    // textTransform: 'capitalize',
    fontSize: '3vh',
    // fontFamily: 'WorkSans-Regular',
    fontFamily: 'Work Sans',
    fontWeight: 400,
    // fontDisplay: 'fallback',
  },
  title02: {
    color: '#ffffff',
    textTransform: 'capitalize',
    fontSize: '10vh',
    // fontFamily: 'WorkSans-Bold',
    fontFamily: 'Work Sans',
    fontWeight: 700,
    letterSpacing: 3,
    // fontDisplay: 'fallback',
  },
  title03: {
    fontSize: '3.5vh',
    // fontFamily: 'WorkSans-Medium',
    fontFamily: 'Work Sans',
    fontWeight: 600,
    color: '#ffffff',
    lineHeight: '150%',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    // fontDisplay: 'fallback',
  },
  title04: {
    fontSize: '2.5vh',
    // fontFamily: 'WorkSans-Regular',
    fontFamily: 'Work Sans',
    fontWeight: 400,
    color: '#ffffff',
    whiteSpace: 'nowrap',
    marginTop: '1vh',
    // fontDisplay: 'fallback',
  },
  button: {
    border: '0.3vh solid #ffffff',
    // background: 'none',
    padding: '2vh',
    fontSize: '2.5vh',
    // fontFamily: 'WorkSans-Regular',
    fontFamily: 'Work Sans',
    fontWeight: 400,
    borderRadius: '1vh',
    textTransform: 'uppercase',
    // color: '#ffffff',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
  },
})
