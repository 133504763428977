import { StyleSheet } from 'aphrodite'

export const globalStyles = StyleSheet.create({
  interactable: {
    pointerEvents: 'auto',
    ':focus': {
      outline: 'solid 3px #2d94cd',
      fontWeight: 'bold',
    },
  },
  nonInteraction: {
    pointerEvents: 'none',
  },
  floater: {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  norender: {
    display: 'none',
  },
})
