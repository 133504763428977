import React from 'react'
import { isMobileOnly } from 'react-device-detect'

function HamburgerIcon() {
  return (
    <svg
      style={{ width: isMobileOnly ? '4.3vh' : '3.3vh', height: isMobileOnly ? '4.3vh' : '3.3vh' }}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 6H22" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M3 12H22" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M3 18H22" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}

export default HamburgerIcon
