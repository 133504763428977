import { OrbitControls, useDetectGPU } from '@react-three/drei'
import { invalidate, useFrame, useThree } from '@react-three/fiber'
import React, { useEffect, useRef } from 'react'
import { Color } from 'three'
import { useLocation } from 'wouter'
import { cameraStore } from '../stores'

export default function PanoramaOrbitControls() {
  const GPUTier = useDetectGPU()
  const cameraState = cameraStore()
  const { gl } = useThree()
  const ref = useRef()
  const [location] = useLocation()
  // const { setIsInteracted } = useAutoTour()

  /**
   * Auto Rotate Region
   */

  const pauseRotation = () => {
    cameraState.stopAndResume()
  }

  useEffect(() => {
    gl.domElement.addEventListener('click', pauseRotation)
    gl.domElement.addEventListener('clickup', pauseRotation)
    gl.domElement.addEventListener('touchstart', pauseRotation)
    gl.domElement.addEventListener('touchend', pauseRotation)
    gl.setClearColor(new Color('black'))
  }, [])

  useEffect(() => {
    if (location !== '/login' || location !== '/fallback') {
      cameraState.resume()
    } else {
      cameraState.stopAndResume()
    }
  }, [location])

  useFrame(() => {
    cameraState.rotate && invalidate()
  })

  return (
    <>
      <OrbitControls
        enablePan={false}
        rotateSpeed={-0.2}
        // target={[5, 5, 5]}
        maxDistance={5}
        enableDamping={GPUTier.tier === 0 ? false : true}
        autoRotate={cameraState.rotate}
        autoRotateSpeed={0.15}
        // rotateSpeed={window.innerWidth < 1000 ? -0.7 : -1}
        enableZoom={false}
        ref={ref}
      />
    </>
  )
}
