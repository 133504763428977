export const categories = [
  {
    name: 'INTRODUCTION',
    image: '/images/thumbnail/categories/mainSections/intro.webp',
    number: '1.',
    target: '/intro',
    ind: 0,
    subcategories: [
      // {
      //   name: 'Front Right',
      //   imageSrc: '/images/thumbnail/categories/subSections/intro/intro.webp',
      //   target: '/intro',
      //   index: 0,
      // },
      {
        name: 'Aerial View',
        imageSrc: '/images/thumbnail/categories/subSections/intro/intro.webp',
        target: '/aerial',
        index: 0,
      },
      {
        name: 'Main Gate Exterior',
        imageSrc: '/images/thumbnail/categories/subSections/intro/IntroExterior.webp',
        target: '/intro/main-ext',
        index: 1,
      },
      {
        name: 'Main Gate Interior',
        imageSrc: '/images/thumbnail/categories/subSections/intro/IntroInt.webp',
        target: '/intro/main-int',
        index: 2,
      },
      // {
      //   name: 'Parking',
      //   imageSrc: '/images/thumbnail/categories/subSections/intro/introPark.webp',
      //   target: '/intro/parking',
      //   index: 3,
      // },
    ],
  },
  {
    name: 'Gallery',
    image: '/images/thumbnail/categories/mainSections/gallery.webp',
    number: '2.',
    ind: 1,
    target: '/gallery',
    subcategories: [
      {
        name: 'Gallery',
        imageSrc: '/images/thumbnail/categories/subSections/gallery.webp',
        target: '/gallery',
        index: 0,
      },
    ],
  },
  {
    name: 'Plate Receipt Area',
    image: '/images/thumbnail/categories/mainSections/plate.webp',
    number: '3.',
    ind: 2,
    target: '/plate',
    subcategories: [
      {
        name: 'Plate Receipt Area',
        imageSrc: '/images/thumbnail/categories/subSections/receipt/plate.webp',
        target: '/plate',
        index: 0,
      },
    ],
  },
  {
    name: 'Marking and Cutting',
    image: '/images/thumbnail/categories/mainSections/mark.webp',
    number: '4.',
    ind: 3,
    target: '/mark',
    subcategories: [
      {
        name: 'Automated Plate Marking Machine',
        imageSrc: '/images/thumbnail/categories/subSections/mark/mark.webp',
        target: '/mark',
        index: 0,
      },
      {
        name: 'Waterjet Cutting Machine',
        imageSrc: '/images/thumbnail/categories/subSections/mark/waterjet.png',
        target: '/mark/waterjet',
        index: 1,
      },

      {
        name: 'CNC Cutting Machine',
        imageSrc: '/images/thumbnail/categories/subSections/mark/gas.png',
        target: '/mark/gas',
        index: 2,
      },
      {
        name: 'Underwater Plasma Cutting Machine',
        imageSrc: '/images/thumbnail/categories/subSections/mark/markPlasma.webp',
        target: '/mark/plasma',
        index: 3,
      },
    ],
  },
  {
    name: 'Plate Rolling',
    image: '/images/thumbnail/categories/mainSections/rolling.webp',
    number: '5.',
    ind: 4,
    target: '/rolling',
    subcategories: [
      {
        name: 'Faccin Rolling Machine',
        imageSrc: '/images/thumbnail/categories/subSections/rolling/rolling.webp',
        index: 0,
        target: '/rolling',
      },
      {
        name: 'Haeusler Rolling Machine',
        imageSrc: '/images/thumbnail/categories/subSections/rolling/rollingHauselar.webp',
        index: 1,
        target: '/rolling/haueslar',
      },
    ],
  },
  {
    name: 'SHELL WELDING',
    image: '/images/thumbnail/categories/mainSections/shell.webp',
    number: '6.',
    ind: 5,
    target: '/shell',
    subcategories: [
      {
        name: 'Shell Longseam Welding',
        imageSrc: '/images/thumbnail/categories/subSections/shell/shell.webp',
        target: '/shell',
        index: 0,
      },
      {
        name: 'Circumferential Seam Welding',
        imageSrc: '/images/thumbnail/categories/subSections/shell/shellCirc1.webp',
        target: '/shell/circ-1',
        index: 1,
      },
      {
        name: 'Electroslag Strip Cladding',
        imageSrc: '/images/thumbnail/categories/subSections/shell/shellCirc2.webp',
        target: '/shell/circ-2',
        index: 2,
      },
    ],
  },
  {
    name: 'Dished-End Welding',
    image: '/images/thumbnail/categories/mainSections/dishWelding.webp',
    number: '7.',
    ind: 6,
    target: '/dish-welding',
    subcategories: [
      {
        name: 'Dished-end Nozzle Welding',
        imageSrc: '/images/thumbnail/categories/subSections/dishWelding.webp',
        index: 0,
        target: '/dish-welding',
      },
    ],
  },
  {
    name: 'Assembly Section',
    image: '/images/thumbnail/categories/mainSections/assembly.webp',
    number: '8.',
    ind: 7,
    target: '/assembly',
    subcategories: [
      {
        name: 'Assembly Section',
        imageSrc: '/images/thumbnail/categories/subSections/assembly.webp',
        target: '/assembly',
        index: 0,
      },
    ],
  },
  {
    name: 'machine shop',
    image: '/images/thumbnail/categories/mainSections/machine.webp',
    number: '9.',
    ind: 8,
    target: '/machine-shop',
    subcategories: [
      {
        name: 'Hartford VMC Machine',
        imageSrc: '/images/thumbnail/categories/subSections/machine/machine.webp',
        target: '/machine-shop',
        index: 0,
      },
      {
        name: 'Vertical Turret Lathe',
        imageSrc: '/images/thumbnail/categories/subSections/machine/machineVtl.webp',
        target: '/machine-shop/vtl2',
        index: 1,
      },
      {
        name: 'Deep Hole Drilling Machine',
        imageSrc: '/images/thumbnail/categories/subSections/machine/deep.webp',
        target: '/machine-shop/rt-room',
        index: 2,
      },
      {
        name: 'Skoda Horizontal Boring Machine',
        imageSrc: '/images/thumbnail/categories/subSections/machine/skoda.webp',
        target: '/machine-shop/skoda',
        index: 3,
      },
      {
        name: 'Farros Blatter',
        imageSrc: '/images/thumbnail/categories/subSections/machine/machineFarros.webp',
        target: '/machine-shop/farros',
        index: 4,
      },
    ],
  },
  {
    name: 'Nozzle Shop',
    image: '/images/thumbnail/categories/mainSections/nozzle.webp',
    number: '10.',
    ind: 9,
    target: '/nozzle',
    subcategories: [
      {
        name: 'Nozzle Storage',
        imageSrc: '/images/thumbnail/categories/subSections/nozzle/nozzle.webp',
        target: '/nozzle',
        index: 0,
      },
      {
        name: 'Pipe Cutting',
        imageSrc: '/images/thumbnail/categories/subSections/nozzle/nozzlePipe.webp',
        target: '/nozzle/pipe',
        index: 1,
      },
      {
        name: 'Nozzle SPM Welder',
        imageSrc: '/images/thumbnail/categories/subSections/nozzle/nozzleWeld.webp',
        target: '/nozzle/welding',
        index: 2,
      },
      {
        name: 'Nozzle ID Overlay',
        imageSrc: '/images/thumbnail/categories/subSections/nozzle/nozzleOverlay.webp',
        target: '/nozzle/overlay',
        index: 3,
      },
    ],
  },
  {
    name: 'training center',
    image: '/images/thumbnail/categories/mainSections/training.webp',
    number: '11.',
    ind: 10,
    target: '/training-center',
    subcategories: [
      {
        name: 'Training Center',
        imageSrc: '/images/thumbnail/categories/subSections/training/training.webp',
        target: '/training-center',
        index: 0,
      },
      {
        name: 'Welding Booth',
        imageSrc: '/images/thumbnail/categories/subSections/training/trainingBooth.webp',
        target: '/training-center/booth',
        index: 1,
      },
    ],
  },
  {
    name: 'Non-Destructive Testing',
    image: '/images/thumbnail/categories/mainSections/ndt.webp',
    number: '12.',
    ind: 11,
    target: '/ndt',
    subcategories: [
      {
        name: 'Dye-Penetrant Testing',
        imageSrc: '/images/thumbnail/categories/subSections/ndt/ndt.webp',
        target: '/ndt',
        index: 0,
      },
      {
        name: 'Magnetic Particle Testing',
        imageSrc: '/images/thumbnail/categories/subSections/ndt/ndtMpt.webp',
        target: '/ndt/mpt',
        index: 1,
      },
      {
        name: 'Ultrasonic Testing',
        imageSrc: '/images/thumbnail/categories/subSections/ndt/ndtUltra.webp',
        target: '/ndt/ultrasonic',
        index: 2,
      },
      {
        name: 'Time-of-Flight Diffraction',
        imageSrc: '/images/thumbnail/categories/subSections/ndt/ndtTofd.webp',
        target: '/ndt/tofd',
        index: 3,
      },
      {
        name: 'Phased Array Ultrasonic Testing',
        imageSrc: '/images/thumbnail/categories/subSections/ndt/ndtPaut.webp',
        target: '/ndt/paut',
        index: 4,
      },
      {
        name: 'Radiographic Testing Room',
        imageSrc: '/images/thumbnail/categories/subSections/ndt/ndtRT.webp',
        target: '/ndt/rt-room-ext-open',
        index: 5,
      },
    ],
  },
  {
    name: 'Finished Equipment',
    image: '/images/thumbnail/categories/mainSections/equipment.webp',
    number: '13.',
    ind: 12,
    target: '/equipment',
    subcategories: [
      {
        name: 'Within The Facility',
        imageSrc: '/images/thumbnail/categories/subSections/equip/equipment.webp',
        target: '/equipment',
        index: 0,
      },
    ],
  },
  {
    name: 'Extended Fabrication Yard',
    image: '/images/thumbnail/categories/mainSections/fabYard.webp',
    number: '14.',
    ind: 13,
    target: '/ext-fab-yard',
    subcategories: [
      {
        name: 'Yard Overview',
        imageSrc: '/images/thumbnail/categories/subSections/fabYard.webp',
        target: '/ext-fab-yard',
        index: 0,
      },
    ],
  },
  {
    name: 'Jetty',
    image: '/images/thumbnail/categories/mainSections/jetty.webp',
    number: '15.',
    ind: 14,
    target: '/jetty',
    subcategories: [
      {
        name: 'Jetty',
        imageSrc: '/images/thumbnail/categories/subSections/jetty.webp',
        target: '/jetty',
        index: 0,
      },
    ],
  },
]
