import React from 'react'
import { isMobileOnly } from 'react-device-detect'

function AutoTourIcon() {
  return (
    <svg
      style={{ width: isMobileOnly ? '4.0vh' : '3.2vh', height: isMobileOnly ? '4.0vh' : '3.2vh' }}
      // width="26" height="29"
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.46926 26.8921L3.19772 28.2033L2.46926 26.8921ZM2.46926 1.85792L3.19772 0.546686L2.46926 1.85792ZM3.19772 0.546686L24.155 12.1896L22.6981 14.8121L1.74079 3.16916L3.19772 0.546686ZM24.155 16.5604L3.19772 28.2033L1.74079 25.5808L22.6981 13.9379L24.155 16.5604ZM3.375 2.20758V26.5424H0.375V2.20758H3.375ZM3.19772 28.2033C1.93131 28.9069 0.375 27.9911 0.375 26.5424H3.375C3.375 25.7037 2.47398 25.1735 1.74079 25.5808L3.19772 28.2033ZM24.155 12.1896C25.8695 13.1421 25.8695 15.6079 24.155 16.5604L22.6981 13.9379C22.3551 14.1284 22.3551 14.6216 22.6981 14.8121L24.155 12.1896ZM1.74079 3.16916C2.47398 3.57648 3.375 3.04632 3.375 2.20758H0.375C0.375 0.758865 1.93131 -0.156875 3.19772 0.546686L1.74079 3.16916Z"
        fill="white"
      />
    </svg>
  )
}

export default AutoTourIcon
