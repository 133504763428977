import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import { Color_White } from '../../../theme'
import RotatePhoneLottie from './lottie/rotate/RotatePhoneLottie'
import SwitchToLandscapeLogic from './SwitchToLandscapeLogic'

export default function SwitchToLandscape() {
  return (
    <SwitchToLandscapeLogic>
      <>
        <div className={css(localStyles.logoContainer)}>
          <img src="/images/GodrejLogo.svg" className={css(localStyles.svgImg)} />
        </div>
        <div className={css(localStyles.title)}>Rotate your Device</div>
        <div className={css(localStyles.lottieContainer)}>
          <div className={css(localStyles.responsiveLottie)}>
            <RotatePhoneLottie />
          </div>
        </div>

        <div className={css(localStyles.title2)}>
          This website is optimized to work only in landscape mode, please rotate your device to
          resume the experience
        </div>
      </>
    </SwitchToLandscapeLogic>
  )
}

const localStyles = StyleSheet.create({
  logoContainer: {
    height: 'auto',
    width: '28vh',
    paddingLeft: '2vh',
    paddingTop: '2vh',
  },
  svgImg: {
    width: '100%',
    height: 'auto',
  },
  responsiveLottie: {
    textAlign: 'center',
    paddingLeft: '20vw',
  },
  lottieContainer: {
    width: '100%',
    paddingTop: '10vh',
  },
  title: {
    color: Color_White,
    paddingTop: '10vh',
    fontSize: 28,
    '@media (min-width: 576px)': {
      paddingTop: '10vh',
    },
    '@media (min-width: 768px)': {
      fontSize: 36,
      paddingTop: '15vh',
    },
    '@media (min-width: 992px)': {
      fontSize: 48,
      paddingTop: '15vh',
    },
    textAlign: 'center',
  },
  title2: {
    color: Color_White,
    padding: '8%',
    fontSize: 16,
    textAlign: 'center',
    '@media (min-width: 768px)': {
      fontSize: 24,
    },
    '@media (min-width: 992px)': {
      fontSize: 32,
    },
  },
})
