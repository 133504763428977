import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useLocation } from 'wouter'
import { useAutoTour, useHSContent, useHud, useStatic } from '../../stores'
import { displayComponents } from '../../hotspot-types'
import HotspotInfo from './HotspotInfo'
import PanoramaInfo from './PanoramaInfo'

function InfoSection() {
  const [content, setContent] = useState(null)
  const { routes } = useStatic()
  const [location] = useLocation()
  const { requestedFunction, setRequestedFunction, map, profileUI, categoryMenu, disclaimer } =
    useHud()
  const { popupUI, thankyouScreen } = useAutoTour()
  const [render, setRender] = useState(false)
  const { hotspotContent } = useHSContent()

  useEffect(() => {
    if (location in routes) setContent(routes[location].content)
  }, [location])

  useEffect(() => {
    if (requestedFunction != null && requestedFunction.target === displayComponents.hotspotInfo) {
      setRender(true)
    }
  }, [requestedFunction])

  const onAnimationEnd = () => {
    if (requestedFunction === null) setRender(false)
  }

  function closeConditionals() {
    setRequestedFunction(null)
  }
  // useEffect(() => {
  //   console.log('panorama desc: ' + panoramaDesc)
  // }, [panoramaDesc])
  return (
    <div
      className={css(styles.container)}
      style={{
        zIndex:
          map ||
          profileUI ||
          categoryMenu ||
          disclaimer ||
          popupUI ||
          hotspotContent ||
          (requestedFunction != null &&
            requestedFunction.target === displayComponents.largePopup) ||
          (requestedFunction != null && requestedFunction.target === displayComponents.pdfPopup) ||
          thankyouScreen
            ? 1
            : 17695054,
      }}
    >
      {/* {requestedFunction != null && requestedFunction.target === displayComponents.hotspotInfo && (
        <HotspotInfo onClose={closeConditionals} content={requestedFunction.content} />
      )} */}
      {render && (
        <HotspotInfo
          onClose={closeConditionals}
          content={requestedFunction != null ? requestedFunction.content : null}
          animationEnd={onAnimationEnd}
        />
      )}
      {content != null && location != '/' && <PanoramaInfo content={content.info} />}
    </div>
  )
}

export default InfoSection

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    pointerEvents: 'auto',
    left: 0,
  },
})
