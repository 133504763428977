import React from 'react'
import PropTypes from 'prop-types'
import { hotspotBehaviours, hotspotTypeE, hotspotTypes } from '../hotspot-types'
import HTMLToCanvas from './Hotspots/HTMLToCanvas'
import HTMLToCanvasSprite from './Hotspots/HTMLToCanvasSprite'
import { useHud, usePanoramaTransition, useStatic } from '../stores'
import { useLocation } from 'wouter'
import { LogError, LogWarn } from './CustomLogger'
import HTMLToCanvasMesh from './Hotspots/HTMLToCanvasMesh'
// import { hotspotTypes } from '../hotspot-types'

export default function HotspotManager({ hotspots }) {
  const { setRequestedFunction } = useHud()
  const { routes } = useStatic()
  const [location, setLocation] = useLocation()
  const { isLoading: isPanoramaTransitioning, setIsLoading } = usePanoramaTransition()

  function onClick(item) {
    let func = item.function
    if (func != null) {
      if (item.function.type == hotspotBehaviours.navigation) {
        if (location != func.target) {
          if (func.target in routes) {
            setIsLoading(true)
            setLocation(func.target)
          } else
            LogError(
              'Hotspot Manager',
              'The specified route is invalid, please change the route in static.json'
            )
        } else {
          LogWarn(
            'Hotspot Manager',
            'This route is the currently active route, this could be a case of the hotspot redirecting to the same location it is rendered in'
          )
        }
      } else if (item.function.type == hotspotBehaviours.display) {
        setRequestedFunction(item.function)
      } else {
        LogError(
          'Hotspot Manager',
          'Invalid function type detected, please use function type from the valid list given by the production developer'
        )
      }
    } else {
      LogError(
        'Hotspot Manager',
        'Invalid function type detected, please use function type from the valid list given by the production developer'
      )
    }
  }
  function onHoverEnter() {
    // console.log(`Hover Enter ${index}`)
  }
  function onHoverExit() {
    // console.log(`Hover Exit ${index}`)
  }

  return (
    <>
      {!isPanoramaTransitioning && (
        <>
          {hotspots.map((item, index) => {
            let hotspot = getHotspotByName(item.component)
            let hotspotComponent = hotspot.component
            let type = hotspot.type
            switch (type) {
              case hotspotTypeE.Transform:
                return (
                  <HTMLToCanvas
                    key={index}
                    position={item.position}
                    scale={item.scale}
                    onClick={() => onClick(item)}
                    onHoverEnter={() => onHoverEnter(item)}
                    onHoverExit={() => onHoverExit(item)}
                  >
                    {hotspotComponent}
                  </HTMLToCanvas>
                )

              case hotspotTypeE.Billboard:
                return (
                  <HTMLToCanvasSprite
                    key={index}
                    position={item.position}
                    onClick={() => onClick(item)}
                    onHoverEnter={() => onHoverEnter(item)}
                    onHoverExit={() => onHoverExit(item)}
                  >
                    {hotspotComponent}
                  </HTMLToCanvasSprite>
                )

              case hotspotTypeE.BasicMesh:
                return (
                  <HTMLToCanvasMesh
                    key={index}
                    position={item.position}
                    onClick={() => onClick(item)}
                    onHoverEnter={() => onHoverEnter(item)}
                    onHoverExit={() => onHoverExit(item)}
                  >
                    {hotspotComponent}
                  </HTMLToCanvasMesh>
                )

              case hotspotTypeE.Custom:
                return React.cloneElement(hotspotComponent, {
                  key: index,
                  ind: index,
                  position: item.position,
                  data: item.data,
                  content: item.content,
                  func: item.function,
                  onClick: () => onClick(item),
                  onHoverEnter: () => onHoverEnter(item),
                  onHoverExit: () => onHoverExit(item),
                  scale: item.scale,
                })

              default:
                LogError(
                  'Hotspot Manager',
                  'Hotspot type: ' +
                    type +
                    ' is not a valid type of hotspot, please register it in hotspot-types.js and Hotspot Manager'
                )
                break
            }
          })}
        </>
      )}
    </>
  )
}

// function getHotspotComponentByName(name) {
//   for (var ind in hotspotTypes) {
//     if (hotspotTypes[ind].name == name) {
//       return hotspotTypes[ind].component
//     }
//   }
// }

function getHotspotByName(name) {
  for (var ind in hotspotTypes) {
    if (hotspotTypes[ind].name == name) {
      return hotspotTypes[ind]
    }
  }
}

HotspotManager.propTypes = {
  hotspots: PropTypes.array,
}
{
  /* {hotspots.forEach((item) => {
        return <Hotspot item={item} />
      })} */
}

/**
        // if (type == 'Transform') {
        //   return (
        //     <HTMLToCanvas
        //       key={index}
        //       position={item.position}
        //       onClick={() => onClick(index)}
        //       onHoverEnter={() => onHoverEnter(index)}
        //       onHoverExit={() => onHoverExit(index)}
        //     >
        //       {hotspotComponent}
        //     </HTMLToCanvas>
        //   )
        // } else if (type == 'Billboard') {
        //   return (
        //     <HTMLToCanvasSprite
        //       key={index}
        //       position={item.position}
        //       onClick={() => onClick(index)}
        //       onHoverEnter={() => onHoverEnter(index)}
        //       onHoverExit={() => onHoverExit(index)}
        //     >
        //       {hotspotComponent}
        //     </HTMLToCanvasSprite>
        //   )
        // } else if (type == 'Mesh') {
        //   console.log(type)
        //   return (
        //     <HTMLToCanvasMesh
        //       key={index}
        //       position={item.position}
        //       onClick={() => onClick(index)}
        //       onHoverEnter={() => onHoverEnter(index)}
        //       onHoverExit={() => onHoverExit(index)}
        //     >
        //       {hotspotComponent}
        //     </HTMLToCanvasMesh>
        //   )
        //   } else if (type == 'Custom') {
        //     console.log(type)
        //     return React.cloneElement(hotspotComponent, {
        //       onClick: () => onClick(index),
        //       onHoverEnter: () => onHoverEnter(index),
        //       onHoverExit: () => onHoverExit(index),
        //     })
        //   } else {
        //     console.log('Type not Found')
        //   }
 */
