import React from 'react'

function Fullscreen() {
  return (
    <svg
      style={{ width: '5vh', height: '5vh' }}
      // width="96"
      // height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 56H20V76H40V68H28V56ZM20 40H28V28H40V20H20V40ZM68 68H56V76H76V56H68V68ZM56 20V28H68V40H76V20H56Z"
        fill="white"
      />
    </svg>
  )
}

export default Fullscreen
