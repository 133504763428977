import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import PropTypes from 'prop-types'

function Next({ color }) {
  return (
    <svg
      // width="25" height="25"
      style={{ width: isMobileOnly ? '4.0vh' : '3.3vh', height: isMobileOnly ? '4.0vh' : '3.3vh' }}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.688 12.208L6.24376 21.1708C5.71212 21.5253 5 21.1441 5 20.5052L5 3.49481C5 2.85586 5.71212 2.47474 6.24376 2.82917L19.688 11.792C19.8364 11.8909 19.8364 12.1091 19.688 12.208Z"
        fill={color}
      />
    </svg>
  )
}

export default Next

Next.propTypes = {
  color: PropTypes.object,
}
