import React from 'react'
import { isMobileOnly } from 'react-device-detect'

function MapIcon() {
  return (
    <svg
      // width="25" height="25"
      style={{ width: isMobileOnly ? '4.3vh' : '3.3vh', height: isMobileOnly ? '4.3vh' : '3.3vh' }}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3128 18.6087C14.7841 17.8447 19.8293 14.8642 19.8293 9.91465C19.8293 6.09579 16.7335 3 12.9146 3C9.09579 3 6 6.09579 6 9.91465C6 14.8642 11.0452 17.8447 12.5165 18.6087C12.7693 18.7399 13.06 18.74 13.3128 18.6087ZM12.9159 12.878C14.5526 12.878 15.8794 11.5512 15.8794 9.91459C15.8794 8.27793 14.5526 6.95116 12.9159 6.95116C11.2793 6.95116 9.95251 8.27793 9.95251 9.91459C9.95251 11.5512 11.2793 12.878 12.9159 12.878Z"
        fill="white"
      />
      <path
        d="M18.903 16.5549C19.5099 17.0054 19.8294 17.5164 19.8294 18.0366C19.8294 18.5568 19.5099 19.0678 18.903 19.5183C18.2961 19.9688 17.4232 20.3429 16.3721 20.603C15.3209 20.8631 14.1285 21 12.9147 21C11.701 21 10.5086 20.8631 9.45742 20.603C8.40626 20.3429 7.53337 19.9688 6.92648 19.5183C6.31959 19.0678 6.00009 18.5568 6.00009 18.0366C6.00009 17.5164 6.31959 17.0054 6.92648 16.5549"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default MapIcon
