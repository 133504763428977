import React, { useEffect, useState } from 'react'
import HTMLToCanvasSprite from './../../../core/Hotspots/HTMLToCanvasSprite'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite'
import { isMobile } from 'react-device-detect'
import { useSpring } from '@react-spring/core'
import { a as animated } from '@react-spring/web'
// import { Color_BlackTransparent, Color_White } from '../../../../../theme'

export default function HoverStateHotspot({
  position,
  onClick,
  onHoverEnter,
  onHoverExit,
  scale,
  content,
  debug,
}) {
  useEffect(() => {}, [])
  const [hover, setHover] = useState(false)

  function OnClickCustomBehaviour() {
    if (onClick != null) onClick()
    // Add you custom Behaviour
  }
  function OnHoverEnterCustomBehaviour() {
    setHover(true)
    // Add you custom Behaviour
    if (onHoverEnter != null) onHoverEnter() // this line is mandatory for navigation system to work
  }
  function OnHoverExitCustomBehaviour() {
    setHover(false)
    // Add you custom Behaviour
    if (onHoverExit != null) onHoverExit() // this line is mandatory for navigation system to work
  }
  const hoverAnim = useSpring({
    width: hover ? '100%' : '0%',

    // backgroundColor: hover ? '#7FB843' : '#ffffff',
    display: hover ? 'flex' : 'none',
  })
  const delayAnim = useSpring({
    opacity: hover ? 1 : 0,
    delay: 200,
  })
  return (
    <>
      <HTMLToCanvasSprite
        position={position}
        scale={scale}
        debug={debug}
        onClick={OnClickCustomBehaviour}
        onHoverEnter={OnHoverEnterCustomBehaviour}
        onHoverExit={OnHoverExitCustomBehaviour}
      >
        <>
          <div
            className={css(styles.container)}
            id="containerDiv"
            onMouseOver={!isMobile ? onHoverEnter : null}
            onMouseOut={!isMobile ? onHoverExit : null}
          >
            <div className={css(styles.divContainer)}>
              <animated.div className={css(styles.hotspotContainer)} style={hoverAnim}>
                <div className={css(styles.textContainer)}>
                  <animated.div className={css(styles.locText)} style={delayAnim}>
                    {content != null ? content.text : 'Dummy Content'}
                  </animated.div>
                </div>
              </animated.div>
            </div>
            <div className={css(styles.imageContainer)}>
              <img
                src={content != null ? content.image : 'dummyImage'}
                alt="branding_logo"
                className={css(styles.image)}
              />
              <img
                src="/images/HotspotShape.svg"
                alt="branding_logo"
                className={css(styles.arrowImage)}
              />
            </div>
          </div>
        </>
      </HTMLToCanvasSprite>
    </>
  )
}
const styles = StyleSheet.create({
  container: {
    pointerEvents: 'auto',
    cursor: 'pointer',
    display: 'flex',
    '@media(max-width: 576px) and (orientation:landscape)': {
      transform: 'scale(0.6)',
    },
    '@media (min-width: 576px) and (orientation:landscape)': {
      transform: 'scale(0.6)',
    },
    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px)': {
      transform: 'scale(0.6)',
    },
    // Large devices (desktops, 992px and up)
    '@media (min-width: 992px)': {
      transform: 'scale(1)',
    },

    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      transform: 'scale(1)',
    },

    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      transform: 'scale(1)',
    },
  },
  divContainer: {
    position: 'absolute',
  },
  hotspotContainer: {
    height: 40,
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    borderRadius: 50,
    paddingRight: 10,
    color: 'rgb(255,255,255,1)',
    backgroundColor: '#7FB843',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  imageContainer: {
    // backgroundColor: 'red',
    margin: 0,
    padding: 0,
    position: 'absolute',
    userSelect: 'none',
  },
  image: {
    height: 40,
    width: 40,
    // marginLeft: 10,
    position: 'absolute',
    borderRadius: '50%',
    border: '2px solid #ffffff',
  },
  arrowImage: {
    height: 47,
    width: 41,
    textAlign: 'center',
  },
  locText: {
    fontSize: 16,
    paddingRight: 10,
    marginLeft: 45,
    userSelect: 'none',
    fontFamily: 'WorkSans-Bold',
  },
})

HoverStateHotspot.propTypes = {
  position: PropTypes.array,
  scale: PropTypes.array,
  onClick: PropTypes.func,
  onHoverEnter: PropTypes.func,
  onHoverExit: PropTypes.func,
  content: PropTypes.object,
  debug: PropTypes.bool,
}
