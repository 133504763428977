import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import PropTypes from 'prop-types'
import { Html } from '@react-three/drei'
import CloseIcon from '../../assets/svgs/CloseIcon'
import { useHud } from '../../stores'
import { fadeIn } from 'react-animations'
import { fadeOut } from 'react-animations'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.css'
import Previous from '../../assets/svgs/Previous'
import Next from '../../assets/svgs/Next'
import '../../assets/css/carousel.css'
import { isMobileOnly } from 'react-device-detect'

export const CertiCarousel = ({ content }) => {
  const customRenderThumb = () =>
    content != null &&
    content.type != null &&
    content.type.map((items, index) => {
      //   return( {content != null && <img src={items.thumbs} alt="stamps" key={index} />
      // })
      return (
        <>
          {content != null && (
            <>
              <img src={items.thumbs} alt="stamps" key={index} />
              <div className={css(styles.thumbTitle)}>{items.thumbTitle}</div>
            </>
          )}
        </>
      )
    })
  return (
    <Carousel
      showThumbs={true}
      showStatus={false}
      infiniteLoop
      showIndicators={false}
      // emulateTouch
      // autoPlay
      useKeyboardArrows
      transitionTime={1000}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            onClick={onClickHandler}
            className={css(styles.btn)}
            title={label}
            style={{
              left: '30%',
            }}
          >
            <Previous />
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            className={css(styles.btn)}
            style={{
              right: '30%',
            }}
          >
            <Next color={'#ffffff'} />
          </button>
        )
      }
      renderThumbs={customRenderThumb}
      // width="600px"
    >
      {content != null &&
        content.type != null &&
        content.type.map((items, index) => {
          return (
            <div className={css(styles.slideHolder)} key={index}>
              {content != null && <img src={items.source} width="100%" height="100%" />}
            </div>
          )
        })}
    </Carousel>
  )
}

function PDFViewer({ content, onClose, animationEnd }) {
  const { requestedFunction } = useHud()

  return (
    <>
      <Html fullscreen>
        <div
          className={css(
            styles.FSContainer,
            requestedFunction != null ? styles.fadeInAnim : styles.fadeOutAnim
          )}
          onAnimationEnd={animationEnd}
          id="disableScroll"
        >
          <div className={css(styles.buttonContainer)}>
            <button className={css(styles.button)} onClick={onClose}>
              <CloseIcon />
            </button>
          </div>
          <CertiCarousel content={content} />
        </div>
      </Html>
    </>
  )
}

export default PDFViewer

PDFViewer.propTypes = {
  content: PropTypes.object,
  onClose: PropTypes.func,
  animationEnd: PropTypes.func,
}

CertiCarousel.propTypes = {
  content: PropTypes.object,
}

const styles = StyleSheet.create({
  // ':global(.carousel .control-dots .dot)': {
  //   backgroundColor: 'blue',
  // },
  thumbTitle: {
    // position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2vh',
    fontFamily: 'WorkSans-Regular',
    background: 'rgba(0,0,0,0.7)',
    color: '#ffffff',
  },
  thumb: {
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2vh',
  },
  slideHolder: {
    width: `${isMobileOnly ? '21%' : '27%'}`,
    pointerEvents: 'auto',
    marginLeft: '50%',
    transform: 'translateX(-50%)',
  },
  title: {
    fontSize: '3vh',
    fontFamily: 'WorkSans-Medium',
    color: '#ffffff',
    marginBottom: '2vh',
  },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
  },
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  buttonContainer: {
    position: 'absolute',
    right: '5vh',
    top: '5vh',
  },
  btn: {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    // width: 30,
    // height: 30,
    cursor: 'pointer',
    outline: 0,
    border: 0,
    background: 'none',
    height: 'auto',
    width: 'auto',
    pointerEvents: 'auto',
    opacity: 0.5,
    ':hover': {
      opacity: 1,
    },
  },
  button: {
    outline: 0,
    border: 0,
    background: 'none',
    height: 'auto',
    width: 'auto',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
  },
  FSContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(8px)',
    // zIndex: 1,
    pointerEvents: 'none',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ContentContainer: {
    height: 'auto',
    width: '35%',
    pointerEvents: 'auto',
    marginLeft: '50%',
    transform: 'translateX(-50%)',
    // background: 'blue',
  },
  content: {
    width: '100%',
    height: '100%',
  },
})
