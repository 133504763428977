import React, { useState, useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import InfoHotspotIcon from '../../../assets/svgs/InfoHotspotIcon'
import InfoCloseIcon from '../../../assets/svgs/InfoCloseIcon'
import { cameraStore, useAutoTour, useHud } from '../../../stores'
import PropTypes from 'prop-types'
import HTMLToCanvasSprite from '../../../core/Hotspots/HTMLToCanvasSprite'
import { sendEvent } from '../../../../../helper/google-analytics'

function PdfViewerHS({
  func,
  position,
  onClick,
  onHoverEnter,
  onHoverExit,
  scale,
  // content,
  debug,
  ind,
}) {
  const { setDesc, setPanoramaDesc, setInfoIndex } = useHud()
  const { setAutoStartTour, setAutoTour } = useAutoTour()
  const [infoInd, setInfoInd] = useState(-1)
  const cameraState = cameraStore()

  useEffect(() => {
    const unsub = useHud.subscribe(
      (paw) => {
        setInfoInd(paw)
        // console.log('desc: ' + paw)
      },
      (state) => state.infoIndex
    )
    return function cleanup() {
      unsub()
    }
  }, [infoInd])

  function OnClickCustomBehaviour() {
    if (onClick != null) {
      onClick()

      setPanoramaDesc(false)
      cameraState.setRotate(false)
      setAutoStartTour(false)
      setAutoTour(false)

      if (func.index != null) {
        if (func.index === ind) {
          setInfoIndex(ind)
          setInfoInd(ind)
        } else {
          setInfoIndex(-1)
          setInfoInd(-1)
        }
      }

      setDesc(true)
    }
    sendEvent(`PDF Hotspot clicked`, 'Hotspots', 'PDF Hotspot')
    // Add you custom Behaviour
  }
  function OnHoverEnterCustomBehaviour() {
    // Add you custom Behaviour

    if (onHoverEnter != null) onHoverEnter() // this line is mandatory for navigation system to work
  }
  function OnHoverExitCustomBehaviour() {
    // Add you custom Behaviour
    if (onHoverExit != null) onHoverExit() // this line is mandatory for navigation system to work
  }

  return (
    <>
      <HTMLToCanvasSprite
        position={position}
        scale={scale}
        debug={debug}
        onClick={OnClickCustomBehaviour}
        onHoverEnter={OnHoverEnterCustomBehaviour}
        onHoverExit={OnHoverExitCustomBehaviour}
      >
        <div
          // onClick={() => {
          //   setDesc(true)
          //   setAutoTour(false)
          // }}
          style={{
            cursor: 'pointer',
            height: isMobileOnly ? '7.6vh' : '5.6vh',
            width: isMobileOnly ? '7.6vh' : '5.6vh',
          }}
        >
          {/* <InfoHotspotIcon /> */}

          {!debug && infoInd === func.index ? <InfoCloseIcon /> : <InfoHotspotIcon />}
        </div>
      </HTMLToCanvasSprite>
    </>
  )
}

export default PdfViewerHS

PdfViewerHS.propTypes = {
  position: PropTypes.array,
  scale: PropTypes.array,
  onClick: PropTypes.func,
  onHoverEnter: PropTypes.func,
  onHoverExit: PropTypes.func,
  content: PropTypes.object,
  func: PropTypes.object,
  debug: PropTypes.bool,
  ind: PropTypes.number,
}
