import React, { Suspense, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isMobile, isTablet } from 'react-device-detect'
import PanoramaTexture from './PanoramaTexture'
import { useStatic } from '../stores'

function TextureManager({ texture, position }) {
  const { baseURL } = useStatic()
  const [texPath, setTextPath] = useState(null)
  useEffect(() => {
    let tex = '/' + texture.index
    setTextPath(
      isTablet
        ? tex + '/' + texture.sizes.tablet + '.webp'
        : isMobile
        ? tex + '/' + texture.sizes.mobile + '.webp'
        : tex + '/' + texture.sizes.desktop + '.webp'
    )
  }, [texture])
  return (
    <>
      <Suspense fallback={null}>
        <PanoramaTexture texture={texPath} baseURL={baseURL} position={position} />
      </Suspense>
    </>
  )
}

export default TextureManager

TextureManager.propTypes = {
  texture: PropTypes.object,
  position: PropTypes.array,
}
