import React from 'react'
import PropTypes from 'prop-types'
// import { Html } from '@react-three/drei'

export default function HTMLToCanvasMesh({
  children,
  onHoverEnter,
  onHoverExit,
  onClick,
  position,
}) {
  return (
    <group
      position={position}
      onClick={onClick}
      onPointerOver={onHoverEnter}
      onPointerOut={onHoverExit}
    >
      {/* <Html> */}
      {/* <div > */}
      {children}
      {/* </div> */}
      {/* </Html> */}
    </group>
  )
}

HTMLToCanvasMesh.propTypes = {
  children: PropTypes.element,
  onHoverEnter: PropTypes.func,
  onHoverExit: PropTypes.func,
  onClick: PropTypes.func,
  position: PropTypes.array,
}
