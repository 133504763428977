import React from 'react'
import { isMobileOnly } from 'react-device-detect'

function AccountIcon(props) {
  return (
    <svg
      style={{ width: isMobileOnly ? '4.3vh' : '3.3vh', height: isMobileOnly ? '4.3vh' : '3.3vh' }}
      // width="25" height="25"

      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 5.25C14.2375 5.25 15.25 6.2625 15.25 7.5C15.25 8.7375 14.2375 9.75 13 9.75C11.7625 9.75 10.75 8.7375 10.75 7.5C10.75 6.2625 11.7625 5.25 13 5.25ZM13 16.5C16.0375 16.5 19.525 17.9513 19.75 18.75H6.25C6.50875 17.94 9.97375 16.5 13 16.5ZM13 3C10.5137 3 8.5 5.01375 8.5 7.5C8.5 9.98625 10.5137 12 13 12C15.4863 12 17.5 9.98625 17.5 7.5C17.5 5.01375 15.4863 3 13 3ZM13 14.25C9.99625 14.25 4 15.7575 4 18.75V21H22V18.75C22 15.7575 16.0038 14.25 13 14.25Z"
        fill="white"
      />
    </svg>
  )
}

export default AccountIcon
