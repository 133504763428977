import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { fadeIn, fadeOut, slideInRight, slideOutRight } from 'react-animations'
import BGSplash from '../assets/images/BGSplash.jpg'
import { useLocation } from 'wouter'
import { cameraStore, useHud } from '../stores'
import { isMobileOnly } from 'react-device-detect'
import CloseIcon from '../assets/svgs/CloseIcon'
import { categories } from '../location-categories'
import BackIcon from '../assets/svgs/BackIcon'
import LocationSubcategoryMenu from './LocationSubcategoryMenu'
import { sendEvent } from '../../../helper/google-analytics'

function LocationMainSection() {
  const [cat, setCate] = useState(null)
  const [ind, setIndex] = useState(-1)
  const [overlay, setOverlay] = useState(false)
  const { setFullScreen, setCategoryMenu } = useHud()
  const [slideAnim, setSlideAnim] = useState(false)
  const [location] = useLocation()
  // const { setPanoramaDesc } = useHud()
  const [fscreen, setFScreen] = useState(false)
  const cameraState = cameraStore()

  function slideOutAnim() {
    setSlideAnim(true)
    setFScreen(true)
    // console.log("fadeAnim")
  }

  function closeMenu() {
    setCategoryMenu(false)
    setFullScreen(false)
    setSlideAnim(false)
    setFScreen(true)
    cameraState.setRotate(true)
  }

  function onBack() {
    setTimeout(() => {
      setIndex(-1)
    }, 200)
  }
  function onDisabledDivWheel(event) {
    // Prevent the event from bubbling up to the document
    event.stopPropagation()
  }

  return (
    <div id="disableScroll" style={{ width: '100%', height: '100%' }}>
      <div
        className={css(styles.fullScreenContainer, fscreen ? styles.fadeOut : styles.fadeIn)}
        // onAnimationEnd={animationEnd}
        onWheel={onDisabledDivWheel}
      >
        {/* <div className={css(styles.imgContainer)}>
        <img src="/images/BGSplash.jpg" className={css(styles.image)} />
      </div> */}
        <div
          className={css(slideAnim ? styles.slidOut : styles.slideIn)}
          style={{
            // background: 'red',
            position: 'absolute',
            right: 0,
            top: 0,
            // bottom: 0,
            height: '100%',
            width: '56.57vh',
          }}
        >
          <div
            className={css(
              styles.profileContainer
              // , slideAnim ? styles.slidOut : styles.slideIn
            )}
          ></div>
          {/* //! Content */}
          <div className={css(styles.contentContainer)}>
            {/* //! Header */}
            <div className={css(styles.header)}>
              <span className={css(styles.title02)}>Locations</span>
              <div className={css(styles.CloseButtonContainer)}>
                <button
                  className={css(styles.button)}
                  onClick={() => {
                    slideOutAnim(setTimeout(() => closeMenu(), 450))
                    sendEvent(`Close button clicked`, 'Location Categories', 'Close Button')
                  }}
                >
                  <div
                    className={css(styles.svg)}
                    style={{ width: isMobileOnly ? '2vw' : '2.2vh' }}
                  >
                    <CloseIcon />
                  </div>
                </button>
              </div>
            </div>

            {/* //! Category cards */}
            <div className={css(styles.categoryGroup)}>
              {categories.map((item, index) => {
                return (
                  <>
                    <div
                      className={css(styles.cardContainer)}
                      key={index}
                      style={{
                        backgroundImage: `url(${item.image})`,
                        cursor: item.ind === ind ? 'default' : 'pointer',
                      }}
                      onClick={() => {
                        setCate(item.subcategories)
                        setIndex(index)
                        // setPanoramaDesc(true)
                        setOverlay(true)
                        sendEvent(
                          `${item.name} clicked`,
                          'Location Categories',
                          'Main Locations Card'
                        )
                      }}
                    >
                      <div
                        className={css(styles.overlay)}
                        style={{
                          display:
                            (item.ind === ind ||
                              location === item.target ||
                              item.target.includes(location.split('/').slice(0, 2).join('/'))) &&
                            location != '/'
                              ? 'none'
                              : 'block',
                        }}
                      ></div>
                      <div className={css(styles.overlayContainer)}>
                        <div
                          className={css(styles.numbContainer)}
                          style={{
                            background:
                              (item.ind === ind ||
                                location === item.target ||
                                item.target.includes(location.split('/').slice(0, 2).join('/'))) &&
                              location != '/'
                                ? '#0B2B39'
                                : 'rgba(0,0,0)',
                          }}
                        >
                          {item.ind === ind ? (
                            <div className={css(styles.backIconContainer)}>
                              <button
                                className={css(styles.backIcon)}
                                onClick={() => {
                                  onBack()
                                  sendEvent(
                                    `Closed ${item.name} card`,
                                    'Location Categories',
                                    'Main Locations Card'
                                  )
                                }}
                              >
                                <BackIcon />
                              </button>
                            </div>
                          ) : (
                            <div className={css(styles.numb)}>{item.number}</div>
                          )}
                        </div>
                        <div
                          className={css(styles.titleContainer)}
                          style={{
                            background:
                              (item.ind === ind ||
                                location === item.target ||
                                item.target.includes(location.split('/').slice(0, 2).join('/'))) &&
                              location != '/'
                                ? '#0B2B39'
                                : 'rgba(0,0,0,0.7)',
                          }}
                        >
                          <div className={css(styles.title)}>{item.name}</div>
                        </div>
                      </div>
                    </div>
                    {/* //! Subcategory cards */}
                    {cat != null && item.ind === ind && overlay && (
                      <div className={css(styles.subCatContainer)}>
                        {cat.map((item, i) => {
                          return <LocationSubcategoryMenu key={i} subcat={item} index={i} />
                        })}
                      </div>
                    )}
                  </>
                )
              })}
            </div>
          </div>
          {/* <div className={css(styles.categoryGroup)}></div> */}
        </div>
      </div>
    </div>
  )
}

export default LocationMainSection

const styles = StyleSheet.create({
  fullScreenContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.01)',
    backdropFilter: 'blur(3px)',
    zIndex: 1,
    pointerEvents: 'none',
    margin: 0,
  },
  fadeIn: {
    animationName: fadeIn,
    animationDuration: '0.3s',
  },
  fadeOut: {
    animationName: fadeOut,
    animationDuration: '0.8s',
  },
  slideIn: {
    animationName: slideInRight,
    animationDuration: '0.9s',
    // animationDelay: '2s',
  },
  slidOut: {
    animationName: slideOutRight,
    // animationDelay: '2s',
    animationDuration: '0.5s',
  },
  imgContainer: {
    width: '56.57vh',
    height: '100%',
    position: 'absolute',
    right: 0,
  },
  image: {
    width: '100%',
    height: '100%',
    opacity: 0.8,
    borderRadius: '0.8vh',
  },
  profileContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
    // width: '34vw',
    backgroundImage: `url(${BGSplash})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    opacity: 0.8,
    // background: 'red',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingLeft: '4.4vh',
    // paddingRight: '4.4vh',
    // background: 'red',
  },
  title02: {
    fontSize: '4.4vh',
    fontFamily: 'WorkSans-ExtraBold',
    color: '#ffffff',
    letterSpacing: 1,
  },
  button: {
    background: 'none',
    border: 'none',
    height: 'auto',
    width: 'auto',
    textAlign: 'center',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
  },
  svg: {
    height: 'auto',
    // width: '1.2vw',
  },
  contentContainer: {
    // width: '44.98vh',
    // height: 'auto',
    position: 'absolute',
    top: '6.6vh',
    right: 0,
    height: '100%',
    width: '56.57vh',
    paddingLeft: '6.4vh',
    paddingRight: '6.4vh',
    // paddingTop: '4%',
    pointerEvents: 'auto',
  },
  categoryGroup: {
    width: '100%',
    height: `${isMobileOnly ? '63vh' : '81vh'} `,
    // height: '81vh',
    overflowY: 'auto',
    marginTop: '2.2vh',
    paddingRight: '1%',
    '::-webkit-scrollbar': {
      width: `${isMobileOnly ? '14px' : '18px'} `,
    },
    '::-webkit-scrollbar-thumb': {
      background: '#1F1F1F',
      borderRadius: '1vw',
      border: '6px solid transparent',
      backgroundClip: 'padding-box',
      boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.025)',
    },
  },
  cardContainer: {
    width: 'auto',
    height: '23.14vh',
    marginBottom: '3%',
    backgroundPosition: 'center' /* Center the image */,
    backgroundRepeat: 'no-repeat' /* Do not repeat the image */,
    backgroundSize: 'cover',
    position: 'relative',
    borderRadius: '0.8vh',

    // background: 'red',
  },
  overlay: {
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    borderRadius: '0.8vh',
  },
  overlayContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'absolute',
    left: 0,
    // textAlign: 'center',
  },
  numbContainer: {
    width: '8.7vh',
    height: '100%',
    borderBottomLeftRadius: '0.8vh',
    borderTopLeftRadius: '0.8vh',
    borderRight: '0.4vh solid #ffffff',
    // background: '#1F1F1F',
  },
  numb: {
    width: '100%',
    height: '5.8vh',
    fontSize: `${isMobileOnly ? '2.8vh' : '2.8vh'}`,
    color: '#ffffff',
    // background: 'blue',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'WorkSans-Bold',
  },
  titleContainer: {
    width: '100%',
    height: '5.8vh',
    borderTopRightRadius: '0.8vh',
    // background: '#1F1F1F',
  },
  title: {
    width: '100%',
    height: '100%',
    fontSize: `${isMobileOnly ? '2.1vh' : '2vh'}`,
    color: '#ffffff',
    display: 'flex',
    paddingLeft: '2.2vh',
    paddingRight: '2.2vh',
    textTransform: 'uppercase',
    alignItems: 'center',
    fontFamily: 'WorkSans-Bold',
    lineHeight: '120%',
    // background: 'red',
  },
  backIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '5.8vh',
    width: 'auto',
    // background: 'red',
  },
  backIcon: {
    border: 'none',
    background: 'none',
    outline: 'none',
    width: `${isMobileOnly ? '7vh' : '4.5vh'}`,
    height: `${isMobileOnly ? '7vh' : '4.5vh'}`,
    ':hover': {
      cursor: 'pointer',
    },
  },
})
