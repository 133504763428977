import React from 'react'

function BrochureIcon() {
  return (
    <svg
      width="3.3vh"
      height="3.3vh"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.4997 34.8333H9.49967C7.75077 34.8333 6.33301 33.4156 6.33301 31.6667V6.33333C6.33301 4.58443 7.75077 3.16666 9.49967 3.16666H20.583C20.5972 3.16482 20.6116 3.16482 20.6258 3.16666H20.6353C20.6502 3.17135 20.6656 3.17453 20.6812 3.17616C20.8208 3.18511 20.9587 3.21226 21.0913 3.25691H21.115H21.1388H21.1578C21.187 3.27738 21.2145 3.30015 21.2401 3.325C21.4126 3.40167 21.5698 3.50896 21.704 3.64166L31.204 13.1417C31.3367 13.2759 31.444 13.4331 31.5207 13.6056C31.5349 13.6404 31.546 13.6737 31.5571 13.7101L31.5729 13.7544C31.6171 13.8864 31.6432 14.0239 31.6505 14.1629C31.6519 14.1787 31.6556 14.1942 31.6616 14.2088V14.2183C31.6642 14.2287 31.6658 14.2393 31.6663 14.25V31.6667C31.6663 32.5065 31.3327 33.312 30.7388 33.9058C30.145 34.4997 29.3395 34.8333 28.4997 34.8333ZM22.8377 22.1667V30.0833H24.326V26.8533H26.6377V25.5265H24.326V23.4982H26.9163V22.1667H22.8377ZM16.9793 22.1667V30.0833H18.8888C19.6899 30.1227 20.4656 29.7966 20.9978 29.1967C21.5439 28.4999 21.8175 27.628 21.7673 26.7441V25.4616C21.804 24.587 21.5228 23.7287 20.9757 23.0454C20.4605 22.459 19.7084 22.1362 18.9284 22.1667H16.9793ZM11.083 22.1667V30.0833H12.5713V27.2983H13.5625C14.2244 27.3349 14.8709 27.0899 15.3422 26.6238C15.792 26.1209 16.0252 25.4609 15.9913 24.7871C16.0207 24.0971 15.7876 23.4216 15.339 22.8966C14.8952 22.4045 14.2546 22.1367 13.5926 22.1667H11.083ZM20.583 6.33333V14.25H28.4997L20.583 6.33333ZM18.9142 28.7565H18.4677V23.4982H19.0092C19.3937 23.4756 19.7634 23.6497 19.9908 23.9606C20.223 24.4408 20.3225 24.9743 20.279 25.5059V26.8818C20.3138 27.3881 20.204 27.8938 19.9623 28.3401C19.7028 28.643 19.3108 28.7988 18.9142 28.7565ZM13.5926 25.9667H12.5698V23.4982H13.6068C13.8657 23.501 14.105 23.6368 14.2402 23.8577C14.4115 24.1404 14.4942 24.468 14.4777 24.7982C14.4975 25.1061 14.4158 25.412 14.2449 25.669C14.0875 25.8667 13.8451 25.9772 13.5926 25.9667Z"
        fill="#F2F2F2"
      />
    </svg>
  )
}

export default BrochureIcon
