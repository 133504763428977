import React, { useState, useEffect } from 'react'
import { Html } from '@react-three/drei'
import { css, StyleSheet } from 'aphrodite'
import { fadeIn } from 'react-animations'
import { fadeOut } from 'react-animations'
import { useAutoTour, usePanoramaTransition } from '../../stores'
import BGSplash from '../../assets/images/BGSplash.jpg'
import { useLocation } from 'wouter'
import { sendEvent } from '../../../../helper/google-analytics'

function ThankyouScreen() {
  const [render, setRender] = useState(false)
  const [thankyou, setThankyou] = useState(false)
  const [, setLocation] = useLocation()
  const { setThankyouScreen, setMasterIndex, setCurrentIndex, setAutoTour } = useAutoTour()
  const { setIsLoading } = usePanoramaTransition()

  useEffect(() => {
    // Start Tour & nextLoc Subscription
    const unsub = useAutoTour.subscribe(
      (paw) => {
        setThankyou(paw)
        console.log('autotour paw' + paw)
      },
      (state) => state.thankyouScreen
    )
    return function cleanup() {
      unsub()
    }
  }, [thankyou])

  useEffect(() => {
    if (thankyou) setRender(true)
  }, [thankyou])

  const onAnimationEnd = () => {
    if (!thankyou) setRender(false)
  }

  const backHome = () => {
    setThankyou(false)
    setThankyouScreen(false)
    setLocation('/')
    setIsLoading(true)
    sendEvent(`Back to home button clicked`, 'Autotour end popup', 'Back to Home Button')
  }

  const replayTour = () => {
    setThankyou(false)
    setThankyouScreen(false)
    setMasterIndex(0)
    setCurrentIndex(0)
    setAutoTour(true)
    sendEvent(`Replay autotour clicked`, 'Autotour end popup', 'Replay Button')
  }

  return (
    <>
      {render && (
        <Html fullscreen>
          <div
            className={css(styles.container, thankyou ? styles.fadeInAnim : styles.fadeOutAnim)}
            onAnimationEnd={onAnimationEnd}
          >
            {/* <img src="/images/BGSplash.jpg" className={css(styles.image)} /> */}
            <div className={css(styles.imageContainer)}></div>
            <div className={css(styles.contentContainer)}>
              {/* <div className={css(styles.checkIcon)}>
                <img
                  src="/images/icons/checkMark.svg"
                  className={css(styles.image, styles.img01)}
                />
              </div> */}
              <div className={css(styles.title)}>Thank You!</div>
              <div className={css(styles.desc)}>
                You have completed the virtual tour of <br /> Godrej Process Equipment, Dahej,
                Gujarat Plant.
              </div>
              <div className={css(styles.buttonContainer)}>
                <button className={css(styles.button)} onClick={backHome}>
                  Back to Home
                </button>
                <button
                  className={css(styles.button)}
                  style={{ marginLeft: '10vh' }}
                  onClick={replayTour}
                >
                  Replay tour
                </button>
              </div>
            </div>
          </div>
        </Html>
      )}
    </>
  )
}

export default ThankyouScreen

const styles = StyleSheet.create({
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '2s',
    animationFillMode: 'forwards',
  },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
  },
  img01: {
    opacity: 1,
  },
  imageContainer: {
    // position: 'absolute',
    // left: 0,
    // top: 0,
    // right: 0,
    // bottom: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${BGSplash})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    opacity: 0.93,
  },
  container: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    // backgroundColor: 'rgba(0, 0, 0, 0.8)',
    // backdropFilter: 'blur(8px)',
    // zIndex: 1,
    pointerEvents: 'none',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 17685591,
  },
  title: {
    fontSize: '10.8vh',
    color: '#ffffff',
    fontFamily: 'WorkSans-Regular',
    lineHeight: '150%',
    // background: 'blue',
  },
  desc: {
    fontSize: '3vh',
    color: '#ffffff',
    fontFamily: 'WorkSans-Regular',
    // width: '80%',
    lineHeight: '150%',
    // background: 'red',
  },
  checkIcon: {
    height: '25.8vh',
    width: '25.8vh',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    // background: 'red',
  },
  imgContainer: {
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    opacity: 0.9,
    objectPosition: 'bottom',
    // borderRadius: '0.8vh',
  },
  contentContainer: {
    position: 'absolute',
    // top: '10vh',
    // top: '0vh',
    // left: 0,
    // right: 0,
    // bottom: 0,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // background: 'blue',
  },
  buttonContainer: {
    color: '#ffffff',
    pointerEvents: 'auto',
    // background: 'black',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12vh',
  },
  title01: {
    color: '#ffffff',
    // textTransform: 'capitalize',
    fontSize: '3vh',
    fontFamily: 'WorkSans-Regular',
  },
  title02: {
    color: '#ffffff',
    textTransform: 'capitalize',
    fontSize: '10vh',
    fontFamily: 'WorkSans-Bold',
    letterSpacing: 3,
  },
  title03: {
    fontSize: '3.5vh',
    fontFamily: 'WorkSans-Medium',
    color: '#ffffff',
    lineHeight: '150%',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
  title04: {
    fontSize: '2.5vh',
    fontFamily: 'WorkSans-Regular',
    color: '#ffffff',
    whiteSpace: 'nowrap',
    marginTop: '1vh',
  },
  button: {
    border: '0.3vh solid #ffffff',
    background: 'none',
    padding: '2vh',
    fontSize: '2.5vh',
    fontFamily: 'WorkSans-Regular',
    borderRadius: '1vh',
    textTransform: 'uppercase',
    color: '#ffffff',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
      background: '#ffffff',
      color: '#224957',
    },
  },
})
