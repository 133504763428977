import React from 'react'
import Lottie from 'react-lottie'
import animationData from './animation.json'

export default function RotatePhoneLottie() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div
      style={{
        height: '60vw',
        width: '60vw',
      }}
    >
      <Lottie options={defaultOptions} />
    </div>
  )
}
