import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import { useLocation } from 'wouter'
import { useAutoTour, useHud, usePanoramaTransition } from '../stores'

export default function Branding() {
  const { map } = useHud()
  const [location, setLocation] = useLocation()
  const { setIsLoading } = usePanoramaTransition()
  const { thankyouScreen } = useAutoTour()

  function GoHome() {
    if (location != '/home' && location != '/' && location != '/login') {
      setIsLoading(true)
      setLocation('/')
    }
  }
  return (
    <div
      className={css(styles.container)}
      style={{
        zIndex: map || thankyouScreen ? 17695054 : 1,
        pointerEvents:
          location != '/home' && location != '/' && location != '/login' ? 'auto' : 'none',
      }}
      onClick={GoHome}
    >
      <img src="/images/GodrejLogo.svg" className={css(styles.svgImg)} />
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    textAlign: 'center',
    top: '3vh',
    left: '3vh',
    right: 0,
    padding: 0,
    width: '25vh',
    height: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
    '@media (max-width: 576px)': {
      width: '22vh',
    },
    '@media (min-width: 576px)': {
      width: '40vh',
    },
    '@media (min-width: 768px)': {
      width: '32vh',
    },
    '@media (min-width: 992px)': {
      width: '32vh',
    },
    // background: 'red',
  },
  svgImg: {
    width: '100%',
    height: 'auto',
  },
})
