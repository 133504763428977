import React, { useEffect } from 'react'
import Lottie from 'react-lottie'
import animationData from './lottie.json'

export default function PulseHotspot() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  useEffect(() => {})
  return <Lottie height={400} width={400} options={defaultOptions} />
}
