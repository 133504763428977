import React from 'react'
import { isMobileOnly } from 'react-device-detect'

function SoundOffIcon() {
  return (
    <svg
      style={{ width: isMobileOnly ? '4.3vh' : '3.7vh', height: isMobileOnly ? '4.3vh' : '3.7vh' }}
      // width="29" height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79013 9.71471C6.24504 10.03 5.78219 10.4808 5.45144 11.0321C4.09685 13.2897 4.09685 16.1102 5.45144 18.3678C5.99527 19.2742 6.89621 19.9091 7.93268 20.1164L10.187 20.5673L14.5194 25.7661L14.5836 25.8431C15.1191 26.486 15.6176 27.0844 16.0554 27.485C16.4325 27.83 17.333 28.5705 18.4838 28.1538C19.6347 27.7371 19.8525 26.5918 19.9213 26.0854C20.0011 25.4974 20.001 24.7185 20.0009 23.8818L20.0009 23.7815V22.9255L17.0009 19.9255V23.7815L17.0007 24.0574L16.824 23.8456L12.3054 18.4232C11.9873 18.0414 11.548 17.7801 11.0607 17.6826L8.52103 17.1747C8.31338 17.1331 8.13288 17.0059 8.02392 16.8243C7.23936 15.5167 7.23936 13.8832 8.02392 12.5756C8.13288 12.394 8.31338 12.2668 8.52103 12.2252L9.17073 12.0953L6.79013 9.71471ZM17.0009 14.0763V5.61837L17.0007 5.34256L16.824 5.55436L13.0308 10.1062L10.9007 7.97617L14.5194 3.6338L14.5835 3.55681C15.1191 2.91394 15.6176 2.31551 16.0554 1.9149C16.4325 1.56987 17.333 0.829448 18.4838 1.24611C19.6347 1.66277 19.8525 2.80809 19.9213 3.31453C20.0011 3.90254 20.001 4.68143 20.0009 5.51813L20.0009 5.61837L20.0009 17.0763L17.0009 14.0763Z"
        fill="white"
      />
      <rect
        x="0.687592"
        y="2.72661"
        width="2.88359"
        height="36.1541"
        rx="1.4418"
        transform="rotate(-45 0.687592 2.72661)"
        fill="white"
      />
    </svg>
  )
}

export default SoundOffIcon
