import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import PropTypes from 'prop-types'
import { useAutoTour, useHud } from '../../stores'
import { isMobileOnly } from 'react-device-detect'
import { sendEvent } from '../../../../helper/google-analytics'

export default function PanoramaInfo({ content }) {
  const [description, setDesc] = useState(true)
  const [render, setRender] = useState(false)
  const { setRequestedFunction, panoramaDesc, setPanoramaDesc, setInfoIndex } = useHud()
  const { autoTour } = useAutoTour()

  useEffect(() => {
    if (description) {
      setRequestedFunction(null)
    }
  }, [description])

  useEffect(() => {
    const unsub = useHud.subscribe(
      (paw) => {
        setDesc(paw)
        // console.log('pano desc: ' + paw)
      },
      (state) => state.panoramaDesc
    )
    return function cleanup() {
      unsub()
    }
  }, [description])

  useEffect(() => {
    if (autoTour) {
      setDesc(false)
      setPanoramaDesc(false)
    }
  }, [autoTour])

  useEffect(() => {
    if (description) setRender(true)
  }, [description])

  const onAnimationEnd = () => {
    if (!description) setRender(false)
  }

  return (
    <>
      <div className={css(styles.container)} id="disableScroll">
        <div className={css(styles.buttonContainer)}>
          <button
            className={css(styles.button)}
            onClick={() => {
              setDesc(!description)
              setPanoramaDesc(!panoramaDesc)
              setInfoIndex(-1)
              sendEvent(
                'location description',
                'Panorama Info Description',
                'Panorama Info Section'
              )
            }}
          >
            MORE INFORMATION
            {!description ? (
              <svg
                width="1.6vw"
                height="1.1vw"
                viewBox="0 0 31 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.5917 11L9.26553 15.7917L21.918 15.7917L15.5917 11Z" fill="white" />
              </svg>
            ) : (
              <svg
                width="1.6vw"
                height="1.1vw"
                viewBox="0 0 31 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.5918 15.7916L21.9181 11H9.26562L15.5918 15.7916Z" fill="white" />
              </svg>
            )}
          </button>
        </div>
        {render && (
          <div
            className={css(
              styles.description,
              description ? styles.fadeInAnim : styles.fadeOutAnim
            )}
            style={{ paddingTop: description ? '1vh' : 0 }}
            onAnimationEnd={onAnimationEnd}
            id="disableScroll"
          >
            {content != null ? content.description : null}
          </div>
        )}
      </div>
    </>
  )
}
PanoramaInfo.propTypes = {
  content: PropTypes.object,
  desc: PropTypes.bool,
}

const styles = StyleSheet.create({
  fadeInAnim: {
    maxHeight: 'calc(100vh - 200px)',
    transition: 'all .9s',
  },
  fadeOutAnim: {
    maxHeight: '0vh',
    transition: 'all .5s',
  },
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: '0.5vw',
    pointerEvents: 'auto',
    paddingTop: '3%',
    paddingLeft: '6%',
    paddingRight: '6%',
    paddingBottom: '3%',
    overflow: 'hidden',
    width: '38vw',
  },
  description: {
    color: 'white',
    fontSize: `${isMobileOnly ? '2.5vh' : '2.0vh'}`,
    textAlign: 'left',
    lineHeight: '170%',
    fontFamily: 'WorkSans-Regular',
    // maxHeight: `${isMobileOnly ? '74vh' : '74vh'}`,
    maxHeight: 'calc(100vh - 200px)',
    width: '100%',
    overflowY: 'auto',
    whiteSpace: 'pre-wrap',
    '::-webkit-scrollbar': {
      width: `${isMobileOnly ? '4vh' : '2.0vh'}`,
    },
    '::-webkit-scrollbar-thumb': {
      background: '#2F6785',
      borderRadius: `${isMobileOnly ? '2vw' : '0.5vw'}`,
      border: '5px solid transparent',
      backgroundClip: 'padding-box',
      boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.025)',
    },
  },
  buttonContainer: {
    width: 'auto',
  },
  button: {
    border: 0,
    padding: 0,
    background: 'none',
    outline: 'none',
    fontFamily: 'Worksans-Bold',
    fontSize: `${isMobileOnly ? '2.5vh' : '2.0vh'}`,
    textAlign: 'left',
    lineHeight: '150%',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
  },
  displayNone: {
    display: 'none',
  },
})
