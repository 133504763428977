import React, { useEffect, useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite'
import { useLocation } from 'wouter'
import { useHud, usePanoramaTransition } from '../stores'
import { sendEvent } from '../../../helper/google-analytics'
import { baseURL, magicLinkDomainURL } from '../../../constants'

function NewuserForm({ setSlideAnim, setFScreen }) {
  const [newUserData, setNewUserData] = useState(null)
  const [extraTime, setExtraTime] = useState(0)
  const [isCopied, setIsCopied] = useState(false)
  const [state, setState] = useState({
    oneHourBtnState: true,
    sixHourBtnState: true,
    nineHourBtnState: true,
    twelveHourBtnState: true,
    twentyFourHourBtnState: true,
    fourtyEightHourBtnState: true,
  })
  const [location, setLocation] = useLocation()
  const { setIsLoading } = usePanoramaTransition()
  const { setFullScreen, setProfileUI } = useHud()
  const [magicLink, setMagicLink] = useState(false)

  const domainURL = `${magicLinkDomainURL}?r=`

  function visitWebsite() {
    if (location != '/') {
      setLocation('/')
      setIsLoading(true)
    }

    setProfileUI(false)
    setFullScreen(false)

    setSlideAnim(false)
    setFScreen(false)
  }

  // ! onClick select hours
  function OnButtonClicked(feature) {
    switch (feature) {
      case 'oneHour':
        setState({
          ...state,
          sixHourBtnState: false,
          nineHourBtnState: false,
          twelveHourBtnState: false,
          twentyFourHourBtnState: false,
          fourtyEightHourBtnState: false,
          oneHourBtnState: true,
        })
        break
      case 'sixHour':
        setState({
          ...state,
          oneHourBtnState: false,
          nineHourBtnState: false,
          twelveHourBtnState: false,
          twentyFourHourBtnState: false,
          fourtyEightHourBtnState: false,
          sixHourBtnState: true,
        })
        break
      case 'nineHour':
        setState({
          ...state,
          oneHourBtnState: false,
          sixHourBtnState: false,
          twelveHourBtnState: false,
          twentyFourHourBtnState: false,
          fourtyEightHourBtnState: false,
          nineHourBtnState: true,
        })
        break
      case 'twelveHour':
        setState({
          ...state,
          oneHourBtnState: false,
          sixHourBtnState: false,
          nineHourBtnState: false,
          twentyFourHourBtnState: false,
          fourtyEightHourBtnState: false,
          twelveHourBtnState: true,
        })
        break
      case 'twentyFourHour':
        setState({
          ...state,
          oneHourBtnState: false,
          sixHourBtnState: false,
          nineHourBtnState: false,
          twelveHourBtnState: false,
          fourtyEightHourBtnState: false,
          twentyFourHourBtnState: true,
        })
        break
      case 'fourtyEightHour':
        setState({
          ...state,
          oneHourBtnState: false,
          sixHourBtnState: false,
          nineHourBtnState: false,
          twelveHourBtnState: false,
          twentyFourHourBtnState: false,
          fourtyEightHourBtnState: true,
        })
        break
      default:
        break
    }
  }

  // ! Copy Magic Link
  let HandleCopy = () => {
    navigator.clipboard.writeText(document.getElementById('magicLinkUrl').value)
    setIsCopied(true)
    sendEvent('Copied magic link', 'Create Magic Link', 'Copy Magic Link Button')
  }

  // ! Resets magic link state and allows to create new link
  let ResetNewForm = () => {
    setExtraTime(0)
    setNewUserData(null)
    setIsCopied(false)
    setMagicLink(false)
    setState({
      ...state,
      oneHourBtnState: true,
      nineHourBtnState: true,
      twelveHourBtnState: true,
      twentyFourHourBtnState: true,
      fourtyEightHourBtnState: true,
      sixHourBtnState: true,
    })
    sendEvent('Reset magic link', 'Create Magic Link', 'Reset Magic Link Button')
    //TODO: REMOVE OLD MAGIC LINK - Done
  }

  // ! Generates random user and pasword
  let GenerateID = () => {
    let result = ''
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let charactersLength = characters.length
    for (var i = 0; i < 11; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  let GeneratePassword = () => {
    const capitalLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz'
    const specialCharacters = '$_'
    const numbers = '0123456789'
    let result = ''

    // Add one capital letter
    result += capitalLetters[Math.floor(Math.random() * capitalLetters.length)]

    // Add one capital letter
    result += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)]

    // Add two special characters
    for (let i = 0; i < 2; i++) {
      result += '@' + specialCharacters[Math.floor(Math.random() * specialCharacters.length)]
    }

    // Add one number
    result += numbers[Math.floor(Math.random() * numbers.length)]

    // Fill the rest of the string with random characters
    while (result.length < 11) {
      const allCharacters = capitalLetters + specialCharacters + lowercaseLetters + numbers
      result += allCharacters[Math.floor(Math.random() * allCharacters.length)]
    }

    return result
  }

  // ! Set expiry date according to the UTC format
  let GetExpiryForUser = () => {
    let date = new Date()
    console.log('date: ', date)
    // let month = date.getUTCMonth()
    // let currDate = date.getUTCDate()
    let hours = date.setUTCHours(date.getUTCHours() + extraTime)
    let expiryDate = date.getUTCDate(hours)
    let expiryMonth = date.getUTCMonth(hours)
    console.log('expiry month: ', date.getUTCMonth())

    // if (extraTime >= 24) {
    //   extraDate = Math.floor(extraTime / 24)
    //   currDate = date.getUTCDate() + extraDate
    // } else {
    //   currDate = date.getUTCDate()
    // }

    let minutes = date.getUTCMinutes()

    expiryDate < 10
      ? (expiryDate = 0 + expiryDate.toString())
      : (expiryDate = expiryDate.toString())

    expiryMonth < 10 && expiryMonth != 9
      ? (expiryMonth = 0 + (expiryMonth + 1).toString())
      : (expiryMonth = (expiryMonth + 1).toString())

    date.getUTCHours() < 10
      ? (hours = 0 + date.getUTCHours().toString())
      : (hours = date.getUTCHours()).toString()

    date.getUTCMinutes() < 10
      ? (minutes = 0 + date.getUTCMinutes().toString())
      : (minutes = date.getUTCMinutes().toString())
    // console.log(
    //   'utc hours: ' + date.getUTCDate() + 'extraTime: ' + extraTime + 'total hours: ' + hours
    // )
    // console.log('expiry date: ' + expiryDate + 'expiry month: ' + expiryMonth)

    return (
      date.getUTCFullYear().toString() +
      '-' +
      expiryMonth +
      '-' +
      expiryDate +
      'T' +
      hours +
      ':' +
      minutes
    )
  }

  // ! Creates random user with selected date
  let CreateUser = () => {
    let generatedUserName = GenerateID()
    console.log('new string: ' + GeneratePassword())
    let generatedPassword = GeneratePassword()
    let expiry = GetExpiryForUser()

    let data = JSON.stringify({
      username: generatedUserName,
      email: generatedUserName + '@autoemail.com',
      password: btoa(generatedPassword),
      expiry: expiry,
    })

    console.log('magic link data: ' + data)

    return data
  }

  // ! Generate magic link jwt
  let HandleMagicLinkSubmitAsync = async () => {
    // console.log('magic link')
    setMagicLink(true)
    let GetTokenFromStorage = () => {
      if (localStorage.getItem('user')) {
        return JSON.parse(localStorage.getItem('user')).jwt
      } else {
        return JSON.parse(sessionStorage.getItem('user')).jwt
      }
    }

    let autoGeneratedData = CreateUser()
    // console.log('auto: ', autoGeneratedData)
    return await axios({
      // http://autovrse-ped-test.hiredevexperts.live/api
      method: 'POST',
      url: `${baseURL}/auth/local/register`,
      headers: {
        Authorization: `Bearer ${GetTokenFromStorage()}`,
        'Content-Type': 'application/json',
      },
      data: autoGeneratedData,
    })
      .then(function (response) {
        // console.log('res data: ', response.data)
        setNewUserData(response.data)
        sendEvent(
          'Create magic link button clicked',
          'Create Magic Link',
          'Create Magic Link Button'
        )
        // console.log(response.data)
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data)
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
        }
      })
  }

  return (
    <>
      <div>
        <div className={css(styles.titleContainer)}>
          <span className={css(styles.title01)}>Create a</span>
          <span className={css(styles.title02)}>Magic Link</span>
        </div>
        <div className={css(styles.contentContainer)}>
          <span className={css(styles.contentTitle)}>Select link expiry time:</span>
          <div className={css(styles.buttonContainer)}>
            {/* <button
              style={{
                // pointerEvents: extraTime > 0 ? 'none' : 'auto',
                opacity: state.oneHourBtnState ? 1 : 0.5,
              }}
              className={css(styles.button, styles.spacer)}
              onClick={() => {
                setExtraTime(1)
                OnButtonClicked('oneHour')
              }}
            >
              1H
            </button>

            <button
              style={{
                // pointerEvents: extraTime > 0 ? 'none' : 'auto',
                opacity: state.sixHourBtnState ? 1 : 0.5,
              }}
              className={css(styles.button, styles.spacer)}
              onClick={() => {
                setExtraTime(6)
                OnButtonClicked('sixHour')
              }}
            >
              6H
            </button>

            <button
              style={{
                // pointerEvents: extraTime > 0 ? 'none' : 'auto',
                opacity: state.nineHourBtnState ? 1 : 0.5,
              }}
              className={css(styles.button, styles.spacer)}
              onClick={() => {
                setExtraTime(9)
                OnButtonClicked('nineHour')
              }}
            >
              9H
            </button> */}

            <button
              style={{
                pointerEvents: magicLink ? 'none' : 'auto',
                opacity: state.twelveHourBtnState ? 1 : 0.5,
              }}
              className={css(styles.button, styles.spacer)}
              onClick={() => {
                setExtraTime(12)
                OnButtonClicked('twelveHour')
                sendEvent('12hr selected', 'Create Magic Link', '12hr Button')
              }}
            >
              12hr
            </button>

            <button
              style={{
                pointerEvents: magicLink ? 'none' : 'auto',
                opacity: state.twentyFourHourBtnState ? 1 : 0.5,
              }}
              className={css(styles.button, styles.spacer)}
              onClick={() => {
                setExtraTime(24)
                OnButtonClicked('twentyFourHour')
                sendEvent('20hr selected', 'Create Magic Link', '20hr Button')
              }}
            >
              24hr
            </button>

            <button
              style={{
                pointerEvents: magicLink ? 'none' : 'auto',
                opacity: state.fourtyEightHourBtnState ? 1 : 0.5,
              }}
              className={css(styles.button)}
              onClick={() => {
                setExtraTime(48)
                OnButtonClicked('fourtyEightHour')
                sendEvent('48hr selected', 'Create Magic Link', '48hr Button')
              }}
            >
              48hr
            </button>
          </div>
        </div>
        <div className={css(styles.blockButton)}>
          {newUserData != null ? (
            <input
              className={css(styles.linkContent)}
              type="text"
              value={domainURL + newUserData.user.uuid}
              id="magicLinkUrl"
            />
          ) : (
            <button
              className={css(styles.buttonContent)}
              style={{
                pointerEvents: extraTime > 0 ? 'auto' : 'none',
                backgroundColor: extraTime > 0 ? '#1E4F69' : '#4A4A4A',
                border: extraTime > 0 ? '0.3vh solid #ffffff' : '0.4vh solid #373737',
                color: extraTime > 0 ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)',
              }}
              type="submit"
              onClick={HandleMagicLinkSubmitAsync}
            >
              CREATE MAGIC LINK
            </button>
          )}
        </div>
        {newUserData != null && (
          <div className={css(styles.buttonGroup)}>
            <button className={css(styles.buttonStyle)} onClick={ResetNewForm}>
              RESET
            </button>
            <button className={css(styles.buttonStyle)} onClick={HandleCopy}>
              {isCopied ? 'COPIED!' : 'COPY LINK'}
            </button>
          </div>
        )}
      </div>
      <div className={css(styles.linkContainer)}>
        <button
          className={css(styles.link, styles.fonts)}
          onClick={() => {
            visitWebsite()
            sendEvent('Visit website clicked', 'Godrej website', 'Visit Our Website Link')
          }}
        >
          Visit Our Website
        </button>
      </div>
    </>
  )
}

NewuserForm.propTypes = {
  setNewUserForm: PropTypes.func,
  setSlideAnim: PropTypes.func,
  setFScreen: PropTypes.func,
}

const styles = StyleSheet.create({
  linkContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    textTransform: 'uppercase',
    justifyContent: 'flex-end',
    marginTop: '2vh',
  },
  fonts: {
    fontSize: '2.1vh',
    fontFamily: 'WorkSans-Medium',
    color: '#ffffff',
    lineHeight: '150%',
    whiteSpace: 'nowrap',
    background: 'none',
    padding: 0,
    margin: 0,
    border: 'none',
    textTransform: 'capitalize',
    ':hover': {
      cursor: 'pointer',
    },
  },
  link: {
    textDecoration: 'none',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#ffffff',
  },
  title01: {
    fontFamily: 'WorkSans-Regular',
    fontSize: '3.5vh',
  },
  title02: {
    fontFamily: 'WorkSans-ExtraBold',
    fontSize: '4vh',
    marginLeft: '2%',
    letterSpacing: 1,
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '8%',
    width: '100%',
    // background: 'blue',
  },
  contentTitle: {
    fontFamily: 'WorkSans-Regular',
    fontSize: '2vh',
    color: '#ffffff',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '55%',
    // background: 'red',
    // ':hover': {
    //   cursor: 'pointer',
    // },
  },
  button: {
    background: 'none',
    borderRadius: '0.2vw',
    border: '0.3vh solid #FFFFFF',
    color: '#ffffff',
    height: '5vh',
    width: '3vw',
    fontSize: '2vh',
    fontFamily: 'WorkSans-Regular',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // pointerEvents: 'auto',
    // opacity: 1,
    ':hover': {
      cursor: 'pointer',
    },
  },
  spacer: {
    marginRight: '5%',
  },
  blockButton: {
    marginTop: '8%',
  },
  buttonContent: {
    width: '100%',
    // backgroundColor: '#4A4A4A',
    // color: 'rgba(255, 255, 255, 0.5)',
    // border: 'none',
    borderRadius: '0.4vw',
    padding: '3%',
    fontSize: '1.8vh',
    fontFamily: 'WorkSans-Regular',
    ':hover': {
      cursor: 'pointer',
    },
  },
  linkContent: {
    width: '100%',
    border: 'none',
    padding: '3%',
    fontSize: '1.8vh',
    fontFamily: 'WorkSans-Regular',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    pointerEvents: 'auto',
    backgroundColor: '#1E4F69',
    color: '#ffffff',
    textAlign: 'center',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
  buttonGroup: {
    marginTop: '8%',
    display: 'flex',
  },
  buttonStyle: {
    border: 'none',
    outline: 'none',
    width: '7vw',
    height: '5vh',
    backgroundColor: '#292929',
    color: '#ffffff',
    marginRight: '5%',
    fontFamily: 'WorkSans-SemiBold',
    borderRadius: '0.4vw',
    pointerEvents: 'auto',
    fontSize: '1.8vh',
    ':hover': {
      cursor: 'pointer',
    },
  },
})

export default NewuserForm
