import { css, StyleSheet } from 'aphrodite'
import devContent from './../dev.json'
import PropTypes from 'prop-types'

export default function HudManager(props) {
  return (
    <>
      <div className={css(styles.containerLeft, styles.noInteration, styles.absolute)}>
        {props.left}
      </div>
      <div className={css(styles.containerCenter, styles.noInteration, styles.absolute)}>
        {props.center}
      </div>
      <div className={css(styles.containerRight, styles.noInteration, styles.absolute)}>
        {props.right}
      </div>
    </>
  )
}

HudManager.propTypes = {
  fullscreen: PropTypes.element,
  left: PropTypes.element,
  right: PropTypes.element,
  center: PropTypes.element,
}

const styles = StyleSheet.create({
  noInteration: {
    pointerEvents: 'none',
  },
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    // width: '100%',
    // height: '100%',
  },
  absolute: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: devContent.GridSystem.gutter,
  },
  containerLeft: {
    left: 0,
    width: '25vw',
    float: 'left',
  },
  containerRight: {
    right: 0,
    width: '25vw',
    float: 'right',
  },
  containerCenter: {
    left: '25vw',
    right: '25vw',
    textAlign: 'center',
  },
})
