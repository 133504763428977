import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'wouter'
import { cameraStore, useAutoTour, useHud, usePanoramaTransition } from '../stores'
import { masterConfig } from '../context/auto-tour/master'
import { voiceOverSource } from './VoiceOvers'

function AutoTour() {
  const [location, setLocation] = useLocation()
  const { setPopupUI, setVoiceOver, setAudioCount, audioCount, setAutoTour, setThankyouScreen } =
    useAutoTour()
  const [tour, setTour] = useState(false)
  const [currIndex, setCurrIndex] = useState(0)
  const [playing, setPlaying] = useState(true)
  const { setFullScreen } = useHud()

  const audio = useRef()
  const [audioSrc, setAudioSrc] = useState(null)
  const [outerIndex, setOuterIndex] = useState(0)
  const [opac, setOpac] = useState(false)

  const { isLoading, setIsLoading } = usePanoramaTransition()

  const cameraState = cameraStore()

  useEffect(() => {
    // Start Tour & nextLoc Subscription
    const unsub = useAutoTour.subscribe(
      (paw) => {
        setTour(paw)
        // console.log('autotour paw' + paw)
      },
      (state) => state.autoTour
    )
    const unsub2 = useAutoTour.subscribe(
      (paw) => {
        setCurrIndex(paw)
        // console.log('current index useefee' + paw)
      },
      (state) => state.currentIndex
    )

    const unsub3 = useHud.subscribe(
      (paw) => {
        setPlaying(paw)
      },
      (state) => state.isAudio
    )

    const unsub4 = useAutoTour.subscribe(
      (paw) => {
        setOuterIndex(paw)
        // console.log('outer index useefee' + paw)
      },
      (state) => state.masterIndex
    )

    const unsub5 = useAutoTour.subscribe(
      (paw) => {
        setOpac(paw)
        // console.log('outer index useefee' + paw)
      },
      (state) => state.opacity
    )

    return function cleanup() {
      unsub()
      unsub2()
      unsub3()
      unsub4()
      unsub5()
    }
  }, [tour, currIndex, playing, outerIndex, opac])

  useEffect(() => {
    //! StartTour func call if store autoTour is true
    if (tour) {
      startTour()
    }
  }, [tour])

  // ! Mute Audio
  useEffect(() => {
    if (tour && audio.current != null) {
      if (!playing) {
        audio.current.muted = true
        audio.current.volume = 0
        // }
      } else {
        audio.current.muted = false
        audio.current.volume = 0.5
      }
    }
  }, [playing, audioCount])

  let i = outerIndex

  // ! Start Tour
  function startTour() {
    // performAction(masterConfig[currIndex][0], 0)
    performAction(masterConfig[outerIndex][currIndex], currIndex)
  }

  // ! Actiondone recursive function
  function onActionDone(index) {
    if (index < masterConfig[i].length - 1) {
      index++
    } else {
      i = i + 1
      index = 0
    }
    if (i < masterConfig.length) {
      performAction(masterConfig[i][index], index)
    } else if (i >= masterConfig.length) {
      setTour(false)
      setAutoTour(false)
      cameraState.setRotate(false)
      setThankyouScreen(true)
      // i = 0
      // console.log(`reach last index`)
      return
    }
  }

  // ! Perform actions as defined in master.js
  function performAction(action, index) {
    if (action != null) {
      switch (action.type) {
        case 'navigate':
          if (location != action.target) {
            setLocation(action.target)
            setIsLoading(true)
            if (!isLoading) {
              setTimeout(() => {
                onActionDone(index)
              }, 1500)
            }
          } else {
            onActionDone(index)
          }
          break
        case 'delay':
          {
            setTimeout(() => {
              onActionDone(index)
            }, action.target)
          }
          break
        case 'voiceOver':
          {
            // audioSrc = action.target
            // audio = new Audio(voiceOverSource[sourceIndex].source)

            if (action.VOType === 'VOSkip') {
              setAudioCount(0)
              if (!isLoading) {
                let sourceIndex = action.target
                setAudioSrc(voiceOverSource[sourceIndex].source)

                if (audio.current != null) {
                  setVoiceOver(true)

                  audio.current.onended = function () {
                    audio.current.pause()
                    setAudioCount(100)
                    setTimeout(() => {
                      if (tour) {
                        onActionDone(index)
                      }
                    }, 2000)
                  }
                }
              }
            } else if (action.VOType === 'VONotSkip') {
              if (!isLoading) {
                let sourceIndex = action.target
                setAudioSrc(voiceOverSource[sourceIndex].source)

                if (audio.current != null) {
                  onActionDone(index)
                }
              }
            }
          }
          break
        case 'uicontrols':
          {
            setFullScreen(true)
            setPopupUI(action.target)
            if (audio.current != null) {
              audio.current.onended = function () {
                audio.current.pause()
                setAudioCount(100)
                setTimeout(() => {
                  setFullScreen(false)
                  setPopupUI(null)
                  onActionDone(index)
                }, 5000)
              }
            } else {
              setTimeout(() => {
                setFullScreen(false)
                setPopupUI(null)
                onActionDone(index)
              }, 5000)
            }
          }
          break
      }
    }
  }

  //! VO pause functionality
  useEffect(() => {
    if (!tour) {
      audio.current.pause()
    } else {
      if (audio.current.paused) audio.current.play()
    }
  }, [tour])

  useEffect(() => {
    if (audioSrc) {
      audio.current.pause()
      audio.current.load()
      if (audio.current.paused && tour) audio.current.play()
      audio.current.ontimeupdate = function () {
        if (audio.current != null) {
          let audioProgress = (audio.current.currentTime / audio.current.duration) * 100
          if (audioProgress != 100) {
            setAudioCount(audioProgress)
          } else {
            setAudioCount(0)
          }
        }
      }
    }
  }, [audioSrc])

  return (
    <>
      {
        <audio ref={audio}>
          <source src={audioSrc != null ? audioSrc : null} type="audio/mp3" />
        </audio>
      }
    </>
  )
}

export default AutoTour
