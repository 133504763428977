//* Intro
export const routeOneConfigs = [
  //! Subsections
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/intro/main-ext',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 0,
  },
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'voiceOver',
    VOType: 'VONotSkip',
    target: 1,
  },
  {
    type: 'uicontrols',
    target: 'PPEPopup',
  },

  {
    type: 'navigate',
    target: '/intro/main-int',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 2,
  },
]

//* Gallery
export const routeTwoConfigs = [
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/gallery',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 3,
  },
]

//* Plate Receipt
export const routeThreeConfigs = [
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/plate',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 4,
  },
]

//* Marking & Cutting
export const routeFourConfigs = [
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/mark',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 5,
  },

  //! Subsections

  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/mark/waterjet',
  },

  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 6,
  },
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/mark/gas',
  },

  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 7,
  },
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/mark/plasma',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 8,
  },
]

//* Plate Rolling
export const routeFiveConfigs = [
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/rolling',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 9,
  },

  //! Subsections

  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/rolling/haueslar',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 10,
  },
]

//* Shell
export const routeSixConfigs = [
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/shell',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 11,
  },

  //! Subsections

  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/shell/circ-1',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 12,
  },
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/shell/circ-2',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 13,
  },
]

//* Dish welding
export const routeSevenConfigs = [
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/dish-welding',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 14,
  },
]

//* Assembly
export const routeEightConfigs = [
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/assembly',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 15,
  },
]

//* Machine Shop
export const routeNineConfigs = [
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/machine-shop',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 16,
  },

  //! Subsections

  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/machine-shop/vtl2',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 17,
  },
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/machine-shop/rt-room',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 18,
  },
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/machine-shop/skoda',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 19,
  },
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/machine-shop/farros',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 20,
  },
]

//* Nozzle Storage
export const routeTenConfigs = [
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/nozzle',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 21,
  },

  // ! Subsections
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/nozzle/pipe',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 22,
  },
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/nozzle/welding',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 23,
  },
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/nozzle/overlay',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 24,
  },
]

//* Training Centre
export const routeElevenConfigs = [
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/training-center',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 25,
  },

  // ! Subsections
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/training-center/booth',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 26,
  },
]

//* NDT
export const routeTwelveConfigs = [
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/ndt',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 27,
  },

  // ! Subsections
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/ndt/mpt',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 28,
  },

  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/ndt/tofd',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 29,
  },

  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/ndt/paut',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 30,
  },

  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/ndt/ultrasonic',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 31,
  },

  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/ndt/rt-room-ext-open',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 32,
  },
]

//* Equipment
export const routeThirteenConfigs = [
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/equipment',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 33,
  },
]

//* Exterior Fab Yard
export const routeFourteenConfigs = [
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/ext-fab-yard',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 35,
  },
]

//* Jetty
export const routeFifteenConfigs = [
  {
    type: 'delay',
    target: 500,
  },
  {
    type: 'navigate',
    target: '/jetty',
  },
  {
    type: 'voiceOver',
    VOType: 'VOSkip',
    target: 36,
  },
]

export const masterConfig = [
  routeOneConfigs,
  routeTwoConfigs,
  routeThreeConfigs,
  routeFourConfigs,
  routeFiveConfigs,
  routeSixConfigs,
  routeSevenConfigs,
  routeEightConfigs,
  routeNineConfigs,
  routeTenConfigs,
  routeElevenConfigs,
  routeTwelveConfigs,
  routeThirteenConfigs,
  routeFourteenConfigs,
  routeFifteenConfigs,
  // routeSixteenConfigs,
  // routeSeventeenConfigs,
]

export const tourMapConfig = [
  '/intro',
  '/intro/main-ext',
  '/intro/main-int',
  '/intro/parking',
  '/plate',
  '/mark',
  '/rolling',
  '/shell',
  '/dish-welding',
  '/assembly',
  '/machine-shop',
  '/nozzle',
  '/training-center',
  '/ndt',
  '/equipment',
  '/ext-fab-yard',
  '/jetty',
  '/gallery',
]
