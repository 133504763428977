import React from 'react'
import { useLandscapeMode } from '../stores'
import PropTypes from 'prop-types'

export default function LandscapeDependent({ children }) {
  const { status } = useLandscapeMode()
  return <>{status && <>{children}</>}</>
}

LandscapeDependent.propTypes = {
  children: PropTypes.object,
}
