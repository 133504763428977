import React from 'react'
import PropTypes from 'prop-types'
import { Html } from '@react-three/drei'

export default function HTMLToCanvas({
  children,
  onHoverEnter,
  onHoverExit,
  onClick,
  position,
  scale,
}) {
  return (
    <group position={position} scale={scale}>
      <Html transform>
        <div onClick={onClick} onMouseEnter={onHoverEnter} onMouseLeave={onHoverExit}>
          {children}
        </div>
      </Html>
    </group>
  )
}

HTMLToCanvas.propTypes = {
  children: PropTypes.element,
  onHoverEnter: PropTypes.func,
  onHoverExit: PropTypes.func,
  onClick: PropTypes.func,
  position: PropTypes.array,
  scale: PropTypes.array,
}
