import { Html } from '@react-three/drei'
import React, { useEffect, useState } from 'react'
import { useAutoTour, useHud } from '../stores'
import Map from './ui/Map'
import ProfileUI from './ui/ProfileUI'
import Disclaimer from './ui/Disclaimer'
import PPEPopup from './ui/PPEPopup'
import LocationMainSection from '../core/LocationMainSection'

function HudComponent() {
  const [popup, setPopup] = useState(false)
  const [isMap, setIsMap] = useState(false)
  const [fScreen, setFScreen] = useState(false)
  const [isProfileUI, setIsProfileUI] = useState(false)
  const [isDisclaimer, setIsDisclaimer] = useState(false)
  const [catMenu, setCateMenu] = useState(false)
  const [tourPopup, setTourPopup] = useState(null)
  const [fade, setFade] = useState(false)

  useEffect(() => {
    const unsub = useHud.subscribe(
      (paw) => {
        setPopup(paw)
      },
      (state) => state.requestedPopup
    )
    const unsub1 = useHud.subscribe(
      (paw) => {
        setIsMap(paw)
      },
      (state) => state.map
    )
    const unsub3 = useHud.subscribe(
      (paw) => {
        setFScreen(paw)
      },
      (state) => state.fullScreen
    )
    const unsub4 = useHud.subscribe(
      (paw) => {
        setIsProfileUI(paw)
      },
      (state) => state.profileUI
    )
    const unsub5 = useHud.subscribe(
      (paw) => {
        setIsDisclaimer(paw)
      },
      (state) => state.disclaimer
    )
    const unsub6 = useHud.subscribe(
      (paw) => {
        setCateMenu(paw)
      },
      (state) => state.categoryMenu
    )
    return function cleanup() {
      unsub()
      unsub1()
      unsub3()
      unsub4()
      unsub5()
      unsub6()
    }
  }, [popup, isMap, isProfileUI, isDisclaimer, catMenu])

  useEffect(() => {
    const unsub7 = useAutoTour.subscribe(
      (paw) => {
        setTourPopup(paw)
        // console.log('tour popup: ' + paw)
      },
      (state) => state.popupUI
    )
    return function cleanup() {
      unsub7()
    }
  }, [tourPopup])

  return (
    <>
      {fScreen && (
        <Html fullscreen>
          <div
            // id="disableScroll"
            style={{
              width: '100%',
              height: '100%',
              zIndex: 10000000,
              // background: 'red',
              position: 'absolute',
              inset: 0,
            }}
          >
            {isMap && <Map />}
            {isProfileUI && <ProfileUI fade={fade} setFade={setFade} />}
            {isDisclaimer && <Disclaimer />}
            {/* {catMenu && <LocationCategoryMenu />} */}
            {catMenu && <LocationMainSection />}
            {tourPopup != null && <PPEPopup popup={tourPopup} />}
          </div>
        </Html>
      )}
    </>
  )
}
export default HudComponent
