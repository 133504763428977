import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import PropTypes from 'prop-types'
import FallbackBG from '../assets/images/FallbackBG.png'

function Fallback() {
  // { errorMessage }
  // console.log(errorMessage)

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.errorContainer)}>
        <div className={css(styles.title)}>
          <img src="/images/error.svg" className={css(styles.image)} />
        </div>
        <div className={css(styles.errorTitle)}>PAGE NOT FOUND</div>
        <div className={css(styles.errorDesc)}>
          {/* {errorMessage.statusCode === 401 && 'The link entered is invalid'}
          {errorMessage.statusCode === 403 && 'The link entered is expired'}
          {errorMessage.statusCode === 404 && 'Page not found'} */}
          The Page you are trying to reach seems to have expired.
        </div>
        <div className={css(styles.errorDesc01)}>Please contact your Administrator.</div>
      </div>
    </div>
  )
}

export default Fallback

Fallback.propTypes = {
  errorMessage: PropTypes.object,
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    inset: 0,
    // background: 'red',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${FallbackBG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // background: 'blue',
  },
  title: {
    color: '#6A6A6A',
    height: 'auto',
    width: '90vh',
    // backgroundImage: `url(${ErrorImg})`,
  },
  image: {
    width: '100%',
    height: '100%',
    filter: 'invert(18%) sepia(1%) saturate(2%) hue-rotate(5deg) brightness(95%) contrast(83%)',
    // opacity: 0.5,
    // background: 'blue',
  },
  errorTitle: {
    fontSize: '8.4vh',
    color: '#ffffff',
    fontFamily: 'WorkSans-Bold',
    lineHeight: '120%',
    marginTop: '5vh',
    textTransform: 'uppercase',
  },
  errorDesc: {
    marginTop: '3vh',
    fontSize: '2.3vh',
    fontFamily: 'WorkSans-light',
    color: '#2F6785',
  },
  errorDesc01: {
    fontSize: '2.3vh',
    fontFamily: 'WorkSans-Medium',
    lineHeight: '180%',
    color: '#2F6785',
  },
  buttonContainer: {
    marginTop: '3%',
  },
  button: {
    border: 'none',
    outline: 'none',
    width: '19.44vh',
    height: '5.1vh',
    background: '#2F6785',
    color: '#ffffff',
    fontSize: '2vh',
    fontFamily: 'WorkSans-Regular',
    borderRadius: '0.4vw',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
  },
})
