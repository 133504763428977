import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import FallbackBG from '../../assets/images/FallbackBG.jpg'
import { useAutoTour, useHud } from '../../stores'
import { fadeIn, fadeOut } from 'react-animations'
// import CloseIcon from '../../assets/svgs/CloseIcon'
import { isMobileOnly } from 'react-device-detect'
import PropTypes from 'prop-types'
import { sendEvent } from '../../../../helper/google-analytics'

function PPEPopup() {
  const { setPopupUI, popupUI, setOpacity } = useAutoTour()
  const { setFullScreen } = useHud()

  function closeMenu() {
    // setRender(false)
    setPopupUI(null)
    setFullScreen(false)
    setOpacity(false)
    sendEvent(`I Agree button clicked`, 'PPE Popup', 'I Agree Button')
  }

  return (
    <>
      {/* {console.log('inside ppe popup' + opac)} */}
      <div
        className={css(
          styles.fullScreenContainer,
          popupUI ? styles.fadeInAnim : styles.fadeOutAnim
          // fscreen ? styles.fadeOutAnim : styles.fadeInAnim
        )}
      >
        <div
          className={css(
            styles.popupContainer
            // , slideAnim ? styles.slidOut : styles.slideIn
          )}
          id="disableScroll"
        >
          {/* <div className={css(styles.btnContainer)}>
            <button
              className={css(styles.button01)}
              // onClick={() => slideOutAnim(setTimeout(() => closeMenu(), 500))}
              onClick={closeMenu}
            >
              <div className={css(styles.svg)} style={{ width: isMobileOnly ? '2vw' : '1.2vw' }}>
                <CloseIcon />
              </div>
            </button>
          </div> */}
          <div className={css(styles.erroMarkContainer)}>
            <img src="/images/PPEImages/errorMark.png" className={css(styles.image)} />
          </div>
          <div className={css(styles.title)}>Remember to put on your PPE</div>
          <div className={css(styles.infoContainer)}>
            <div className={css(styles.detailsContainer)}>
              <div className={css(styles.infoImg)}>
                <div className={css(styles.detailsImg)}>
                  <img src="/images/PPEImages/helmet 1.png" className={css(styles.image)} />
                </div>
              </div>
              <div className={css(styles.infotitle)}>
                <div className={css(styles.infotext)}>Safety Helmet</div>
              </div>
            </div>
            <div className={css(styles.detailsContainer)}>
              <div className={css(styles.infoImg)}>
                <div className={css(styles.detailsImg)}>
                  <img src="/images/PPEImages/boots 1.png" className={css(styles.image)} />
                </div>
              </div>
              <div className={css(styles.infotitle)}>
                <div className={css(styles.infotext)}>Safety Boots</div>
              </div>
            </div>
            <div className={css(styles.detailsContainer)}>
              <div className={css(styles.infoImg)}>
                <div className={css(styles.detailsImg)}>
                  <img src="/images/PPEImages/safety-glasses 1.png" className={css(styles.image)} />
                </div>
              </div>
              <div className={css(styles.infotitle)}>
                <div className={css(styles.infotext)}>Safety Glasses</div>
              </div>
            </div>
          </div>
          {/* <div className={css(styles.buttonContainer)}>
            <div className={css(styles.text)}>I Agree</div>
          </div> */}
          <button className={css(styles.text)} onClick={closeMenu}>
            I Agree
          </button>
          <div className={css(styles.downloadText)}>
            <a
              className={css(styles.button)}
              // style={{ pointerEvents: 'auto' }}
              href="/pdf/Safety_Brochure.pdf"
              // download="Safety broucher"
              target="_blank"
            >
              View safety preparation brochure
            </a>
            {/* View safety preparation brochure */}
            {/* </a> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default PPEPopup

PPEPopup.propTypes = {
  popup: PropTypes.string,
}

const styles = StyleSheet.create({
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '1s',
  },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  slideIn: {
    animationName: fadeIn,
    animationDuration: '0.9s',
    // animationDelay: '2s',
  },
  slidOut: {
    animationName: fadeOut,
    // animationDelay: '2s',
    animationDuration: '0.5s',
  },
  displayNone: {
    display: 'none',
  },
  fullScreenContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(8px)',
    zIndex: 1,
    pointerEvents: 'none',
    margin: 0,
  },
  popupContainer: {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    margin: '1.6%',
    // justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundImage: `url(${FallbackBG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
  },
  btnContainer: {
    position: 'absolute',
    top: '2%',
    right: '1%',
  },
  button01: {
    background: 'none',
    border: 'none',
    height: 'auto',
    width: 'auto',
    textAlign: 'center',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
    // backgroundColor: 'pink',
  },
  svg: {
    height: 'auto',
    // width: '1.2vw',
  },
  erroMarkContainer: {
    marginTop: '2.5vh',
    width: `${isMobileOnly ? '7.9vh' : '10.9vh'}`,
    height: 'auto',
    // background: 'blue',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: `${isMobileOnly ? '3.9vh' : '4.9vh'}`,
    fontFamily: 'WorkSans-Black',
    color: '#FFE55A',
    marginTop: `${isMobileOnly ? '2.6vh' : '4.6vh'}`,
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '106vh',
    height: 'auto',
  },
  detailsContainer: {
    width: `${isMobileOnly ? '20.7vh' : '27.7vh'}`,
    height: 'auto',
    marginTop: `${isMobileOnly ? '5.6vh' : '7.6vh'}`,
    // background: 'blue',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  infoImg: {
    width: '100%',
    height: `${isMobileOnly ? '20.7vh' : '27.7vh'}`,
    border: '0.3vh solid #FFE55A',
    background: 'rgba(32, 32, 32, 0.5)',
    borderRadius: '0.8vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infotitle: {
    width: '100%',
    height: '4.3vh',
    marginTop: '3.9vh',
    textAlign: 'center',
  },
  infotext: {
    width: '100%',
    height: '100%',
    border: '0.3vh solid #FFE55A',
    fontFamily: 'WorkSans-Medium',
    lineHeight: '65.8%',
    fontSize: '2.2vh',
    color: '#FFE55A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.4vw',
  },
  detailsImg: {
    height: `${isMobileOnly ? '10.5vh' : '18.5vh'}`,
    width: `${isMobileOnly ? '10.5vh' : '18.5vh'}`,
  },
  buttonContainer: {
    width: '36.7vh',
    height: '8.7vh',
    // padding: '12vh',
    marginTop: `${isMobileOnly ? '3.3vh' : '5.3vh'}`,
  },
  text: {
    background: '#434343',
    border: '3px solid #30657E',
    borderRadius: '0.8vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '100%',
    // height: '100%',
    marginTop: `${isMobileOnly ? '6.3vh' : '8.3vh'}`,
    padding: '1.3vh 5vh',
    color: '#ffffff',
    fontFamily: 'WorkSans-Bold',
    textTransform: 'uppercase',
    fontSize: '3.2vh',
    pointerEvents: 'auto',
    cursor: 'pointer',
  },
  downloadText: {
    // position: 'absolute',
    // bottom: '1.7vh',
    marginTop: `${isMobileOnly ? '0.8vh' : '2.3vh'}`,
  },
  button: {
    borderBottom: '0.3vh solid #FFE55A',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    margin: 0,
    padding: 0,
    background: 'none',
    color: '#FFE55A',
    fontFamily: 'WorkSans-Regular',
    fontSize: '2.2vh',
    pointerEvents: 'auto',
    // textDecoration: 'none',
    ':hover': {
      cursor: 'pointer',
    },
  },
})
