import React from 'react'

function BackIcon() {
  return (
    <svg
      // width="32" height="27"
      viewBox="0 0 32 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 8.73459L2.68708 10.2433L0.953356 8.73459L2.68708 7.22587L4 8.73459ZM12.125 26.2898C11.0204 26.2898 10.125 25.3944 10.125 24.2898C10.125 23.1852 11.0204 22.2898 12.125 22.2898L12.125 26.2898ZM10.8121 17.3139L2.68708 10.2433L5.31292 7.22587L13.4379 14.2964L10.8121 17.3139ZM2.68708 7.22587L10.8121 0.155321L13.4379 3.17276L5.31292 10.2433L2.68708 7.22587ZM4 6.73459L22.2224 6.73459L22.2224 10.7346L4 10.7346L4 6.73459ZM22.2224 26.2898L12.125 26.2898L12.125 22.2898L22.2224 22.2898L22.2224 26.2898ZM32 16.5122C32 21.9122 27.6224 26.2898 22.2224 26.2898L22.2224 22.2898C25.4133 22.2898 28 19.7031 28 16.5122L32 16.5122ZM22.2224 6.73459C27.6224 6.73459 32 11.1122 32 16.5122L28 16.5122C28 13.3213 25.4133 10.7346 22.2224 10.7346L22.2224 6.73459Z"
        fill="white"
      />
    </svg>
  )
}

export default BackIcon
