import React from 'react'
import { isMobileOnly } from 'react-device-detect'

function Previous() {
  return (
    <svg
      style={{ width: isMobileOnly ? '4.0vh' : '3.3vh', height: isMobileOnly ? '4.0vh' : '3.3vh' }}
      // width="25" height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.31202 11.792L17.7562 2.82918C18.2879 2.47475 19 2.85586 19 3.49482L19 20.5052C19 21.1441 18.2879 21.5253 17.7562 21.1708L4.31202 12.208C4.16358 12.1091 4.16358 11.8909 4.31202 11.792Z"
        fill="white"
      />
    </svg>
  )
}

export default Previous
