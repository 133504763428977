import React, { useEffect } from 'react'
// import { useSpring } from '@react-spring/core'
// import { animated } from '@react-spring/web'
import PropTypes from 'prop-types'
// import { css, StyleSheet } from 'aphrodite'
// import useLocation from 'wouter/use-location'
// import { useHud } from '../../stores'

export default function FadeOut({ children, fade, onFinished }) {
  // const [, setLocation] = useLocation()
  // const { isLoggedIn } = useHud()
  // const { routes } = useStatic()
  // const [render, setRender] = useState(false)

  // const animation = useSpring({
  //   opacity: fade ? 0 : 1,
  // onRest: (d) => {
  //   if (d.value.opacity == 0 && d.finished) {
  //     onFinished()
  //   }
  // },
  // })
  useEffect(() => {
    if (fade) {
      // setRender(true)
      onFinished()
    }
  }, [fade])

  // const onAnimationEnd = () => {
  //   if (!fade) setRender(false)
  // }
  return (
    <>
      {/* {render && ( */}
      <div
      // style={animation}
      // className={css(styles.container, fade ? styles.fadeInAnim : styles.fadeOutAnim)}
      // onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
      {/* )} */}
    </>
  )
}

FadeOut.propTypes = {
  children: PropTypes.element,
  fade: PropTypes.bool,
  onFinished: PropTypes.func,
}
