import { useFrame } from '@react-three/fiber'
import { useRef } from 'react'
import { RoundToPlace } from '../../helper'

export default function CameraDebugger() {
  const camPos = useRef(document.getElementById('cam-pos'))
  useFrame((state) => {
    camPos.current = document.getElementById('cam-pos')
    if (camPos.current != null) {
      camPos.current.innerHTML =
        RoundToPlace(state.camera.position.x, 3) +
        ',' +
        RoundToPlace(state.camera.position.y, 3) +
        ',' +
        RoundToPlace(state.camera.position.z, 3)
    }

    // console.log(state.camera.position)
  })
  return null
}
