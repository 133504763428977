import React from 'react'
import { isMobileOnly } from 'react-device-detect'

function BackButtonIcon() {
  return (
    <svg
      width="24"
      height="24"
      style={{ width: isMobileOnly ? '4.0vh' : '3.2vh', height: isMobileOnly ? '4.0vh' : '3.2vh' }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 9L3.29289 9.70711L2.58579 9L3.29289 8.29289L4 9ZM9 21C8.44772 21 8 20.5523 8 20C8 19.4477 8.44772 19 9 19L9 21ZM8.29289 14.7071L3.29289 9.70711L4.70711 8.29289L9.70711 13.2929L8.29289 14.7071ZM3.29289 8.29289L8.29289 3.29289L9.70711 4.70711L4.70711 9.70711L3.29289 8.29289ZM4 8L14.5 8L14.5 10L4 10L4 8ZM14.5 21L9 21L9 19L14.5 19L14.5 21ZM21 14.5C21 18.0898 18.0899 21 14.5 21L14.5 19C16.9853 19 19 16.9853 19 14.5L21 14.5ZM14.5 8C18.0899 8 21 10.9101 21 14.5L19 14.5C19 12.0147 16.9853 10 14.5 10L14.5 8Z"
        fill="white"
      />
    </svg>
    // <svg
    //   width="24"
    //   height="24"
    //   style={{ width: isMobileOnly ? '4.0vh' : '4.0vh', height: isMobileOnly ? '4.0vh' : '4.0vh' }}
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M22 12.5H22.5V11.5H22V12.5ZM1 12L6 14.8868V9.11325L1 12ZM22 11.5L5.5 11.5V12.5L22 12.5V11.5Z"
    //     fill="white"
    //   />
    // </svg>
  )
}

export default BackButtonIcon
