import create from 'zustand'
import staticContent from './static.json'
import devContent from './dev.json'
import { Log, LogError } from './core/CustomLogger'
import tourContent from './AutoTourConfig.json'
import { masterConfig } from './context/auto-tour/master'

export const useLandscapeMode = create((set) => ({
  status: false,
  disabled: false,
  setDisabled: (bool) => set({ disabled: bool }),
  setStatus: (state) => set({ status: state }),
}))

/**
 * useHud
 * For Rendering one element at a time on the Hud, can do multiple if you supply an array and change the logic in Hud.js
 */
export const useHud = create((set) => ({
  requestedFunction: null,
  isLoggedIn: null,
  loggedIn: false,
  requestedPopup: false,
  isAudio: true,
  map: false,
  fullScreen: false,
  profileUI: false,
  disclaimer: false,
  categoryMenu: false,
  desc: false,
  panoramaDesc: true,
  infoIndex: -1,
  welcomePopup: false,
  startTour: false,

  setStartTour: (bool) => set({ startTour: bool }),
  setWelcomePopup: (bool) => set({ welcomePopup: bool }),
  setInfoIndex: (bool) => set({ infoIndex: bool }),
  setPanoramaDesc: (bool) => set({ panoramaDesc: bool }),
  setDesc: (bool) => set({ desc: bool }),
  setCategoryMenu: (bool) => set({ categoryMenu: bool }),
  setDisclaimer: (bool) => set({ disclaimer: bool }),
  setProfileUI: (bool) => set({ profileUI: bool }),
  setFullScreen: (bool) => set({ fullScreen: bool }),
  setIsAudio: (bool) => set({ isAudio: bool }),
  setMap: (bool) => set({ map: bool }),
  setRequestedPopup: (bool) => set({ requestedPopup: bool }),
  setIsLoggedIn: (string) => set({ isLoggedIn: string }),
  setRequestedFunction: (fn) => set({ requestedFunction: fn }),
}))
export const useStatic = create(() => ({
  baseURL: staticContent.config.baseURL,
  routes: staticContent.routes,
  genericRoutes: staticContent.genericRoutes,
}))
export const useLoader = create((set, get) => ({
  state: { isLoading: false, isDone: false, hasError: false },
  items: devContent.Loader.items,
  doneItems: [],
  doneAmount: 0,
  setDoneAmount: (amount) => set({ doneAmount: amount }),
  hasFinished: () => set({ state: { isLoading: false, isDone: true, hasError: false } }),
  hasError: () => set({ state: { isLoading: false, isDone: true, hasError: true } }),
  startLoading: () => {
    // console.time('LoaderTime')
    set({ state: { isLoading: true, isDone: false, hasError: false } })
  },
  itemDoneById: (id) => {
    let state = get().state
    if (state.isLoading) {
      let itemsTemp = get().items
      let item = isIdInItems(itemsTemp, id)
      if (item != null) {
        let doneItemsTemp = get().doneItems
        let alreadyDone = isIdInItems(doneItemsTemp, id)
        if (!alreadyDone) {
          Log('Loader:Item Loaded', item)
          doneItemsTemp.push(item)
          get().setDoneAmount((doneItemsTemp.length / itemsTemp.length) * 100)
          if (doneItemsTemp.length == itemsTemp.length) {
            // console.timeEnd('LoaderTime')
            get().hasFinished()
          }
        } else {
          LogError(
            'Loader:Error',
            'This loader "' +
              item.name +
              '" was already done, please check how your code is reporting the load'
          )
          get().hasError()
        }
      } else {
        LogError(
          'Loader:Error',
          'The loader with id: ' +
            id +
            ' was not found in the registered list of loaders, please make sure your loader is present in framework/panorama-viewer/dev.json'
        )
        get().hasError()
      }
    } else {
      LogError('Loader:Not Initialized/ Suspended', 'please call startLoading() to begin tracking')
    }
  },
  itemDoneByName: () => {
    // console.log(name)
  },
}))

export const useLogin = create((set) => ({
  isManager: false,
  setIsManager: (bool) => set({ isManager: bool }),
}))

export const usePanoramaTransition = create((set) => ({
  isLoading: false,
  setIsLoading: (state) => set({ isLoading: state }),
}))

function isIdInItems(items, id) {
  for (var item in items) {
    if (items[item].id == id) return items[item]
  }
  return null
}

export const useAutoTour = create((set, get) => ({
  tourConfig: tourContent.tourConfig,
  currentIndex: 0,
  masterIndex: 0,
  autoTour: false,
  isAudioFinished: false,
  showPopup: false,
  nextLoc: false,
  pause: false,
  startTourAgain: false,
  popupUI: null,
  voiceOver: false,
  audioCount: 0,
  opacity: true,
  controlStart: false,
  thankyouScreen: false,

  /**
   * check if user interacted with screen or ui.
   *  */
  autoStartTour: false,
  autoTourButtonClick: false,
  voStatus: false,

  setAutoStartTour: (bool) => set({ autoStartTour: bool }),
  setAutoTourButtonClick: (bool) => set((state) => (state.autoTourButtonClick = bool)),
  setVOStatus: (bool) => set((state) => (state.voStatus = bool)),

  setThankyouScreen: (bool) => set({ thankyouScreen: bool }),

  setcontrolStart: (bool) => set({ controlStart: bool }),
  setOpacity: (bool) => set({ opacity: bool }),
  setAudioCount: (index) => set({ audioCount: index }),
  setVoiceOver: (bool) => set({ voiceOver: bool }),
  setPopupUI: (string) => set({ popupUI: string }),
  setCurrentIndex: (index) => set({ currentIndex: index }),
  setMasterIndex: (index) => set({ masterIndex: index }),
  setAutoTour: (bool) => set({ autoTour: bool }),
  setIsAudioFinished: (bool) => set({ isAudioFinished: bool }),
  setShowPopup: (bool) => set({ showPopup: bool }),
  setNextLoc: (bool) => set({ nextLoc: bool }),
  setPause: (bool) => set({ pause: bool }),
  setStartTourAgain: (bool) => set({ startTourAgain: bool }),

  getTourIndex: (loc) => {
    let find = 0

    if (loc === '/') {
      get().setMasterIndex(0)
      get().setCurrentIndex(0)
    } else {
      for (let ind = 0; ind < masterConfig.length; ind++) {
        for (let b = 0; b < masterConfig[ind].length; b++) {
          const typ = masterConfig[ind].find((v) => v.target === loc)
          if (typ) {
            get().setMasterIndex(ind)
            // console.log('type: ' + ind)
          }
          if (masterConfig[ind][b].target === loc) {
            find = masterConfig[ind].findIndex((v) => v.target === loc)
            get().setCurrentIndex(find)
            // console.log('find: ' + find)
          }
        }
      }
    }
  },
}))

export const useHSContent = create((set) => ({
  hotspotContent: null,
  setHotspotContent: (string) => set(() => ({ hotspotContent: string })),
}))

export const useVoiceOver = create((set) => ({
  isVoiceOver: false,
  setIsVoiceOver: (bool) => set(() => ({ isVoiceOver: bool })),
}))

export const useTourPopup = create((set) => ({
  popupId: null,
  setPopupId: (fn) => set({ popupId: fn }),
}))

export const cameraStore = create((set) => ({
  rotate: false,
  enableControls: true,
  time: setTimeout(() => null, 100000),
  setRotate: (bool) => set((state) => (state.rotate = bool)),
  setEnableControls: (bool) => set((state) => (state.enableControls = bool)),
  stopAndResume: (time) =>
    set((state) => {
      clearTimeout(state.time)
      state.rotate = false
      state.time = setTimeout(() => {
        state.resume()
      }, time || 30000)
    }),
  stop: () =>
    set((state) => {
      clearTimeout(state.time)
      state.rotate = false
    }),
  resume: () =>
    set((state) => {
      state.rotate = true
    }),
}))
