import { css, StyleSheet } from 'aphrodite'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Loader from './Loader'
import BGSplash from '../assets/images/BGSplash.jpg'
import BrandingSvg from '../assets/svgs/BrandingSvg'
import { useLoader } from '../stores'
import { isMobileOnly } from 'react-device-detect'

export default function SplashScreen() {
  const { itemDoneById } = useLoader()

  useEffect(() => {
    // LogWarn(
    //   'SplashScreen',
    //   'Starting a debug version of Loader, replace with your own logic in SplashScreen.js'
    // )
    //   startLoading()
    setTimeout(() => {
      itemDoneById(1)
    }, 1500)
  }, [])

  return (
    <Loader
      loadingBar={true}
      loading={<div></div>}
      done={<div></div>}
      content={
        <div className={css(localStyles.background)}>
          <div className={css(localStyles.branding)}>
            <BrandingSvg />
          </div>
        </div>
      }
    />
  )
}

SplashScreen.propTypes = {
  setLoading: PropTypes.func,
  onOrientationChange: PropTypes.func,
}

const localStyles = StyleSheet.create({
  background: {
    backgroundImage: `url(${BGSplash})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    textAlign: 'center',
    // zIndex: 17685591,
    height: '100%',
    width: '100%',
  },
  branding: {
    position: 'absolute',

    textAlign: 'center',
    top: '50%',
    left: '50%',
    right: 0,
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    width: `${isMobileOnly ? '30.44vh' : '50.44vh'}`,
  },
  responsiveSpinner: {
    pointerEvents: 'none',
    paddingTop: '30vh',
    transform: 'scale(0.4)',
    '@media (min-width: 576px)': {
      transform: 'scale(0.5)',
    },
    '@media (min-width: 768px)': {
      transform: 'scale(0.4)',
    },
    '@media (min-width: 992px)': {
      transform: 'scale(0.6)',
    },
    '@media (min-width: 1200px)': {
      transform: 'scale(0.7)',
    },
    '@media (min-width: 2000px)': {
      transform: 'scale(1)',
    },
  },
})
