import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AnimatedPanorama from './AnimatedPanorama'
import { TextureLoader } from 'three'

function PanoramaTexture({ texture, baseURL, position }) {
  const [textureInfo, setTextureInfo] = useState(null)

  useEffect(() => {
    if (texture != null && baseURL != null) {
      const textureLoader = new TextureLoader()
      textureLoader.load(baseURL + '' + texture, function (texture) {
        setTextureInfo(texture)
      })
    }
  }, [baseURL, texture])

  return (
    <>{textureInfo != null && <AnimatedPanorama texture={textureInfo} position={position} />}</>
  )
}

export default PanoramaTexture

PanoramaTexture.propTypes = {
  texture: PropTypes.string,
  baseURL: PropTypes.string,
  position: PropTypes.array,
}
