import React, { useState } from 'react'
import HTMLToCanvasSprite from './../../../core/Hotspots/HTMLToCanvasSprite'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite'
import { fadeOut } from 'react-animations'
import { fadeIn } from 'react-animations'

function MessageHS({
  position,
  // onClick,
  // onHoverEnter,
  // onHoverExit,
  scale,
  content,
  debug,
}) {
  const [hover, setHover] = useState(false)

  return (
    <>
      <HTMLToCanvasSprite
        position={position}
        scale={scale}
        debug={debug}
        // onClick={OnClickCustomBehaviour}
        // onHoverEnter={OnHoverEnterCustomBehaviour}
        // onHoverExit={OnHoverExitCustomBehaviour}
      >
        <>
          <div className={css(styles.container, styles.fadeAnim)}>
            <div
              className={css(styles.icon)}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <img
                src={hover ? '/images/ConstructionActive.svg' : '/images/Construction.svg'}
                className={css(styles.image)}
              />
              {/* <div className={css(styles.number)}>
                {content != null ? content.index : 'Dummy Content'}
              </div> */}
              {/* <NavigateHotspotIcon /> */}
            </div>
            {/* <div className={css(styles.numberContainer)}>
              
            </div> */}
            <div
              className={css(styles.title)}
              style={{
                backgroundColor: '#1f1f1f',
                opacity: hover ? 1 : 0,
                display: hover ? styles.fadeAnim : styles.displayNone,
              }}
            >
              {content != null ? content.title : 'Dummy Content'}
            </div>
          </div>
        </>
      </HTMLToCanvasSprite>
    </>
  )
}

export default MessageHS

MessageHS.propTypes = {
  position: PropTypes.array,
  scale: PropTypes.array,
  onClick: PropTypes.func,
  onHoverEnter: PropTypes.func,
  onHoverExit: PropTypes.func,
  content: PropTypes.object,
  func: PropTypes.object,
  debug: PropTypes.bool,
  key: PropTypes.number,
}

const styles = StyleSheet.create({
  displayNone: {
    animationName: fadeOut,
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
  },
  fadeAnim: {
    animationName: fadeIn,
    // animationDelay: '1s',
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    // background: 'red',
  },
  icon: {
    position: 'relative',
    textAlign: 'center',
    // cursor: 'pointer',
    width: '2.5vw',
    height: '2.5vw',
    // background: 'red',
  },
  image: {
    height: '100%',
    width: '100%',
  },
  numberContainer: {
    position: 'absolute',
    // top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // background: 'red',
  },
  number: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // left: 5,
    color: '#ffffff',
    textAlign: 'center',
    borderRadius: '0.4vw',
    fontFamily: 'Worksans-SemiBold',
    fontSize: '2.2vh',
  },
  title: {
    // cursor: 'pointer',
    padding: '1vh',
    // padding: '8%',
    whiteSpace: 'nowrap',
    color: '#F1C40F',
    textAlign: 'center',
    borderRadius: '0.4vw',
    // backgroundColor: '#2F6785',
    border: '1px solid #F1C40F',
    marginTop: '2%',
    fontFamily: 'Worksans-SemiBold',
    fontSize: '1.8vh',
  },
})
