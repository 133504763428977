import React from 'react'
// import { isMobileOnly } from 'react-device-detect'

function RoundClose() {
  return (
    <svg
      width="40"
      height="40"
      // style={{ width: isMobileOnly ? '3.8vh' : '3.3vh', height: isMobileOnly ? '3.8vh' : '3.3vh' }}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#999999" />
      <path
        d="M28.75 13.0125L26.9875 11.25L20 18.2375L13.0125 11.25L11.25 13.0125L18.2375 20L11.25 26.9875L13.0125 28.75L20 21.7625L26.9875 28.75L28.75 26.9875L21.7625 20L28.75 13.0125Z"
        fill="white"
      />
    </svg>
  )
}

export default RoundClose
