import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useAutoTour } from '../../../stores'
// import { useLocation } from 'wouter'
import HTMLToCanvasSprite from '../../../core/Hotspots/HTMLToCanvasSprite'
import PropTypes from 'prop-types'
import { fadeOut } from 'react-animations'
import { fadeIn } from 'react-animations'
import { sendEvent } from '../../../../../helper/google-analytics'

function NavigationHotspot({
  position,
  onClick,
  onHoverEnter,
  onHoverExit,
  scale,
  content,
  debug,
}) {
  const { setAutoTour, setAutoStartTour } = useAutoTour()

  const [hover, setHover] = useState(false)

  function OnClickCustomBehaviour() {
    if (onClick != null) {
      // if (hover) {
      onClick()
      sendEvent(`Navigation hotspot clicked`, 'Hotspots', 'Navigation Hotspot')
      //   setFirstHover(false)
      // }
    }
    setAutoStartTour(false)
    setAutoTour(false)
    // Add you custom Behaviour
  }
  function OnHoverEnterCustomBehaviour() {
    setHover(true)
    // Add you custom Behaviour
    if (onHoverEnter != null) onHoverEnter() // this line is mandatory for navigation system to work
  }
  function OnHoverExitCustomBehaviour() {
    setHover(false)
    // Add you custom Behaviour
    if (onHoverExit != null) onHoverExit() // this line is mandatory for navigation system to work
  }

  return (
    <HTMLToCanvasSprite
      position={position}
      scale={scale}
      debug={debug}
      onClick={OnClickCustomBehaviour}
      onHoverEnter={OnHoverEnterCustomBehaviour}
      onHoverExit={OnHoverExitCustomBehaviour}
    >
      {/* <div className={css(styles.container)}> */}
      <>
        <div className={css(styles.container, styles.fadeAnim)}>
          <div className={css(styles.icon)} id="hoverMe">
            <img
              src={hover ? '/images/HaverHS.svg' : '/images/NavHS.svg'}
              className={css(styles.image)}
            />
            {/* <div className={css(styles.number)}>
              {content != null ? content.index : 'Dummy Content'}
            </div> */}
            {/* <NavigateHotspotIcon /> */}
          </div>
          {/* <div className={css(styles.numberContainer)}>
              
            </div> */}
          <div
            className={css(styles.title)}
            style={{
              backgroundColor: '#AA2555',
              opacity: hover ? 1 : 0,
              display: hover ? styles.fadeAnim : styles.displayNone,
            }}
          >
            {content != null ? content.title : 'Dummy Content'}
          </div>
        </div>
      </>
      {/* </div> */}
    </HTMLToCanvasSprite>
  )
}

export default NavigationHotspot

const styles = StyleSheet.create({
  displayNone: {
    animationName: fadeOut,
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
  },
  fadeAnim: {
    animationName: fadeIn,
    // animationDelay: '1s',
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    // background: 'red',
  },
  icon: {
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    width: '2.5vw',
    height: '2.5vw',
    // background: 'red',
  },
  image: {
    height: '100%',
    width: '100%',
  },
  numberContainer: {
    position: 'absolute',
    // top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // background: 'red',
  },
  number: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // left: 5,
    color: '#ffffff',
    textAlign: 'center',
    borderRadius: '0.4vw',
    fontFamily: 'Worksans-SemiBold',
    fontSize: '2.2vh',
  },
  title: {
    cursor: 'pointer',
    padding: '1vh',
    // padding: '8%',
    whiteSpace: 'nowrap',
    color: '#ffffff',
    textAlign: 'center',
    borderRadius: '0.4vw',
    // backgroundColor: '#2F6785',
    border: '1px solid #ffffff',
    marginTop: '2%',
    fontFamily: 'Worksans-SemiBold',
    fontSize: '1.8vh',
  },
})

NavigationHotspot.propTypes = {
  position: PropTypes.array,
  scale: PropTypes.array,
  onClick: PropTypes.func,
  onHoverEnter: PropTypes.func,
  onHoverExit: PropTypes.func,
  content: PropTypes.object,
  debug: PropTypes.bool,
}
