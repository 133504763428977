import { useState, useEffect } from 'react'
import { useLocation } from 'wouter'
import { useAutoTour } from '../stores'

let timeout = null

function AutoStartTour() {
  const [autoStart, setAutoStart] = useState(false)
  const { setAutoTour, getTourIndex } = useAutoTour()
  const [location] = useLocation()
  const autoTourClick = useAutoTour()

  useEffect(() => {
    const unsub = useAutoTour.subscribe(
      (paw) => {
        setAutoStart(paw)
        // console.log('autoStart paw' + paw)
      },
      (state) => state.autoStartTour
    )
    return function cleanup() {
      unsub()
    }
  }, [autoStart])

  useEffect(() => {
    clearTimeout(timeout)

    if (autoStart) {
      getTourIndex(location)

      timeout = setTimeout(() => {
        setAutoTour(true)
      }, 30000)
    } else {
      if (!autoTourClick.autoTourButtonClick) setAutoTour(false)
    }
  }, [autoStart])

  return null
}

export default AutoStartTour
