import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import BrochureIcon from '../../assets/svgs/BrochureIcon'
import { useLocation } from 'wouter'
import { useHud, usePanoramaTransition } from '../../stores'
import PropTypes from 'prop-types'
import { sendEvent } from '../../../../helper/google-analytics'

function AuthUserUI({ setSlideAnim, setFScreen }) {
  const [location, setLocation] = useLocation()
  const { setFullScreen, setProfileUI } = useHud()
  const { setIsLoading } = usePanoramaTransition()

  function visitWebsite() {
    if (location != '/') {
      setLocation('/')
      setIsLoading(true)
    }

    setProfileUI(false)
    setFullScreen(false)

    setSlideAnim(false)
    setFScreen(false)
  }

  return (
    <>
      <div className={css(styles.descContainer)}>
        <div className={css(styles.desc)}>
          Established in 1976, we at Godrej Process Equipment are leaders in fabricating static
          equipment for process industries. We manufacture the entire range of custom-built critical
          process equipment for global end-users in core industries like oil &amp; gas, refineries,
          petrochemicals, chemicals, fertilizers, power, hydrogen and water across six continents in
          the world. G&amp;B’s acquisition of the YUBA® and ECOLAIRE® brands in 2018 for Feed Water
          Heaters and Surface Condensers have enabled us to expand our design-led offerings for
          process equipment in the power sector. We have two manufacturing facilities in India, at
          Vikhroli, Mumbai and Dahej, Gujarat. <br />
          <br />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontWeight: 'bold',
              fontSize: '2vh',
            }}
          >
            <div
              style={{
                lineHeight: '150%',
                fontFamily: 'WorkSans-SemiBold',
              }}
            >
              45+ Years of experience
            </div>
            <div
              style={{
                lineHeight: '150%',
                fontFamily: 'WorkSans-SemiBold',
              }}
            >
              400+ Customers served
            </div>
            <div
              style={{
                lineHeight: '150%',
                fontFamily: 'WorkSans-SemiBold',
              }}
            >
              35+ Countries served
            </div>
            <div
              style={{
                lineHeight: '150%',
                fontFamily: 'WorkSans-SemiBold',
              }}
            >
              3000+ Equipments manufactured
            </div>
          </div>
        </div>
        <div className={css(styles.infoContainer, styles.spacer)}>
          <div className={css(styles.title)}>E-MAIL</div>
          <div className={css(styles.desc, styles.spacer01)}>pedmktg@godrej.com</div>
        </div>
        <div className={css(styles.linkContainer)}>
          <a
            className={css(styles.link, styles.fonts)}
            href="https://www.godrej.com/godrej-process-equipment"
            rel="noreferrer"
            target="_blank"
            onClick={() =>
              sendEvent(`Visit Our Website clicked`, 'About Us', 'Visit Out Website Link')
            }
          >
            Visit Our Website
          </a>
        </div>
        <div className={css(styles.infoContainer, styles.spacer)}>
          <div className={css(styles.title)}>DOWNLOAD BROCHURE</div>
          <div className={css(styles.spacer01)}>
            <button className={css(styles.button)}>
              <a
                href="/pdf/GPED.pdf"
                download="Godrej Process Equipment Brochure"
                onClick={() =>
                  sendEvent(`Brochure button clicked`, 'About Us', 'Download Broucher Button')
                }
              >
                <BrochureIcon />
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AuthUserUI

AuthUserUI.propTypes = {
  setFScreen: PropTypes.func,
  setSlideAnim: PropTypes.func,
}

const styles = StyleSheet.create({
  descContainer: {
    width: '100%',
    height: '100%',
    // background: 'red',
    overflowY: 'auto',
  },
  linkContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',

    justifyContent: 'flex-start',
    marginTop: '2vh',
  },
  fonts: {
    fontSize: '1.6vh',
    fontFamily: 'WorkSans-Medium',
    color: '#ffffff',
    lineHeight: '150%',
    whiteSpace: 'nowrap',
    background: 'none',
    padding: 0,
    margin: 0,
    border: 'none',
    textTransform: 'uppercase',
    ':hover': {
      cursor: 'pointer',
    },
  },
  link: {
    textDecoration: 'none',
  },
  spacer: {
    marginTop: '5%',
  },
  spacer01: {
    marginTop: '1.8%',
  },
  desc: {
    color: '#ffffff',
    textAlign: 'justify',
    marginRight: 'auto',
    marginLeft: 'auto',
    fontSize: '1.8vh',
    lineHeight: '150%',
    fontFamily: 'WorkSans-Light',
    // background: 'red',
  },
  title: {
    color: '#ffffff',
    textAlign: 'justify',
    marginRight: 'auto',
    marginLeft: 'auto',
    fontSize: '2vh',
    lineHeight: '150%',
    fontFamily: 'WorkSans-SemiBold',
  },
  button: {
    // display: 'flex',
    // justifyContent: 'center',
    // background: '#292929',
    // border: 'none',
    // borderRadius: '0.5vw',
    // textAlign: 'center',
    // width: '4.3vh',
    // height: '4.3vh',
    // pointerEvents: 'auto',
    // ':hover': {
    //   cursor: 'pointer',
    // },
    background: '#292929',
    border: 'none',
    textAlign: 'center',
    borderRadius: '0.8vh',
    padding: 0,
    margin: 0,
    width: '5.3vh',
    height: '5.3vh',
    pointerEvents: 'auto',
    display: 'inline-flex',
    verticalAlign: 'middle',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})
