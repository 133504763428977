import React from 'react'
import GenericRightMenu from '../ui/GenericRightMenu'

function RightGridContent() {
  return (
    // onClose={closeConditionals} content={renderThis.content}
    <>
      <GenericRightMenu />
    </>
  )
}

export default RightGridContent
