import { useEffect, useState } from 'react'

import axios from 'axios'

import useLocation from 'wouter/use-location'

import { useHud, useLoader } from '../stores'

import { Route } from 'wouter'

import Fallback from './Fallback'
import { baseURL } from '../../../constants'

function ExtractUUID(urlString) {
  // console.log('token: ', urlString.split('?r=')[1])
  return urlString.split('?r=')[1]
}

function ExtractToken(magicLinkString) {
  return magicLinkString.split('?token=')[1]
}

function LinkAuthentication() {
  const [, setLocation] = useLocation()
  const { itemDoneById, startLoading } = useLoader()
  const [errorMessageString, setErrorMessageString] = useState({})
  const { setWelcomePopup } = useHud()

  let uuid = ExtractUUID(window.location.href)

  function CheckStorageForLoginData() {
    return CheckLocalData() || CheckSessionData()
  }

  function CheckLocalData() {
    const loggedInUser = localStorage.getItem('user')
    if (loggedInUser) {
      if (localStorage.getItem('user-type') === 'manager') {
        return true
      }
    } else {
      setLocation('/login')
    }
    return false
  }

  function CheckSessionData() {
    const loggedInUser = sessionStorage.getItem('user')
    if (loggedInUser) {
      if (sessionStorage.getItem('user-type') === 'manager') {
        return true
      }
    } else {
      setLocation('/login')
    }
    return false
  }

  //! Extract uuid
  const ExtractMagiclinkToken = async () => {
    return await axios({
      method: 'GET',
      url: `${baseURL}/users-permissions/redirection-url`,
      params: {
        uuid: uuid,
      },
    })
  }
  //! Magic Link
  const MagiclinkAsync = async (extractedToken) => {
    return await axios({
      method: 'GET',
      url: `${baseURL}/users-permissions/auth/local/validateToken`,
      headers: {
        Authorization: `Bearer ${extractedToken}`,

        'Content-Type': 'application/json',
      },
    })
  }

  //! if uuid present then get the token from redirection url and use validate token api
  //! if uuid is not present then redirect to login page
  useEffect(() => {
    startLoading()

    if (CheckStorageForLoginData()) {
      itemDoneById(0)
      setWelcomePopup(true)
      // setFullScreen(true)
      return setLocation('/')
    }

    if (uuid) {
      ExtractMagiclinkToken()
        .then(function (response) {
          if (response.status === 200) {
            //! console.log('uuid data: ', response.data.magic_link)
            MagiclinkAsync(ExtractToken(response.data.magic_link))
              .then(function (response) {
                if (response.status == 200) {
                  itemDoneById(0)
                  setWelcomePopup(true)
                  // setFullScreen(true)
                }

                return setLocation('/')
              })
              .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  // console.log(error.response.data)
                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  // console.log(error.request)
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message)
                }
                itemDoneById(0)
                let errorMessages = error.response
                setErrorMessageString(errorMessages)
                return setLocation('/fallback')
              })
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
          }
          itemDoneById(0)
          let errorMessages = error.response
          setErrorMessageString(errorMessages)
          return setLocation('/fallback')
        })
    } else {
      itemDoneById(0)

      return setLocation('/login')
    }
  }, [])

  return (
    <>
      <Route path="/fallback">{<Fallback errorMessage={errorMessageString} />}</Route>
    </>
  )
}

export default LinkAuthentication

/*

#region rough comments



authenticated magic link :  

http://localhost:3000?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ODUsImlhdCI6MTY0MDU5MTY4MCwiZXhwIjoxNjQzMTgzNjgwfQ.snwJI41zY6TXUUVuPkxnbjgdh2ojbkQuPM7oZ6qEQO4



manager magic link :  

http://localhost:3000?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzUsImlhdCI6MTY0Mjc1MzU5MiwiZXhwIjoxNjQ1MzQ1NTkyfQ.9TIxkiuvWEN3S5ae7S8OsJvACDhlNWZSt_NP3gATdfo


godrej link

http://localhost:3000?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTM3LCJpYXQiOjE2NDIwNjI4NzcsImV4cCI6MTY0NDY1NDg3N30.gtV_Qjn7V0skJ1LEK6qnGzV_VKibySqxytRuCAitQLw



expired link :

http://localhost:3000?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTQ2LCJpYXQiOjE2NDIyMzQ1NjIsImV4cCI6MTY0NDgyNjU2Mn0.qugSP1HihcGIeunUVJVyBIEfQsVhVxL-6rXY9GCoRHY



invlaid link :

http://localhost:3000?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTksImlhdCI6MTYzNzQ4MjM5NywiZXhwIjoxNjQwMDc0Mzk3fQ.XtF14kg1T0Z7VRDOQNQe6RD-5mu5kQwIIkHTN-cA1BM



#endregion

*/
