import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import PropTypes from 'prop-types'
import { useLocation } from 'wouter'
import { useHud, usePanoramaTransition } from '../stores'
import { sendEvent } from '../../../helper/google-analytics'

function LocationSubcategoryMenu({ subcat }) {
  const { setFullScreen, setCategoryMenu } = useHud()
  const [location, setLocation] = useLocation()
  const { setIsLoading } = usePanoramaTransition()

  return (
    <div className={css(styles.subCatContainer)}>
      <div className={css(styles.rightContainer)}>
        <div
          className={css(styles.imgContainer)}
          onClick={() => {
            setLocation(subcat.target)
            setCategoryMenu(false)
            setFullScreen(false)
            setIsLoading(true)
            sendEvent(`${subcat.name} clicked`, 'Location Categories', 'Sub Locations Card')
          }}
          style={{ pointerEvents: location != subcat.target ? 'auto' : 'none' }}
        >
          <div className={css(styles.title)}>
            {subcat.name}
            <div style={{ height: '1vh', width: '3vh', marginLeft: '1vh' }}>
              <img src="/images/categoryArrow.png" style={{ width: '100%', height: '100%' }} />
            </div>
          </div>

          <div
            className={css(styles.overlay)}
            style={{
              background: location === subcat.target ? 'rgba(11, 43, 57, 0.3)' : 'rgba(0,0,0,0.6)',
            }}
          ></div>

          <img src={subcat.imageSrc} className={css(styles.img)} />
        </div>
      </div>
    </div>
  )
}

export default LocationSubcategoryMenu

LocationSubcategoryMenu.propTypes = {
  subcat: PropTypes.object,
  // index: PropTypes.number,
}

const styles = StyleSheet.create({
  subCatContainer: {
    width: '100%',
    height: '11.57vh',
    marginBottom: '4%',
  },
  rightContainer: {
    height: '100%',
    width: '100%',
  },
  img: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'fill',
    borderRadius: '0.4vw',
  },
  title: {
    position: 'absolute',
    left: '5%',
    top: '8%',
    color: '#ffffff',
    fontSize: '1.8vh',
    fontFamily: 'WorkSans-SemiBold',
    display: 'flex',
    zIndex: 2,
  },
  imgContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    ':hover': {
      cursor: 'pointer',
    },
  },
  overlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 1,
    borderRadius: '0.4vw',
  },
  displayNone: {
    display: 'none',
  },
  displayBlock: {
    display: 'block',
  },
  locationButtonContainer: {
    position: 'absolute',
    right: '2%',
    bottom: '4%',
    zIndex: 2,
  },
  locationButton: {
    color: '#ffffff',
    fontSize: '1.8vh',
    background: '#0B2B39',
    pointerEvents: 'auto',
    border: 'none',
    padding: '1.2vh',
    borderRadius: '0.4vw',
    ':hover': {
      cursor: 'pointer',
    },
  },
})
