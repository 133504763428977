import BasicHotspot from './context/hotspot-types/BasicHotspot/BasicHotspot'
import CustomHotspotExample from './context/hotspot-types/CustomHotspotExample/CustomHotspotExample'
import BasicMeshHotspot from './context/hotspot-types/MeshHotspot/BasicMeshHotspot'
import OpenStateHotspot from './context/hotspot-types/CustomHotspotExample/OpenStateHotspot'
import PulseHotspot from './context/hotspot-types/PulseHotspot/PulseHotspot'
import HoverStateHotspot from './context/hotspot-types/CustomHotspotExample/HoverStateHotspot'
import MessageHS from './context/hotspot-types/CustomHotspotExample/MessageHS'
import FullscreenPopupHotSpot from './context/hotspot-types/BasicHotspot/FullscreenPopupHotspot'
import InfoHotspot from './context/hotspot-types/BasicHotspot/InfoHotspot'
import NavigationHotspot from './context/hotspot-types/BasicHotspot/NavigationHotspot'
import PdfHS from './context/hotspot-types/CustomHotspotExample/PdfHS'
import PdfViewerHS from './context/hotspot-types/CustomHotspotExample/PdfViewerHS'

export const hotspotTypeE = {
  Billboard: 'Billboard',
  Transform: 'Transform',
  BasicMesh: 'BasicMesh',
  Custom: 'Custom',
}

export const hotspotTypes = [
  {
    name: 'pulse',
    type: hotspotTypeE.Transform,
    component: <PulseHotspot />,
  },
  {
    name: 'billboard',
    type: hotspotTypeE.Billboard,
    component: <BasicHotspot />,
  },
  {
    name: 'basicMesh',
    type: hotspotTypeE.BasicMesh,
    component: <BasicMeshHotspot />,
  },
  {
    name: 'customx',
    type: hotspotTypeE.Custom,
    component: <CustomHotspotExample />,
  },
  {
    name: 'openStateHotspot',
    type: hotspotTypeE.Custom,
    component: <OpenStateHotspot />,
  },
  {
    name: 'hoverStateHotspot',
    type: hotspotTypeE.Custom,
    component: <HoverStateHotspot />,
  },
  {
    name: 'disclaimerPopup',
    type: hotspotTypeE.Billboard,
    component: <FullscreenPopupHotSpot />,
  },
  {
    name: 'hotspotInfo',
    type: hotspotTypeE.Custom,
    component: <InfoHotspot />,
  },
  {
    name: 'navigationHotspot',
    type: hotspotTypeE.Custom,
    component: <NavigationHotspot />,
  },
  {
    name: 'messageHS',
    type: hotspotTypeE.Custom,
    component: <MessageHS />,
  },
  {
    name: 'pdfHS',
    type: hotspotTypeE.Custom,
    component: <PdfHS />,
  },
  {
    name: 'pdfViwerHS',
    type: hotspotTypeE.Custom,
    component: <PdfViewerHS />,
  },
]

export function getHotspotByName(name) {
  for (var ind in hotspotTypes) {
    if (hotspotTypes[ind].name == name) {
      return hotspotTypes[ind]
    }
  }
}

export const hotspotBehaviours = {
  navigation: 'navigation',
  display: 'display',
}

export const displayComponents = {
  largePopup: 'largePopup',
  pdfPopup: 'pdfPopup',
  hotspotInfo: 'hotspotInfo',
  fullscreenPopup: 'fullscreenPopup',
}

/**
 * Billboard - Html Drei w/ sprite and center props active
 * HTML - Html Drei w/ transform
 */
