import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Html } from '@react-three/drei'
import { useHSContent, useHud } from '../../stores'
import { displayComponents } from '../../hotspot-types'
import PDFViewer from './PDFViewer'
import CloseIcon from '../../assets/svgs/CloseIcon'
import { fadeOut } from 'react-animations'
import { fadeIn } from 'react-animations'
import CertificateViewer from './CertificateViewer'

function HotspotFSContent() {
  const [content, setContent] = useState(null)
  const { requestedFunction, setRequestedFunction } = useHud()
  const [render, setRender] = useState(false)
  const { setHotspotContent } = useHSContent()
  const [renderImg, setRenderImg] = useState(false)
  const [renderPdf, setRenderPdf] = useState(false)

  useEffect(() => {
    if (requestedFunction != null) {
      if (requestedFunction.target === displayComponents.largePopup) setRender(true)
      if (requestedFunction.target === displayComponents.pdfPopup) setRenderPdf(true)
    }
    if (content != null) {
      setRenderImg(true)
    }
  }, [requestedFunction, content])

  function closeConditionals() {
    setRequestedFunction(null)
  }

  useEffect(() => {
    const unsub = useHSContent.subscribe(
      (paw) => {
        setContent(paw)
      },
      (state) => state.hotspotContent
    )

    return function cleanup() {
      unsub()
    }
  }, [content])

  const onAnimationEnd = () => {
    if (requestedFunction === null) setRender(false)
  }

  const AnimationEnd = () => {
    if (requestedFunction === null) setRenderPdf(false)
  }

  const onAnimEnd = () => {
    if (content === null) setRenderImg(false)
  }

  return (
    <>
      {renderImg && (
        <Html fullscreen>
          <div
            className={css(
              styles.FSContainer,
              content != null ? styles.fadeInAnim : styles.fadeOutAnim
            )}
            onAnimationEnd={onAnimEnd}
          >
            <div className={css(styles.buttonContainer)}>
              <button
                className={css(styles.button)}
                onClick={() => {
                  setContent(null)
                  setHotspotContent(null)
                }}
              >
                <CloseIcon />
              </button>
            </div>
            <div className={css(styles.ContentContainer)}>
              <img src={content} className={css(styles.content)} alt="empty" />
            </div>
          </div>
        </Html>
      )}
      {render && (
        <PDFViewer
          onClose={closeConditionals}
          content={requestedFunction != null ? requestedFunction.content : null}
          animationEnd={onAnimationEnd}
        />
      )}
      {renderPdf && (
        <CertificateViewer
          onClose={closeConditionals}
          content={requestedFunction != null ? requestedFunction.content : null}
          animationEnd={AnimationEnd}
        />
      )}
    </>
  )
}

export default HotspotFSContent

const styles = StyleSheet.create({
  // ':global(.carousel .control-dots .dot)': {
  //   backgroundColor: 'blue',
  // },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
  },
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  buttonContainer: {
    position: 'absolute',
    right: '5vh',
    top: '5vh',
  },
  button: {
    outline: 0,
    border: 0,
    background: 'none',
    height: 'auto',
    width: 'auto',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
  },
  FSContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(8px)',
    // zIndex: 1,
    pointerEvents: 'none',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ContentContainer: {
    height: '90vh',
    width: 'auto',
  },
  content: {
    width: '100%',
    height: '100%',
  },
})
