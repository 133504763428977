import React from 'react'
import { isMobileOnly } from 'react-device-detect'

function HomeIcon() {
  return (
    <svg
      style={{ width: isMobileOnly ? '4.5vh' : '3.5vh', height: isMobileOnly ? '4.9vh' : '4.5vh' }}
      //  width="32" height="30"
      viewBox="0 0 32 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.2 30H4.8C3.91634 30 3.2 29.2773 3.2 28.3859V15.4732H0L14.8672 0.473392C15.1673 0.170302 15.5745 0 15.9992 0C16.4239 0 16.8311 0.170302 17.1312 0.473392L32 15.4732H28.8V28.3859C28.8 29.2773 28.0837 30 27.2 30ZM12.8 18.7013H19.2V26.7718H25.6V13.5814L16 3.89688L6.4 13.5814V26.7718H12.8V18.7013Z"
        fill="white"
      />
    </svg>
  )
}

export default HomeIcon
