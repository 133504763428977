import { useEffect } from 'react'
import { useStatic } from './../stores'
import PropTypes from 'prop-types'
import { preloadImages } from '../../../helper/general'

export default function PanoramaPreloader({ preload }) {
  const { baseURL } = useStatic()
  useEffect(() => {
    var cleanUrls = []
    for (var index in preload) {
      cleanUrls[index] = '.' + baseURL + '/' + preload[index] + '/4x.webp'
    }
    preloadImages(cleanUrls)
  }, [preload])

  return null
}

PanoramaPreloader.propTypes = {
  preload: PropTypes.array,
}
