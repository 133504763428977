import React from 'react'
import PropTypes from 'prop-types'
import HTMLToCanvas from '../../../core/Hotspots/HTMLToCanvas'

export default function CustomHotspotExample({
  position,
  onClick,
  onHoverEnter,
  onHoverExit,
  scale,
}) {
  function OnClickCustomBehaviour() {
    if (onClick != null) onClick()
    // Add you custom Behaviour
  }
  function OnHoverEnterCustomBehaviour() {
    if (onHoverEnter != null) onHoverEnter()
    // Add you custom Behaviour
  }
  function OnHoverExitCustomBehaviour() {
    if (onHoverExit != null) onHoverExit()
    // Add you custom Behaviour
  }

  return (
    <HTMLToCanvas
      position={position}
      scale={scale}
      onClick={OnClickCustomBehaviour}
      onHoverEnter={OnHoverEnterCustomBehaviour}
      onHoverExit={OnHoverExitCustomBehaviour}
    >
      <div style={{ backgroundColor: 'green', height: 30, width: 30 }} />
    </HTMLToCanvas>
  )
}

CustomHotspotExample.propTypes = {
  position: PropTypes.array,
  scale: PropTypes.array,
  onClick: PropTypes.func,
  onHoverEnter: PropTypes.func,
  onHoverExit: PropTypes.func,
}
