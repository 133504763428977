import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'

import BG from '../../assets/images/BG.jpg'
import CloseIcon from '../../assets/svgs/CloseIcon'
import { isMobileOnly } from 'react-device-detect'
import { useHud } from '../../stores'
import { fadeIn, fadeOut, slideInRight, slideOutRight } from 'react-animations'

function Disclaimer() {
  const { setProfileUI, setDisclaimer } = useHud()
  const [slideAnim, setSlideAnim] = useState(false)
  const [fscreen, setFScreen] = useState(false)

  function slideOutAnim() {
    setSlideAnim(true)
    setFScreen(true)
    // console.log("fadeAnim")
  }

  function closeMenu() {
    setProfileUI(true)
    setDisclaimer(false)
    setSlideAnim(false)
    setFScreen(false)
  }
  function onDisabledDivWheel(event) {
    // Prevent the event from bubbling up to the document
    event.stopPropagation()
  }

  return (
    <div
      className={css(styles.fullScreenContainer, fscreen ? styles.fadeOut : styles.fadeIn)}
      // onAnimationEnd={animationEnd}
      onWheel={onDisabledDivWheel}
    >
      <div className={css(styles.profileContainer, slideAnim ? styles.slidOut : styles.slideIn)}>
        <div className={css(styles.header)}>
          <div className={css(styles.CloseButtonContainer)}>
            <button
              className={css(styles.button)}
              onClick={() => slideOutAnim(setTimeout(() => closeMenu(), 500))}
            >
              <div className={css(styles.svg)} style={{ width: isMobileOnly ? '2vw' : '1.2vw' }}>
                <CloseIcon />
              </div>
            </button>
          </div>
        </div>
        <div className={css(styles.descContainer, styles.spacer)}>
          <div className={css(styles.desc, styles.spacer)}>
            The artistic works contained in this website are in the form of a 360 degree view
            walkthrough, and are meant only for representation purposes. <br />
            <br />
            The photographs are digitally enhanced for representational purpose. The shown views are
            indicative and artistic representations only and are not to be considered a part of any
            actual deliverables. The specifications and extent/number/variety of the equipment or
            machinery displayed hereunder are indicative and are liable to change at the sole
            discretion of the company.
            <br />
            <br />
            The artistic works, images, information on this website are provided in good faith and
            on an “AS IS” basis without any warranty, express, implied, or otherwise including, but
            not limited to, warranties of merchantability, non-infringement, or fitness for any
            particular purpose. Some jurisdictions do not allow for the exclusion of implied
            warranties, so the above exclusions may not apply to you. It is your responsibility to
            evaluate the accuracy, completeness of any information provided herein. Godrej and Boyce
            Mfg. Co. Ltd. may make any other changes to the site, the materials and the products,
            programs, services or prices (if any) described in the site at any time without notice.
            <br />
            <br />
            All information contained herein is distributed with the understanding that the company,
            authors, publishers and distributors are not rendering legal or other professional
            advice or opinions on specific facts or matters and accordingly assume no liability
            whatsoever in connection with its use. In no event shall Godrej and Boyce Mfg. Co. Ltd
            and its related, affiliated, and subsidiary companies be held liable for any direct,
            indirect, special, incidental or consequential damages arising out of(i) the reliance or
            use of the information provided on this website or (ii) the access to this website or
            any weblink posted herein.
            <br />
            <br />
            While efforts are made to keep the website smooth and running, we strongly recommend
            that you perform a virus check at your end prior to accessing any weblink.
            <br />
            <br />
            These terms constitute a binding agreement between us and you regarding the reliance and
            use of this website.
          </div>
        </div>
        {/* <div className={css(styles.footer)}>
          <div className={css(styles.contentContainer)}>
            <div className={css(styles.imgContainer)}>
              <img src="/images/GodrejLogo.svg" className={css(styles.svgImg)} />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Disclaimer

const styles = StyleSheet.create({
  fullScreenContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.01)',
    backdropFilter: 'blur(3px)',
    zIndex: 1,
    pointerEvents: 'none',
    margin: 0,
  },
  fadeIn: {
    animationName: fadeIn,
    animationDuration: '1s',
  },
  fadeOut: {
    animationName: fadeOut,
    animationDuration: '1s',
  },
  slideIn: {
    animationName: slideInRight,
    animationDuration: '0.9s',
    // animationDelay: '2s',
  },
  slidOut: {
    animationName: slideOutRight,
    // animationDelay: '2s',
    animationDuration: '0.5s',
  },
  profileContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '34vw',
    backgroundImage: `url(${BG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    paddingLeft: '3%',
    paddingRight: '3%',
    paddingTop: '4%',
    paddingBottom: '4%',
    opacity: 0.9,
  },
  header: {
    border: 'none',
    paddingBottom: '3%',
    display: 'flex',
    justifyContent: 'flex-end',
    // background: 'red',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: '#ffffff',
  },
  title01: {
    fontSize: '2.5vh',
    lineHeight: '60%',
    fontFamily: 'WorkSans-Regular',
  },
  title02: {
    fontSize: '5.5vh',
    fontFamily: 'WorkSans-ExtraBold',
  },
  button: {
    background: 'none',
    border: 'none',
    height: 'auto',
    width: 'auto',
    textAlign: 'center',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
  },
  svg: {
    height: 'auto',
    // width: '1.2vw',
  },
  bodyContent: {
    marginTop: '8%',
  },
  footer: {
    position: 'absolute',
    bottom: '1.2%',
    // left: 0,
    // right: 0,
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingLeft: '-2%',
    // paddingRight: '5%',
    marginBottom: '3%',
  },
  buttonContent: {
    fontSize: '1.8vh',
    fontFamily: 'WOrkSans-Medium',
    padding: 0,
    color: '#ffffff',
  },
  imgContainer: {
    width: `${isMobileOnly ? '28vh' : '33vh'}`,
    height: 'auto',
  },
  svgImg: {
    width: '100%',
    height: 'auto',
  },
  linkContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
  fonts: {
    fontSize: '1.6vh',
    fontFamily: 'WorkSans-Light',
    color: '#ffffff',
    lineHeight: '150%',
    whiteSpace: 'nowrap',
  },
  link: {
    textDecoration: 'none',
  },
  spacer: {
    marginTop: '1%',
  },
  spacer01: {
    marginTop: '1.8%',
  },
  desc: {
    color: '#ffffff',
    textAlign: 'justify',
    marginRight: 'auto',
    marginLeft: 'auto',
    fontSize: '1.8vh',
    lineHeight: '150%',
    fontFamily: 'WorkSans-Light',
  },
  descContainer: {
    overflowY: 'auto',
    height: `${isMobileOnly ? '60vh' : '75vh'}`,
    pointerEvents: 'auto',
    paddingRight: '0.8vh',
    '::-webkit-scrollbar': {
      width: `${isMobileOnly ? '4vh' : '2.0vh'}`,
    },
    '::-webkit-scrollbar-thumb': {
      background: '#AAAAAA',
      borderRadius: '0.5vw',
      border: '5px solid transparent',
      backgroundClip: 'padding-box',
      boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.025)',
    },
    // background: 'red',
  },
})
