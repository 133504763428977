import React from 'react'
import { isMobileOnly } from 'react-device-detect'

function PauseAutoTourIcon() {
  return (
    <svg
      style={{ width: isMobileOnly ? '3.3vh' : '1.5vh', height: isMobileOnly ? '3.3vh' : '2.9vh' }}
      // width="14" height="30"
      viewBox="0 0 14 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.5 28.5L1.5 2.25" stroke="white" strokeWidth="3" strokeLinecap="round" />
      <path d="M12 28.5L12 2.25" stroke="white" strokeWidth="3" strokeLinecap="round" />
    </svg>
  )
}

export default PauseAutoTourIcon
