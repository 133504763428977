import { Html } from '@react-three/drei'
import React, { useEffect, useState } from 'react'
import { usePanoramaTransition } from '../stores'
import { css, StyleSheet } from 'aphrodite'
import { fadeIn, fadeOut } from 'react-animations'

function TransitionScreen() {
  const { isLoading } = usePanoramaTransition()
  const [fade, setFade] = useState(false)

  useEffect(() => {
    if (isLoading) {
      setFade(true)
    }
  }, [isLoading])

  const onAnimationEnd = () => {
    if (!isLoading) setFade(false)
  }

  return (
    <>
      {fade && (
        <>
          {/* {console.log('transiton' + isLoading)} */}
          <Html fullscreen style={{ pointerEvents: 'none' }}>
            <div
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                // backgroundColor: 'white',
                pointerEvents: 'none',
                display: isLoading ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className={css(isLoading ? styles.fadeInAnim : styles.fadeOutAnim)}
              onAnimationEnd={onAnimationEnd}
            >
              {/* <div
                // style={fadeAnim}
                className={css(styles.loaderContainer)}
                onAnimationEnd={onAnimationEnd}
              > */}
              <div style={{ width: '31.81vh', height: '30.41vh', position: 'relative' }}>
                {/* <img
                  src="/images/loadingSpinner.png"
                  style={{ height: 'auto', width: '100%' }}
                  className={css(styles.rotateAnim)}
                /> */}
                {/* <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '49%',
                    textAlign: 'center',
                    // background: 'red',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transform: 'translate(-45%, -50%)',
                    fontSize: '3vh',
                    fontFamily: 'WorkSans-Medium',
                    color: '#f2f2f2',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    // fontDisplay: 'fallback',
                  }}
                >
                  Loading
                </div> */}
              </div>
              {/* </div> */}
            </div>
          </Html>
        </>
      )}
    </>
  )
}

export default TransitionScreen

const rotate = {
  '0%': {
    transform: 'rotate(0deg)',
    // filter: blur(0px);
  },
  '100%': {
    transform: 'rotate(359deg)',
    // filter: blur(3px);
  },
  // '50%': {
  //   transform: 'scale(1)',
  //   // filter: blur(3px);
  // },
  // '75%': {
  //   // filter: blur(3px);
  //   transform: 'scale(1.4)',
  // },
  // '100%': {
  //   transform: 'scale(1)',
  //   // filter: blur(3px);
  // },
}

const styles = StyleSheet.create({
  loaderContainer: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    // transform: 'translateY(-40%)',
  },
  text: {
    fontSize: '3vh',
    fontFamily: 'WorkSans-Regular',
    color: '#fefefe',
  },
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '1s',
  },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '0.5s',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rotateAnim: {
    animationName: [rotate],
    animationDuration: '5s',
    animationIterationCount: 'infinite',
  },
  loaderStyle: {
    width: '2vw',
    height: '2vw',
    borderRadius: '100%',
    margin: '1.2vw',
    backgroundImage: 'linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%)',
    // animationName: [bounce],
    animationDuration: '1.5s, 0.5s',
    animationIterationCount: 'infinite',
  },
  yellow: {
    background: 'rgba(95, 178, 51, 0.7)',
  },

  red: {
    backgroundColor: '#4187CE',
    animationDelay: '0.1s',
  },

  blue: {
    backgroundColor: '#BD1362',
    animationDlay: '0.2s',
  },
})
