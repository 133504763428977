import React from 'react'
import PropTypes from 'prop-types'
import { useLoader } from '../stores'

export default function LoaderDependent({ children }) {
  const { state } = useLoader()
  return <>{state.isDone && children}</>
}

LoaderDependent.propTypes = {
  children: PropTypes.array,
}
