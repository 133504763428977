export const voiceOverSource = [
  // intro
  {
    index: 0,
    source: '/audio/ped-audio/intro/ext.mp3',
    // source: '/audio/ped-audio/nozzle/pipe.mp3',
  },
  {
    index: 1,
    source: '/audio/ped-audio/intro/safety.mp3',
  },
  {
    index: 2,
    source: '/audio/ped-audio/intro/int.mp3',
  },

  // gallery
  {
    index: 3,
    source: '/audio/ped-audio/intro/gallery.mp3',
  },
  // plate receipt
  {
    index: 4,
    source: '/audio/ped-audio/plate/plate.mp3',
  },
  // marking and cutting
  {
    index: 5,
    source: '/audio/ped-audio/mark/mark.mp3',
  },
  {
    index: 6,
    source: '/audio/ped-audio/mark/waterjet.mp3',
  },
  {
    index: 7,
    source: '/audio/ped-audio/mark/gas.mp3',
  },
  {
    index: 8,
    source: '/audio/ped-audio/mark/plasma.mp3',
  },

  // plate rolling
  {
    index: 9,
    source: '/audio/ped-audio/rolling/faccin.mp3',
  },
  {
    index: 10,
    source: '/audio/ped-audio/rolling/haueslar.mp3',
  },
  // shell
  {
    index: 11,
    source: '/audio/ped-audio/shell/circ1.mp3',
  },
  {
    index: 12,
    source: '/audio/ped-audio/shell/circ2.mp3',
  },
  {
    index: 13,
    source: '/audio/ped-audio/shell/stripCladding.mp3',
  },
  // dish end
  {
    index: 14,
    source: '/audio/ped-audio/dish-end/dish.mp3',
  },
  // assembly
  {
    index: 15,
    source: '/audio/ped-audio/assembly/assembly.mp3',
  },
  // Machine Shop
  {
    index: 16,
    source: '/audio/ped-audio/machine-shop/hartford.mp3',
  },
  {
    index: 17,
    source: '/audio/ped-audio/machine-shop/vtl.mp3',
  },
  {
    index: 18,
    source: '/audio/ped-audio/machine-shop/deep.mp3',
  },
  {
    index: 19,
    source: '/audio/ped-audio/machine-shop/skoda.mp3',
  },
  {
    index: 20,
    source: '/audio/ped-audio/machine-shop/farros.mp3',
  },
  // Nozzle Storage
  {
    index: 21,
    source: '/audio/ped-audio/nozzle/nozzle.mp3',
  },
  {
    index: 22,
    source: '/audio/ped-audio/nozzle/pipe.mp3',
  },
  {
    index: 23,
    source: '/audio/ped-audio/nozzle/welding.mp3',
  },
  {
    index: 24,
    source: '/audio/ped-audio/nozzle/idoverlay.mp3',
  },
  // Training Center
  {
    index: 25,
    source: '/audio/ped-audio/training/ground.mp3',
  },
  {
    index: 26,
    source: '/audio/ped-audio/training/booth.mp3',
  },
  // NDT
  {
    index: 27,
    source: '/audio/ped-audio/ndt/dpt.mp3',
  },
  {
    index: 28,
    source: '/audio/ped-audio/ndt/mpt.mp3',
  },
  {
    index: 29,
    source: '/audio/ped-audio/ndt/tofd.mp3',
  },
  {
    index: 30,
    source: '/audio/ped-audio/ndt/paut.mp3',
  },
  {
    index: 31,
    source: '/audio/ped-audio/ndt/ultrasonic.mp3',
  },
  {
    index: 32,
    source: '/audio/ped-audio/ndt/rtopen.mp3',
  },
  // Equipment
  {
    index: 33,
    source: '/audio/ped-audio/finished-equip/equip.mp3',
  },
  {
    index: 34,
    source: '/audio/ped-audio/finished-equip/delivery.mp3',
  },
  // Ext fab
  {
    index: 35,
    source: '/audio/ped-audio/ext-fab/extFab.mp3',
  },
  // Jetty
  {
    index: 36,
    source: '/audio/ped-audio/jetty/jetty.mp3',
  },
]
