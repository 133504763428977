import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import AccountIcon from '../../assets/svgs/AccountIcon'
import MapIcon from '../../assets/svgs/MapIcon'
import HamburgerIcon from '../../assets/svgs/HamburgerIcon'
import { cameraStore, useAutoTour, useHSContent, useHud, usePanoramaTransition } from '../../stores'
import BackButtonIcon from '../../assets/svgs/BackButtonIcon'
import { useLocation } from 'wouter'
import { isMobileOnly } from 'react-device-detect'
import MapActive from '../icons/MapActive'
import { fadeOut } from 'react-animations'
import { fadeIn } from 'react-animations'
import { displayComponents } from '../../hotspot-types'
import { sendEvent } from '../../../../helper/google-analytics'

// import { categories } from '../../location-categories'

function GenericRightMenu() {
  const {
    setMap,
    setFullScreen,
    setProfileUI,
    setCategoryMenu,
    profileUI,
    categoryMenu,
    disclaimer,
    welcomePopup,
    requestedFunction,
  } = useHud()
  const { popupUI } = useAutoTour()
  const [location, setLocation] = useLocation()
  let split = location.split('/')
  const { map } = useHud()
  const { autoTour, setAutoStartTour, setAutoTour, thankyouScreen } = useAutoTour()
  const { setIsLoading } = usePanoramaTransition()
  const cameraState = cameraStore()
  const { hotspotContent } = useHSContent()

  function onBack() {
    let length = split.length
    let final = split[0]
    for (var i = 1; i < length - 1; i++) {
      final = final + '/' + split[i]
    }
    if (final == '') {
      final = '/'
    }
    setIsLoading(true)
    setLocation(final)
  }

  return (
    <>
      {/* //! Top right menu */}
      <div
        className={css(
          styles.rightMenuContainer,
          welcomePopup ? styles.displayNone : styles.fadeAnim
        )}
        style={{
          zIndex:
            map ||
            (!profileUI &&
              !categoryMenu &&
              !disclaimer &&
              !popupUI &&
              !welcomePopup &&
              !hotspotContent &&
              !thankyouScreen &&
              !(
                requestedFunction != null &&
                requestedFunction.target === displayComponents.largePopup
              ) &&
              !(
                requestedFunction != null && requestedFunction.target === displayComponents.pdfPopup
              ))
              ? 17695054
              : 1,
          width: isMobileOnly ? '35.10vh' : '29.10vh',
        }}
      >
        <div className={css(styles.buttonContainer, styles.borderStyle)}>
          <button
            className={css(styles.button)}
            style={{
              height: isMobileOnly ? '8.1vh' : '6.1vh',
              width: isMobileOnly ? '8.1vh' : '6.1vh',
              color: '#ffffff',
            }}
            onClick={() => {
              setAutoStartTour(false)
              setAutoTour(false)
              cameraState.setRotate(false)
              if (map) {
                setMap(false)
              }
              setProfileUI(true)
              setFullScreen(true)
              sendEvent(
                `${
                  sessionStorage.getItem('user-type') === 'manager' ||
                  localStorage.getItem('user-type') === 'manager'
                    ? 'Account button clicked'
                    : 'About us button clicked '
                }`,
                'Generic Right Menu',
                `${
                  sessionStorage.getItem('user-type') === 'manager' ||
                  localStorage.getItem('user-type') === 'manager'
                    ? 'Account Button'
                    : 'About Us Button'
                }`
              )
            }}
          >
            <AccountIcon width="3.3vh" height="3.3vh" />
            {sessionStorage.getItem('user-type') === 'manager' ||
            localStorage.getItem('user-type') === 'manager'
              ? 'Account'
              : 'About us'}
          </button>
        </div>
        <div className={css(styles.buttonContainer, styles.borderStyle)}>
          <button
            className={css(styles.button)}
            style={{
              height: isMobileOnly ? '8.1vh' : '6.1vh',
              width: isMobileOnly ? '8.1vh' : '6.1vh',
              color: map ? '#0074AA' : '#ffffff',
              // fontWeight: map ? 600 : 0,
            }}
            onClick={() => {
              setAutoStartTour(false)
              setAutoTour(false)
              cameraState.setRotate(false)
              setMap(true)
              setFullScreen(true)
              sendEvent('Map button clicked', 'Generic Right Menu', 'Map Button')
            }}
          >
            {map ? <MapActive /> : <MapIcon />}
            Map
          </button>
        </div>
        <div className={css(styles.buttonContainer)}>
          <button
            className={css(styles.button)}
            style={{
              height: isMobileOnly ? '8.1vh' : '6.1vh',
              width: isMobileOnly ? '8.1vh' : '6.1vh',
              color: '#ffffff',
            }}
            onClick={() => {
              setAutoStartTour(false)
              setAutoTour(false)
              cameraState.setRotate(false)
              if (map) {
                setMap(false)
              }
              setCategoryMenu(true)
              setFullScreen(true)
              sendEvent('Locations button clicked', 'Generic Right Menu', 'Location Button')
            }}
          >
            <HamburgerIcon />
            Locations
          </button>
        </div>
      </div>
      {split.length === 3 && !autoTour && (
        <div className={css(styles.backButtonContainer)}>
          <button
            className={css(styles.backButton)}
            style={{
              // height: isMobileOnly ? '6.6vhvh' : '5.6vh',
              // width: isMobileOnly ? '8.6vh' : '12.6vh',
              color: '#ffffff',
            }}
            onClick={() => {
              onBack()
            }}
          >
            <BackButtonIcon />
            MAIN SECTION
          </button>
        </div>
      )}
    </>
  )
}

export default GenericRightMenu

const styles = StyleSheet.create({
  displayNone: {
    animationName: fadeOut,
    animationDuration: '0s',
    animationFillMode: 'forwards',
  },
  fadeAnim: {
    animationName: fadeIn,
    // animationDelay: '1s',
    animationDuration: '2s',
    animationFillMode: 'forwards',
  },
  rightMenuContainer: {
    // background: 'red',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    height: `${isMobileOnly ? '8.3vh' : '6.8vh'}`,
    background: 'rgba(0,0,0,0.7)',
    borderRadius: '0.4vw',
  },
  borderStyle: {
    borderRight: '2.5px solid #6B6B6B',
    borderLeft: 'none',
    borderBottom: 'none',
    borderTop: 'none',
  },
  buttonContainer: {
    width: '100%',
    height: '100%',
    // background: 'red',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    // paddingTop: '1%',
  },
  button: {
    background: 'none',
    border: 'none',
    textAlign: 'center',
    padding: 0,
    textTransform: 'uppercase',
    fontSize: `${isMobileOnly ? '1.5vh' : '1.2vh'}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    fontFamily: 'WorkSans-Medium',
    margin: 0,

    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
  },
  iconContainer: {
    width: '4.6vh',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  backButtonContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    textAlign: 'center',
  },
  backButton: {
    background: 'none',
    border: 'none',
    borderRadius: '0.5vw',
    textAlign: 'center',
    width: `${isMobileOnly ? '21.8vh' : '12.3vh'}`,
    height: `${isMobileOnly ? '7.8vh' : '6.3vh'}`,
    backgroundColor: 'rgba(0,0,0,0.7)',
    // paddingBottom: '1vh',
    margin: 0,
    color: '#ffffff',
    fontSize: `${isMobileOnly ? '1.5vh' : '1.2vh'}`,
    fontFamily: 'WorkSans-Medium',
    pointerEvents: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    textTransform: 'uppercase',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    ':hover': {
      cursor: 'pointer',
    },
  },
})
