import { Html } from '@react-three/drei'
import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { globalStyles } from './GlobalStyles'
import PropTypes from 'prop-types'
import { Color_Gray } from '../../../theme'
import { useLandscapeMode } from '../stores'
import { useLocation } from 'wouter'
import BGSplash from '../assets/images/BGSplash.jpg'

export default function SwitchToLandscapeLogic({ children }) {
  const { setStatus } = useLandscapeMode()
  const [location] = useLocation()
  const [disabled, setDisabled] = useState(false)

  function onResize() {
    let result = isValidScreen()
    setStatus(!result)

    // setIsDisabled(result)
    setDisabled(result)
  }
  function isValidScreen() {
    if (isMobile) {
      if (window.innerWidth > window.innerHeight) {
        return false
      } else return true
    } else return false
  }
  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()

    return function cleanup() {
      window.removeEventListener('resize', onResize)
    }
  }, [window])

  return (
    <>
      {disabled && location != '/login' && (
        <Html fullscreen style={{ zIndex: 10 }}>
          <div className={css(globalStyles.floater, styles.background)}>{children}</div>
        </Html>
      )}
    </>
  )
}

SwitchToLandscapeLogic.propTypes = {
  children: PropTypes.element,
  onOrientationChange: PropTypes.func,
}

const styles = StyleSheet.create({
  background: {
    backgroundColor: Color_Gray,
    backgroundImage: `url(${BGSplash})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
})
