import React, { useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import AutoTourIcon from '../../assets/svgs/AutoTourIcon'
import HomeIcon from '../../assets/svgs/HomeIcon'
import SoundOnIcon from '../../assets/svgs/SoundOnIcon'
import { useLocation } from 'wouter'
import { useAutoTour, useHSContent, useHud, usePanoramaTransition } from '../../stores'
import { isMobileOnly } from 'react-device-detect'
import SoundOffIcon from '../../assets/svgs/SoundOffIcon'
import HomeDisableIcon from '../../assets/svgs/HomeDisableIcon'
import PauseAutoTourIcon from '../../assets/svgs/PauseAutoTourIcon'
import { fadeOut } from 'react-animations'
import { fadeIn } from 'react-animations'
import { displayComponents } from '../../hotspot-types'
import { sendEvent } from '../../../../helper/google-analytics'

let timeout = null

function GenericBottomMenu() {
  const [location, setLocation] = useLocation()
  const {
    setAutoTour,
    autoTour,
    popupUI,
    setAutoStartTour,
    autoStartTour,
    getTourIndex,
    thankyouScreen,
  } = useAutoTour()
  const {
    setIsAudio,
    isAudio,
    map,
    profileUI,
    categoryMenu,
    disclaimer,
    setPanoramaDesc,
    welcomePopup,
    requestedFunction,
  } = useHud()
  const { setIsLoading } = usePanoramaTransition()
  const { hotspotContent } = useHSContent()
  const autoTourClick = useAutoTour()

  // play and pause auto tour. Wait for 10 secs if user is interacting with ui or screen
  function Tour() {
    getTourIndex(location)
    autoTourClick.setVOStatus(!autoTourClick.voStatus)
    if (timeout == null) {
      clearTimeout(timeout)

      if (autoStartTour) {
        // console.log('auto tour click' + autoTour)
        setAutoStartTour(false)
        autoTourClick.setAutoTourButtonClick(true)
        setAutoTour(true)
      } else {
        setAutoTour(!autoTour)
      }

      timeout = setTimeout(() => {
        timeout = null
      }, 1500)
    }
  }

  useEffect(() => {
    if (autoTour) {
      setPanoramaDesc(false)
    } else {
      setPanoramaDesc(true)
    }
  }, [autoTour])

  return (
    <div
      className={css(
        styles.centerBottomContainer,
        welcomePopup ? styles.displayNone : styles.fadeAnim
      )}
      style={{
        width: isMobileOnly ? '30.10vh' : '25.10vh',
        zIndex:
          !map &&
          !profileUI &&
          !categoryMenu &&
          !disclaimer &&
          !popupUI &&
          !welcomePopup &&
          !hotspotContent &&
          !thankyouScreen &&
          !(
            requestedFunction != null && requestedFunction.target === displayComponents.largePopup
          ) &&
          !(requestedFunction != null && requestedFunction.target === displayComponents.pdfPopup)
            ? 17695054
            : 1,
      }}
    >
      {/* Audio Button */}
      <button
        className={css(styles.button)}
        style={{
          height: isMobileOnly ? '6.6vh' : '5.6vh',
          width: isMobileOnly ? '6.6vh' : '5.6vh',
          pointerEvents: 'auto',
        }}
        onClick={() => {
          setIsAudio(!isAudio)
          sendEvent(
            `${isAudio ? 'audio playing' : 'audio muted'}`,
            'Generic Bottom UI',
            'Autotour VO & BG Audio button'
          )
        }}
      >
        {isAudio ? <SoundOnIcon /> : <SoundOffIcon />}
      </button>

      {/* Home Button */}
      <button
        className={css(styles.button)}
        style={{
          height: isMobileOnly ? '6.6vh' : '5.6vh',
          width: isMobileOnly ? '6.6vh' : '5.6vh',
          pointerEvents: location === '/' ? 'none' : 'auto',
        }}
        onClick={() => {
          setIsLoading(true)
          setLocation('/')
          setAutoStartTour(false)
          setAutoTour(false)
          sendEvent('Home button clicked', 'Generic Bottom UI', 'Home Button')
        }}
      >
        {location === '/' ? <HomeDisableIcon /> : <HomeIcon />}
      </button>

      {/* AutoTour Play Pause Button */}
      <button
        className={css(styles.button)}
        style={{
          height: isMobileOnly ? '6.6vh' : '5.6vh',
          width: isMobileOnly ? '6.6vh' : '5.6vh',
          pointerEvents: 'auto',
          // border: autoTour ? '0.3vh solid #ffffff' : 'none',
        }}
        onClick={() => {
          Tour()
          sendEvent(
            `${autoTour ? 'Autotour started' : 'Autotour paused'}`,
            'Generic Bottom UI',
            'Autotour button'
          )
        }}
      >
        {autoTour ? <PauseAutoTourIcon /> : <AutoTourIcon />}
      </button>
    </div>
  )
}

export default GenericBottomMenu

const styles = StyleSheet.create({
  displayNone: {
    animationName: fadeOut,
    animationDuration: '0s',
    animationFillMode: 'forwards',
  },
  fadeAnim: {
    animationName: fadeIn,
    // animationDelay: '1s',
    animationDuration: '2s',
    animationFillMode: 'forwards',
  },
  centerBottomContainer: {
    position: 'absolute',
    // bottom: 11,
    left: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    transform: 'translateX(-50%)',
    bottom: 0,
    // width: '30.10vh',
    // background: 'red',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'yellow',
  },
  button: {
    background: 'rgba(0,0,0,0.7)',
    border: 'none',
    textAlign: 'center',
    borderRadius: '0.4vw',
    padding: 0,
    margin: 0,
    // pointerEvents: 'auto',
    display: 'inline-flex',
    verticalAlign: 'middle',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {
      cursor: 'pointer',
    },
  },
  svgStyle: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button01: {
    background: 'rgba(0, 0, 0, 0.7)',
    border: 'none',
    textAlign: 'center',
    borderRadius: '0.4vw',
    padding: 0,
    margin: 0,
    pointerEvents: 'none',
    display: 'inline-flex',
    verticalAlign: 'middle',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainer: {
    width: '1.6vw',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})
