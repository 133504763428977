import { Html } from '@react-three/drei'
import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { fadeIn } from 'react-animations'
import { fadeOut } from 'react-animations'
import PropTypes from 'prop-types'
import { useHud } from '../../stores'
import CloseIcon from '../../assets/svgs/CloseIcon'

function CertificateViewer({ content, onClose, animationEnd }) {
  const { requestedFunction } = useHud()

  return (
    <>
      <Html fullscreen>
        <div
          className={css(
            styles.FSContainer,
            requestedFunction != null ? styles.fadeInAnim : styles.fadeOutAnim
          )}
          onAnimationEnd={animationEnd}
        >
          <div className={css(styles.ContentContainer)}>
            <object
              data={content != null && content.source}
              type="application/pdf"
              width="100%"
              height="100%"
            >
              <p>
                Your web browser does not have a PDF plugin. Instead you can
                <a href={content != null && content.source}> download the PDF file.</a>
              </p>
            </object>
          </div>
          {/* {renderPagination(page, pages)} */}
          <div className={css(styles.buttonContainer)}>
            <button className={css(styles.button)} onClick={onClose}>
              <CloseIcon />
            </button>
          </div>
        </div>
        {/* </div> */}
      </Html>
    </>
  )
}
export default CertificateViewer

CertificateViewer.propTypes = {
  content: PropTypes.object,
  onClose: PropTypes.func,
  animationEnd: PropTypes.func,
}

const styles = StyleSheet.create({
  // ':global(.carousel .control-dots .dot)': {
  //   backgroundColor: 'blue',
  // },
  pdfContent: {
    // height: '100%',
    background: 'red',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // marginTop: '4vh',
    // marginLeft: '10vh',
    // height: '120vh',
    flexDirection: 'column',
  },
  page: {
    transformOrigin: 'center',
  },
  title: {
    fontSize: '3vh',
    fontFamily: 'WorkSans-Medium',
    color: '#ffffff',
    marginBottom: '2vh',
  },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
  },
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  buttonContainer: {
    position: 'absolute',
    right: '5vh',
    top: '5vh',
  },
  btn: {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    // width: 30,
    // height: 30,
    cursor: 'pointer',
    outline: 0,
    border: 0,
    background: 'none',
    height: 'auto',
    width: 'auto',
    pointerEvents: 'auto',
    opacity: 0.5,
    ':hover': {
      opacity: 1,
    },
  },
  button: {
    outline: 0,
    border: 0,
    background: 'none',
    height: 'auto',
    width: 'auto',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
  },
  FSContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(8px)',
    // zIndex: 1,
    pointerEvents: 'none',
    textAlign: 'center',
    // margin: 0,
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
  },
  ContentContainer: {
    backgroundColor: '#282c34',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '60%',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: 'white',
    pointerEvents: 'auto',
    transform: 'translateY(15%)',
  },
  content: {
    width: '100%',
    height: '100%',
  },
})
