import React, { useState } from 'react'
import HTMLToCanvasSprite from './../../../core/Hotspots/HTMLToCanvasSprite'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite'
import { useAutoTour, useHud } from '../../../stores'
import { fadeOut } from 'react-animations'
import { fadeIn } from 'react-animations'
import { sendEvent } from '../../../../../helper/google-analytics'

export default function OpenStateHotspot({
  position,
  onClick,
  onHoverEnter,
  onHoverExit,
  scale,
  content,
  debug,
}) {
  const { setPanoramaDesc, welcomePopup } = useHud()
  // let b = 0
  const [hover, setHover] = useState(false)
  const { setAutoStartTour, setAutoTour } = useAutoTour()

  function OnClickCustomBehaviour() {
    if (onClick != null) {
      // b = tourMapConfig.findIndex((v) => v.includes(func.target))
      onClick()
      sendEvent(`Open Navigation Hotspot clicked`, 'Hotspots', 'Navigation Hotspot')
    }

    // Add you custom Behaviour
    setPanoramaDesc(true)
    setAutoStartTour(false)
    setAutoTour(false)
  }
  function OnHoverEnterCustomBehaviour() {
    // Add you custom Behaviour
    setHover(true)
    if (onHoverEnter != null) onHoverEnter() // this line is mandatory for navigation system to work
  }
  function OnHoverExitCustomBehaviour() {
    // Add you custom Behaviour
    setHover(false)
    if (onHoverExit != null) onHoverExit() // this line is mandatory for navigation system to work
  }
  return (
    <>
      <HTMLToCanvasSprite
        position={position}
        scale={scale}
        debug={debug}
        onClick={OnClickCustomBehaviour}
        onHoverEnter={OnHoverEnterCustomBehaviour}
        onHoverExit={OnHoverExitCustomBehaviour}
      >
        <>
          <div
            className={css(styles.container, welcomePopup ? styles.displayNone : styles.fadeAnim)}
          >
            <div className={css(styles.icon)}>
              <img
                src={hover ? '/images/HaverHS.svg' : '/images/NavHS.svg'}
                className={css(styles.image)}
              />
              {/* <div className={css(styles.number)}>
                {content != null ? content.index : 'Dummy Content'}
              </div> */}
              {/* <NavigateHotspotIcon /> */}
            </div>
            {/* <div className={css(styles.numberContainer)}>
              
            </div> */}
            <div
              className={css(styles.title)}
              style={{ backgroundColor: hover ? '#AA2555' : '#2F6785' }}
            >
              {content != null ? content.title : 'Dummy Content'}
            </div>
          </div>
        </>
      </HTMLToCanvasSprite>
    </>
  )
}
const styles = StyleSheet.create({
  displayNone: {
    animationName: fadeOut,
    animationDuration: '0s',
    animationFillMode: 'forwards',
  },
  fadeAnim: {
    animationName: fadeIn,
    // animationDelay: '1s',
    animationDuration: '2s',
    animationFillMode: 'forwards',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    // background: 'red',
  },
  icon: {
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    width: '2.6vw',
    height: '2.6vw',
    // background: 'red',
  },
  image: {
    height: '100%',
    width: '100%',
  },
  numberContainer: {
    position: 'absolute',
    // top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // background: 'red',
  },
  number: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // left: 5,
    color: '#ffffff',
    textAlign: 'center',
    borderRadius: '0.4vw',
    fontFamily: 'Worksans-SemiBold',
    fontSize: '2.2vh',
  },
  title: {
    cursor: 'pointer',
    padding: '1vh',
    // padding: '8%',
    whiteSpace: 'nowrap',
    color: '#ffffff',
    textAlign: 'center',
    borderRadius: '0.4vw',
    // backgroundColor: '#2F6785',
    border: '1px solid #ffffff',
    marginTop: '2%',
    fontFamily: 'Worksans-SemiBold',
    fontSize: '1.8vh',
  },
})

OpenStateHotspot.propTypes = {
  position: PropTypes.array,
  scale: PropTypes.array,
  onClick: PropTypes.func,
  onHoverEnter: PropTypes.func,
  onHoverExit: PropTypes.func,
  content: PropTypes.object,
  func: PropTypes.object,
  debug: PropTypes.bool,
  key: PropTypes.number,
}
