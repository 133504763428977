/**
 * block laptop sleep mode if Autotour running
 */
import { useEffect, useState } from 'react'
import { useAutoTour } from '../stores'

function AwakeScreen() {
  const { autoTour } = useAutoTour()
  const [wakeLock, setWakeLock] = useState(null)

  async function acquireLock() {
    setWakeLock(await navigator.wakeLock.request('screen'))
    console.log(' Wake Lock is acquired')
  }

  useEffect(() => {
    if (autoTour) {
      if ('wakeLock' in navigator) {
        console.log('wake lock api supported')
        acquireLock()
      } else {
        console.log('wake lock api not supported')
      }
    } else {
      if (wakeLock != null) {
        wakeLock.release().then(() => {
          setWakeLock(null)
          console.log('relesed lock')
        })
      }
    }
  }, [autoTour])
  return null
}

export default AwakeScreen
