import PropTypes from 'prop-types'
import { Log } from '../framework/panorama-viewer/core/CustomLogger'

export function preloadImage(url) {
  Log('Image Preloader: Loading', url)
  var img = new Image()
  img.src = url
}

preloadImage.propTypes = {
  url: PropTypes.string,
}

export function preloadImages(urlArray) {
  for (var url in urlArray) {
    preloadImage(urlArray[url])
  }
}
