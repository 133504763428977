import React, { useState } from 'react'
import FadeOut from '../context/ui/FadeOut'
import { useLoader } from '../stores'
import LoadingBar from './LoadingBar'
import LoadingScreen from './LoadingScreen'
import PropTypes from 'prop-types'
import { Html } from '@react-three/drei'

export default function Loader({ loading, done, content, loadingBar }) {
  const { state, doneAmount } = useLoader()
  const [mounted, setMounted] = useState(true)

  function unmountLoadingScreen() {
    setMounted(false)
  }

  return (
    <>
      {mounted && (
        <Html fullscreen>
          <FadeOut fade={state.isDone && !state.hasError} onFinished={unmountLoadingScreen}>
            <>
              <LoadingScreen state={state} loading={loading} done={done} content={content} />
              {loadingBar && <LoadingBar amount={doneAmount} />}
            </>
          </FadeOut>
        </Html>
      )}
    </>
  )
}

Loader.propTypes = {
  loading: PropTypes.element,
  done: PropTypes.element,
  content: PropTypes.element,
  loadingBar: PropTypes.bool,
}
