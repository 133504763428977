import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import Previous from '../../assets/svgs/Previous'
import Next from '../../assets/svgs/Next'
import { useAutoTour, useHSContent, useHud, usePanoramaTransition, useStatic } from '../../stores'
import { useLocation } from 'wouter'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { fadeIn, fadeOut } from 'react-animations'
import { displayComponents } from '../../hotspot-types'
import { sendEvent } from '../../../../helper/google-analytics'

function NextPrevLocations() {
  const { routes } = useStatic()
  const [location, setLocation] = useLocation()
  const { setAutoStartTour, setAutoTour, thankyouScreen } = useAutoTour()
  const { setIsLoading } = usePanoramaTransition()
  const [hover, setHover] = useState(false)
  const [nextHover, setNextHover] = useState(false)
  const { map, setPanoramaDesc, profileUI, categoryMenu, disclaimer, requestedFunction } = useHud()
  const [render, setRender] = useState(false)
  const [renderNext, setRenderNext] = useState(false)
  const { popupUI, autoTour } = useAutoTour()
  const [vo, setVO] = useState(false)
  const [count, setCount] = useState(0)
  const [renderProgress, setRenderProgress] = useState(false)
  const { hotspotContent } = useHSContent()

  useEffect(() => {
    const unsub = useAutoTour.subscribe(
      (paw) => {
        setVO(paw)
        // console.log('autotour paw' + paw)
      },
      (state) => state.voiceOver
    )
    const unsub2 = useAutoTour.subscribe(
      (paw) => {
        setCount(paw)
        // console.log('count useefee' + paw)
      },
      (state) => state.audioCount
    )
    return function cleanup() {
      unsub()
      unsub2()
    }
  }, [vo, count])

  useEffect(() => {
    if (hover) setRender(true)
    if (nextHover) setRenderNext(true)
    if (autoTour) setRenderProgress(true)
  }, [hover, nextHover, vo, autoTour])

  const onAnimationEnd = () => {
    if (!hover) setRender(false)
  }

  const onNextAnimationEnd = () => {
    if (!nextHover) setRenderNext(false)
  }

  const onProgressAnimationEnd = () => {
    if (!autoTour) {
      setRenderProgress(false)
    }
  }

  function changeLocation(button) {
    setAutoStartTour(false)
    setAutoTour(false)
    setPanoramaDesc(true)
    if (routes[location].nextPrevLoc != null && location != '/jetty') {
      switch (button) {
        case 'previous':
          setIsLoading(true)
          setLocation(routes[location].nextPrevLoc.prev)
          break

        case 'next':
          setIsLoading(true)
          setLocation(routes[location].nextPrevLoc.next)
          break

        default:
          break
      }
    }
  }

  return (
    <>
      {routes[location].nextPrevLoc != null && (
        <>
          <div
            className={css(styles.nextPrevContainer)}
            style={{
              zIndex:
                map ||
                (!profileUI &&
                  !categoryMenu &&
                  !disclaimer &&
                  !popupUI &&
                  !hotspotContent &&
                  !thankyouScreen &&
                  !(
                    requestedFunction != null &&
                    (requestedFunction.target === displayComponents.largePopup ||
                      requestedFunction.target === displayComponents.pdfPopup)
                  ))
                  ? 17695054
                  : 1,
            }}
          >
            <button
              className={css(styles.button)}
              onClick={() => {
                changeLocation('previous')
                sendEvent('Previous button clicked', 'Previous Location', 'Previous Button')
              }}
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}
              style={{ color: '#ffffff' }}
            >
              <Previous />
              Prev
            </button>

            <div className={css(styles.title)}>{routes[location].nextPrevLoc.currentLocName}</div>
            <button
              className={css(styles.button)}
              onClick={() => {
                changeLocation('next')
                sendEvent('Previous button clicked', 'Previous Location', 'Previous Button')
              }}
              onMouseOver={() => setNextHover(true)}
              onMouseOut={() => setNextHover(false)}
              style={{ color: location === '/jetty' ? '#767676' : '#ffffff' }}
            >
              <Next color={location === '/jetty' ? '#767676' : '#ffffff'} />
              Next
            </button>
            {renderProgress && (
              <>
                <div
                  className={css(
                    styles.progress,
                    autoTour ? styles.fadeInAnim : styles.fadeOutAnim
                  )}
                  onAnimationEnd={onProgressAnimationEnd}
                >
                  <div
                    className={css(styles.progressbar)}
                    style={{
                      width: `${count}%`,
                    }}
                  ></div>
                </div>
              </>
            )}
          </div>
          {render && !isMobile && (
            <>
              <div
                className={css(
                  styles.hoverContainer,
                  hover ? styles.fadeInAnim : styles.fadeOutAnim
                )}
                onAnimationEnd={onAnimationEnd}
                style={{ zIndex: map ? 17695054 : 1 }}
              >
                <div className={css(styles.imgContainer)}>
                  <img
                    src={routes[location].nextPrevLoc.PrevImg}
                    className={css(styles.img)}
                    width="100%"
                  />
                </div>
                <div className={css(styles.titleContainer)}>
                  {routes[location].nextPrevLoc.PrevTitle}
                </div>
              </div>
            </>
          )}
          {renderNext && !isMobile && location != '/jetty' && (
            <>
              <div
                className={css(
                  styles.NexthoverContainer,
                  nextHover ? styles.fadeInAnim : styles.fadeOutAnim
                )}
                style={{ zIndex: map ? 17695054 : 1 }}
                onAnimationEnd={onNextAnimationEnd}
              >
                <div className={css(styles.imgContainer)}>
                  <img
                    src={routes[location].nextPrevLoc.NextImg}
                    className={css(styles.img)}
                    width="100%"
                  />
                </div>
                <div className={css(styles.titleContainer)}>
                  {routes[location].nextPrevLoc.NextTitle}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default NextPrevLocations

const styles = StyleSheet.create({
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '1s',
  },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '0.5s',
  },
  progress: {
    position: 'absolute',
    top: `${isMobileOnly ? '25%' : '12%'}`,
    marginTop: '12%',
    width: '100%',
    height: '12%',
    display: 'flex',
    background: '#ffffff',
    borderRadius: '0.2vw',
  },
  progressbar: {
    height: '100%',
    backgroundColor: '#1E4F69',
    borderRadius: '0.2vw',
  },
  nextPrevContainer: {
    position: 'absolute',
    left: '50%',
    fontSize: '2.4vh',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    transform: 'translateX(-50%)',
    top: 0,
  },
  title: {
    fontSize: '75%',
    fontFamily: 'WorkSans-SemiBold',
    color: '#ffffff',
    textAlign: 'center',
    width: '20vw',
    marginRight: '2%',
    marginLeft: '2%',
    textTransform: 'uppercase',
  },
  hoverContainer: {
    marginTop: '2%',
    // height: '13.88vh',
    height: 'auto',
    width: '24.6vh',
    position: 'absolute',
    left: '12%',
    color: '#ffffff',
    top: '7%',
    borderRadius: '0.4vw',
    zIndex: 18695054,
  },
  NexthoverContainer: {
    marginTop: '2%',
    // background: 'red',
    // height: '13.88vh',
    height: 'auto',
    width: '24.6vh',
    position: 'absolute',
    top: '7.5%',
    right: '12%',
    color: '#ffffff',
    zIndex: 18695054,
    borderRadius: '0.4vw',
  },
  svgStyle: {
    width: `${isMobileOnly ? '2.0vh' : '3.3vh'}`,
    height: `${isMobileOnly ? '2.6vh' : '3.3vh'}`,
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '15vh',
    overflow: 'hidden',
  },
  img: {
    // width: '100%',
    // height: '100%',
    objecFit: 'cover',
    objectPosition: 'center',
    borderRadius: '0.4vw',
  },
  titleContainer: {
    width: '100%',
    background: 'rgba(0, 0, 0, 0.6)',
    backDropFilter: '8px',
    textTransform: 'uppercase',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    fontFamily: 'WorkSans-Bold',
    fontSize: '1.6vh',
    padding: '3%',
    borderBottomLeftRadius: '0.4vw',
    borderBottomRightRadius: '0.4vw',
  },
  button: {
    background: 'none',
    border: 'none',
    borderRadius: '0.5vw',
    textAlign: 'center',
    width: `${isMobileOnly ? '7.8vh' : '6.8vh'}`,
    height: `${isMobileOnly ? '7.8vh' : '6.8vh'}`,
    backgroundColor: 'rgba(0,0,0,0.7)',
    padding: '1.8%',
    margin: 0,
    // color: '#ffffff',
    fontSize: `${isMobileOnly ? '1.5vh' : '1.2vh'}`,
    fontFamily: 'WorkSans-Medium',
    pointerEvents: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'inherit',
    textTransform: 'uppercase',
    alignItems: 'center',
    ':hover': {
      cursor: 'pointer',
    },
  },
})
