import React from 'react'
import { css, StyleSheet } from 'aphrodite'

export default function FullscreenPopupHotSpot() {
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.hotspotContainer)}>
        <div className={css(styles.textContainer)}>
          <div className={css(styles.locText)}>FullScreen Popup</div>
        </div>
      </div>
      <div className={css(styles.imageContainer)}>
        <img
          src="/images/HotspotShape.svg"
          alt="branding_logo"
          className={css(styles.arrowImage)}
        />
      </div>
    </div>
  )
}
const styles = StyleSheet.create({
  container: {
    pointerEvents: 'auto',
    cursor: 'pointer',
    display: 'flex',
    // justifyContent: 'center'
    '@media(max-width: 576px) and (orientation:landscape)': {
      transform: 'scale(0.6)',
    },
    '@media (min-width: 576px) and (orientation:landscape)': {
      transform: 'scale(0.6)',
    },
    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px)': {
      transform: 'scale(0.6)',
    },
    // Large devices (desktops, 992px and up)
    '@media (min-width: 992px)': {
      transform: 'scale(1)',
    },

    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      transform: 'scale(1)',
    },

    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      transform: 'scale(1)',
    },
  },
  hotspotContainer: {
    height: 40,
    whiteSpace: 'nowrap',
    borderRadius: 50,
    paddingRight: 10,
    backgroundColor: '#ffffff',
    color: '#7A7A7A',
    ':hover': {
      color: 'rgb(255,255,255,1)',
      backgroundColor: '#7FB843',
    },
    '@media(min-width: 2256px)': {
      height: 65,
    },
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  imageContainer: {
    // backgroundColor: 'red',
    margin: 0,
    padding: 0,
    position: 'absolute',
    userSelect: 'none',
  },
  image: {
    height: 40,
    width: 40,
    position: 'absolute',
    borderRadius: '50%',
    border: '2px solid #ffffff',
    '@media(min-width: 2256px)': {
      height: 65,
      width: 65,
    },
  },
  arrowImage: {
    height: 47,
    width: 41,
    textAlign: 'center',
    '@media(min-width: 2256px)': {
      height: 77,
      width: 71,
    },
  },
  locText: {
    fontSize: 16,
    paddingRight: 10,
    marginLeft: 45,
    textDecoration: 'none',
    fontFamily: 'WorkSans-Bold',
    userSelect: 'none',

    '@media(min-width: 2256px)': {
      fontSize: 24,
      marginLeft: 75,
    },
  },
  locDesc: {
    textAlign: 'left',
    fontSize: 12,
    marginLeft: 45,
    paddingRight: 15,
    fontFamily: 'SourceSansPro-Regular',
  },
})
