import React, { lazy, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import CloseIcon from '../../assets/svgs/CloseIcon'
import PropTypes from 'prop-types'
import { cameraStore, useHSContent, useHud } from '../../stores'
import { fadeInDown, fadeOutUp } from 'react-animations'
import { isMobileOnly } from 'react-device-detect'
import { Carousel } from 'react-responsive-carousel/lib/js/index'
import 'react-responsive-carousel/lib/styles/carousel.css'
import InfoHSDescription from './InfoHSDescription'
import Previous from '../../assets/svgs/Previous'
import Next from '../../assets/svgs/Next'
import Fullscreen from '../icons/Fullscreen'
import { sendEvent } from '../../../../helper/google-analytics'

function HotspotInfo({ onClose, content, animationEnd }) {
  const { setDesc, setInfoIndex } = useHud()
  const { requestedFunction, setIsAudio } = useHud()
  const cameraState = cameraStore()
  const [vidPlay, setVidPlay] = useState(false)
  const { setHotspotContent } = useHSContent()

  function closeMenu() {
    onClose()
    setInfoIndex(-1)
    setDesc(false)
    cameraState.setRotate(true)
    // setPanoramaDesc(true)
  }

  function VideoPlaying() {
    setIsAudio(false)
    setVidPlay(true)
  }

  useEffect(() => {
    let vid = document.getElementById('video')
    if (vid != null) {
      if (vidPlay) {
        vid.play()
      } else {
        vid.pause()
      }
    }
  }, [vidPlay])

  return (
    <>
      <div
        className={css(
          styles.hotspotInfoContainer,
          requestedFunction != null ? styles.fadeInAnim : styles.fadeOutAnim
        )}
        onAnimationEnd={animationEnd}
        id="disableScroll"
      >
        <div className={css(styles.titleContainer)}>
          <div className={css(styles.title)}>
            {content != null ? content.title : 'DESCRIPTION GOES HERE'}
          </div>
          <div className={css(styles.buttonContainer)}>
            <button
              className={css(styles.button)}
              onClick={() => {
                closeMenu()
                sendEvent('Closed hotspot info', 'Hotspot Info Carousel', 'Close Button')
              }}
            >
              {/* <div className={css(styles.svgStyle)}> */}
              <CloseIcon />
              {/* </div> */}

              {/* <BorderCloseIcon /> */}
            </button>
          </div>
        </div>
        <Carousel
          showThumbs={false}
          autoFocus={true}
          // showIndicators={isMobileOnly ? false : true}
          showIndicators={false}
          onChange={() => {
            let dot = document.getElementById('dot')
            if (dot != null) {
              dot.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' })
            }
            if (vidPlay) {
              setVidPlay(false)
            }
          }}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            const defStyle = {
              WebkitTransition: 'opacity 0.25s ease-in',
              MozTransition: 'opacity 0.25s ease-in',
              msTransition: 'opacity 0.25s ease-in',
              OTransition: 'opacity 0.25s ease-in',
              transition: 'opacity 0.25s ease-in',
              opacity: 0.3,
              filter: 'alpha(opacity=30)',
              boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.9)',
              background: '#fff',
              borderRadius: '50%',
              width: '1vh',
              height: '1vh',
              cursor: 'pointer',
              display: 'inline-block',
              margin: '0 1vh',
            }
            const style = isSelected
              ? { ...defStyle, background: '#fff', opacity: 1 }
              : { ...defStyle }

            return (
              <div
                style={{
                  display: 'inline-flex',
                }}
              >
                <span
                  style={style}
                  onClick={() => {
                    onClickHandler()
                  }}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                  id={isSelected ? 'dot' : 'none'}
                >
                  {/* {'cust ' + index} */}
                </span>
              </div>
            )
          }}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <div
                className={css(styles.btnContainer)}
                style={{
                  left: '0vh',
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    onClickHandler()
                    sendEvent('Previous button clicked', 'Hotspot Info Carousel', 'Previous Button')
                  }}
                  className={css(styles.btn)}
                  title={label}
                >
                  <Previous />
                </button>
              </div>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <div
                className={css(styles.btnContainer)}
                style={{
                  right: '0vh',
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    onClickHandler()
                    sendEvent('Next button clicked', 'Hotspot Info Carousel', 'Next Button')
                  }}
                  title={label}
                  className={css(styles.btn)}
                >
                  <Next color={'#ffffff'} />
                </button>
              </div>
            )
          }
        >
          {content != null &&
            content.type != null &&
            content.type.map((items, index) => {
              return (
                <>
                  <div className={css(styles.thumbnailContainer)} key={index}>
                    {items.contentType === 'image' ? (
                      <>
                        {/* <div> */}

                        <img
                          className={css(styles.img)}
                          src={items.FSsource}
                          loading={lazy}
                          style={{
                            objectPosition:
                              content != null && content.title === 'Our Capabilities'
                                ? 'top'
                                : 'center',
                          }}
                        />
                        <div className={css(styles.btn01Container)}>
                          <button
                            className={css(styles.btn01)}
                            onClick={() => {
                              setHotspotContent(items.FSsource)
                              sendEvent(
                                'Fullscreen button clicked',
                                'Hotspot Info Carousel',
                                'Fullscreen Button'
                              )
                            }}
                          >
                            <Fullscreen />
                          </button>
                        </div>
                      </>
                    ) : (
                      //* Video
                      <video
                        preload="metadata"
                        className={css(styles.video)}
                        controls
                        controlsList="nodownload"
                        onPlay={() => VideoPlaying()}
                        id="video"
                      >
                        <source src={items.source} type="video/mp4"></source>
                      </video>
                    )}
                  </div>
                </>
              )
            })}
        </Carousel>
        {content != null && content.description != '' && (
          <>
            <div className={css(styles.contentContainer)}>
              <InfoHSDescription desc={content.description} />
            </div>
            {/* 
              {content != null ? content.description : 'DESCRIPTION GOES HERE'}
            </div> */}
          </>
        )}
      </div>
    </>
  )
}

export default HotspotInfo

HotspotInfo.propTypes = {
  content: PropTypes.object,
  onClose: PropTypes.func,
  animationEnd: PropTypes.func,
}

const scaleKeyframes = {
  '0%': {
    opacity: 0,
    transform: 'translate(0px,50px)',
    // height: 0,
  },

  '100%': {
    opacity: 1,
    transform: 'translate(0px, 0)',
    // height: '20vh',
  },
}

const transitionKeyframes = {
  '0%': {
    opacity: 0,
    transform: 'translate(0px,0px)',
    // height: 0,
  },

  '100%': {
    opacity: 1,
    transform: 'translate(0px, -100px)',
    // height: '20vh',
  },
}

const styles = StyleSheet.create({
  btn01: {
    height: `${isMobileOnly ? '6.8vh' : '5vh'}`,
    width: `${isMobileOnly ? '6.8vh' : '5vh'}`,
    background: '#0000008E',
    border: 0,
    outline: 'none',
    display: 'flex',
    pointerEvents: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'background 0.2s',
    ':hover': {
      background: '#000000',
    },
  },
  btn01Container: {
    position: 'absolute',
    bottom: '1.5vh',
    right: '0.5vh',
  },
  ':global(.carousel .control-dots .dot)': {
    backgroundColor: 'blue',
    zIndex: 0,
  },
  fadeOutAnim: {
    animationName: fadeOutUp,
    animationDuration: '0.5s',
  },
  fadeInAnim: {
    animationName: fadeInDown,
    animationDuration: '1s',
  },
  slideUpAnim: {
    animationName: [scaleKeyframes],
    animationDuration: '1s',
    animationTimingFunction: 'ease backwards',
  },
  slideDownAnim: {
    animationName: [transitionKeyframes],
    animationDuration: '1s',
    animationTimingFunction: 'ease backwards',
  },
  hotspotInfoContainer: {
    marginBottom: '1.2vh',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    // backdropFilter: 'blur(12px)',
    borderRadius: '0.5vw',
    pointerEvents: 'auto',
    padding: '6%',
    // paddingTop: '3%',
    // paddingLeft: '5%',
    // paddingRight: '3%',
    // width: `${isMobileOnly ? '57vh' : '38vw'}`,
    width: '38vw',
    // animationName: [scaleKeyframes],
    // animationDuration: '1s',
    // animationTimingFunction: 'ease backwards',
  },
  title: {
    fontSize: `${isMobileOnly ? '2.5vh' : '2.0vh'}`,
    color: '#ffffff',
    fontFamily: 'WorkSans-SemiBold',
    textTransform: 'uppercase',
    marginRight: '2vh',
    // background: 'red',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5%',
    // background: 'red',
  },
  closeContainer: {
    textAlign: 'center',
  },
  thumbnailContainer: {
    width: '100%',
    height: `${isMobileOnly ? '30vh' : '30vh'}`,
  },
  svgStyle: {
    width: '2.3vh',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // background: 'red',
  },
  btnContainer: {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    height: '5vh',
    width: '5vh',
    borderRadius: '50%',
    // background: 'rgba(0,0,0,0.8)',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // opacity: 0.5,
  },
  btn: {
    // width: 30,
    // height: 30,
    paddingTop: '0.8vh',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 0,
    border: 0,
    background: 'none',
    height: 'auto',
    width: 'auto',
    pointerEvents: 'auto',
  },
  buttonContainer: {
    width: '3.3vh',
    height: '3.3vh',
    marginLeft: '1vh',
    // background: 'blue',
  },
  button: {
    // background: '#2F6785',
    background: 'none',
    borderRadius: '0.3vw',
    border: 'none',
    textAlign: 'center',
    fontSize: `${isMobileOnly ? '2.5vh' : '2.0vh'}`,
    width: '100%',
    height: '100%',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ':hover': {
      cursor: 'pointer',
    },
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '0.3vw',
    objectFit: 'cover',
    // objectPosition: '50% 10%',
  },
  videoContainer: {
    display: 'flex',
    alignItemstems: 'stretch',
    justifyContent: 'center',
    maxHeight: '30vh',
    height: '30vh',
    width: '100%',
    overflow: 'hidden',
    margin: '0 auto',
  },
  video: {
    display: 'block',
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '30vh',
  },
  contentContainer: {
    color: 'white',
    fontSize: `${isMobileOnly ? '2.5vh' : '2.0vh'}`,
    textAlign: 'left',
    paddingTop: 0,
    marginTop: '2%',
    maxHeight: `${isMobileOnly ? '10vh' : '20vh'}`,
    overflowY: 'auto',
    lineHeight: '150%',
    whiteSpace: 'pre-wrap',
    fontFamily: 'WorkSans-Regular',
    '::-webkit-scrollbar': {
      width: `${isMobileOnly ? '4vh' : '2vh'}`,
    },
    '::-webkit-scrollbar-thumb': {
      background: '#2F6785',
      borderRadius: '0.5vw',
      border: '5px solid transparent',
      backgroundClip: 'padding-box',
      boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.025)',
    },
  },
})
