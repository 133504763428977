import React, { useEffect, useState } from 'react'
import { useLocation } from 'wouter'
import CenterGridContent from './context/grid-components/CenterGridContent'
import LeftGridContent from './context/grid-components/LeftGridContent'
// import NonGridContent from './context/grid-components/NonGridContent'
import RightGridContent from './context/grid-components/RightGridContent'
// import Spacer from './context/ui/Spacer'
// import TextPopupExample from './context/ui/TextPopupExample'
// import { displayComponents } from './hotspot-types'
import HudManager from './core/HudManager'
import { useHud, useStatic } from './stores'
// import GenricRightMenu from './context/ui/GenricRightMenu'

export default function Hud() {
  const [location] = useLocation()
  const { routes } = useStatic()
  const [content, setContent] = useState(null)
  const { setRequestedFunction } = useHud()

  // function closeConditionals() {
  //   setRequestedFunction(null)
  // }

  useEffect(() => {
    setRequestedFunction(null)
  }, [location])

  // useEffect(() => {
  //   const unsub = useHud.subscribe(
  //     (func) => {
  //       setRenderThis(func)
  //     },
  //     (state) => state.requestedFunction
  //   )

  //   return function cleanup() {
  //     unsub()
  //   }
  // }, [])

  useEffect(() => {
    if (location in routes) setContent(routes[location].content)
  }, [location])

  /**/
  // const [, setLocation] = useLocation()
  // let GoToLoginPage = () => {
  //   console.log(`Go to login page`)
  //   setLocation('/login')
  //   // <Route path="/about" component={About} />
  // }

  return (
    <>
      {/* The Three Division Grid System */}
      {content != null && routes[location] != null && (
        <div>
          <HudManager
            left={
              <>
                <LeftGridContent />
                {/* <Spacer height={'45%'} />

                {renderThis != null && renderThis.target === displayComponents.largePopup && (
                  <TextPopupExample onClose={closeConditionals} content={renderThis.content} />
                )}
                {renderThis == null && <Spacer height={'20%'} />} */}
              </>
            }
            center={
              <>
                <CenterGridContent />
              </>
            }
            right={
              <>
                <RightGridContent />
                {/* <GenricRightMenu /> */}
              </>
            }
          />
          {/* <NonGridContent /> */}
        </div>
      )}
      <></>
    </>
  )
}
