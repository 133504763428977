import { TransformControls, OrbitControls } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import React, { useEffect, useRef, useState } from 'react'
import { useHotspotDebugger } from '../context/debugs/debugStores'
import { LogError } from '../core/CustomLogger'
import HTMLToCanvas from '../core/Hotspots/HTMLToCanvas'
import HTMLToCanvasSprite from '../core/Hotspots/HTMLToCanvasSprite'
import { RoundToPlace } from '../helper'
import { hotspotTypeE } from '../hotspot-types'

export default function HotspotPlacementHelper() {
  const camera = useThree((state) => state.camera)
  const orbit = useRef()
  const transform = useRef()
  const [mode, setMode] = useState('translate')
  const { enabled: hostspotDebugerEnabled, selectedHotspot } = useHotspotDebugger()

  function onKeyDown(e) {
    switch (e.key) {
      case 'f':
        orbit.current.target.set(
          transform.current.worldPosition.x,
          transform.current.worldPosition.y,
          transform.current.worldPosition.z
        )
        orbit.current.update()
        break
      case 'c':
        console.log(camera)
        camera.position.set(10, 10, 10)
        orbit.current.target.set(0, 0, 0)
        orbit.current.update()
        break
      case 'w':
        setMode('translate')
        break
      case 'e':
        setMode('scale')
        break
      case 'r':
        setMode('rotate')
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (transform.current != null) transform.current.setMode(mode)
  }, [mode])

  useEffect(() => {
    if (transform.current) {
      const controls = transform.current
      const callback = (event) => (orbit.current.enabled = !event.value)
      controls.addEventListener('dragging-changed', callback)
      return () => controls.removeEventListener('dragging-changed', callback)
    }
  })

  useEffect(() => {
    if (hostspotDebugerEnabled) {
      document.addEventListener('keydown', onKeyDown)
    } else {
      document.removeEventListener('keydown', onKeyDown)
    }

    return function cleanup() {
      if (hostspotDebugerEnabled) document.removeEventListener('keydown', onKeyDown)
    }
  }, [[hostspotDebugerEnabled]])

  const ActiveHotspot = () => {
    let hotspotComponent = selectedHotspot.component
    let type = selectedHotspot.type
    if (type == hotspotTypeE.Transform) {
      return <HTMLToCanvas>{hotspotComponent}</HTMLToCanvas>
    } else if (type == hotspotTypeE.Billboard) {
      return <HTMLToCanvasSprite>{hotspotComponent}</HTMLToCanvasSprite>
    } else if (type == hotspotTypeE.Custom || type == hotspotTypeE.BasicMesh) {
      return React.cloneElement(hotspotComponent, {
        debug: true,
      })
    } else {
      LogError(
        'Hotspot Manager',
        'Hotspot type: ' +
          type +
          ' is not a valid type of hotspot, please register it in hotspot-types.js and Hotspot Manager'
      )
      return null
    }
  }

  useFrame(() => {
    let pos = document.getElementById('hotspot-helper-pos')
    let scale = document.getElementById('hotspot-helper-scale')
    let rot = document.getElementById('hotspot-helper-rot')
    if (hostspotDebugerEnabled && pos != null && scale != null && rot != null) {
      pos.innerHTML =
        RoundToPlace(transform.current.worldPosition.x, 3) +
        ',' +
        RoundToPlace(transform.current.worldPosition.y, 3) +
        ',' +
        RoundToPlace(transform.current.worldPosition.z, 3)

      scale.innerHTML =
        RoundToPlace(transform.current.worldScale.x, 3) +
        ',' +
        RoundToPlace(transform.current.worldScale.y, 3) +
        ',' +
        RoundToPlace(transform.current.worldScale.z, 3)

      rot.innerHTML =
        RoundToPlace(transform.current.worldQuaternion._x, 3) +
        ',' +
        RoundToPlace(transform.current.worldQuaternion._y, 3) +
        ',' +
        RoundToPlace(transform.current.worldQuaternion._z, 3) +
        ',' +
        RoundToPlace(transform.current.worldQuaternion._w, 3)
    }
  })
  return (
    <>
      {hostspotDebugerEnabled && (
        <>
          <TransformControls ref={transform}>
            <ActiveHotspot />
          </TransformControls>
          <OrbitControls ref={orbit} />
        </>
      )}
    </>
  )
}
