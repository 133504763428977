import './App.css'
import { Canvas } from '@react-three/fiber'
import PanoramaBuilder from './core/PanoramaBuilder'
// import Debug from './context/debugs/Debug'
import Hud from './Hud'
import SplashScreen from './core/SplashScreen'
import HotspotPlacementHelper from './debug-tools/HotspotPlacementHelper'
import CameraDebugger from './context/debugs/CameraDebugger'
import SwitchToLandscape from './context/SwitchToLandscape'
import LoaderDependent from './core/LoaderDependent'
import { activateGA } from '../../helper/google-analytics'
import { gaConfig } from '../../constants'
import LandscapeDependent from './core/LandscapeDependent'
import HudComponent from './context/HudComponent'
import AutoTour from './core/AutoTour'
import LinkAuthentication from './core/LinkAuthentication'
import { Route } from 'wouter'
import LoginPage from './core/LoginPage'
import Branding from './core/Branding'
import { Suspense, useState } from 'react'
import WelcomePopup from './context/WelcomePopup'
import BackgroundAudio from './core/BackgroundAudio'
import AutoStartTour from './core/AutoStartTour'
import HotspotFSContent from './context/ui/HotspotFSContent'
import ThankyouScreen from './context/ui/ThankyouScreen'
import AwakeScreen from './core/AwakeScreen'

activateGA(gaConfig.code)

function App() {
  const [awakeScreen, setAwakeScreen] = useState(false)

  return (
    <>
      <Canvas colorManagement dpr={[1, 1.5]} frameloop="demand">
        <Suspense fallback="null">
          <SplashScreen />
          <color attach="background" args={'#000000'} />
          <PanoramaBuilder />
          <HotspotPlacementHelper />
          <CameraDebugger />
          {/* <axesHelper /> */}
          <SwitchToLandscape />

          <HudComponent />
          <WelcomePopup />
          <HotspotFSContent />
          <ThankyouScreen />
          {/* <PDFViewer /> */}
          {/* <Stats /> */}
        </Suspense>
      </Canvas>

      <LinkAuthentication />

      <LandscapeDependent>
        <LoaderDependent>
          <AwakeScreen setAwakeScreen={setAwakeScreen} />
          <BackgroundAudio awakeScreen={awakeScreen} />
          <AutoTour />
          <AutoStartTour />
          <Branding />
          {/* <Route path="/home">{<LandingPage />}</Route> */}
          <Hud />
          {/* <Debug /> */}
          {/* <PPEPopup /> */}
          {/* <VoiceOver /> */}
        </LoaderDependent>
      </LandscapeDependent>

      <Route path="/login">{<LoginPage />}</Route>
    </>
  )
}

export default App

//#region TASKS

/**
 * FIXME:
 * P : logout not working when using magic link.
 * P : Use Store data instead of local storage for token.
 * P : Remember me
 *
 *
 *
 */

/**
 * TODO:
 * Only Manger should see the generate magic link (front end)
 * P : Fallback
 * N : Remove magic link after creating new user
 */

//#endregion
