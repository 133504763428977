import React from 'react'
import GenericBottomMenu from '../ui/GenericBottomMenu'
import Hint from '../ui/Hint'
import NextPrevLocations from '../ui/NextPrevLocations'

function CenterGridContent() {
  return (
    <>
      {/* SubcategoryDropdown Goes here */}
      <Hint />
      <NextPrevLocations />
      <GenericBottomMenu />
    </>
  )
}

export default CenterGridContent
