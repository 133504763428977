import React, { useEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { fadeIn, fadeOut } from 'react-animations'
import { useHud, usePanoramaTransition } from '../../stores'
import animationData from '../../assets/lottie/ClickAndDrag.json'
import Lottie from 'react-lottie'
import { useLocation } from 'wouter'

function Hint() {
  const { welcomePopup } = useHud()
  const [render, setRender] = useState(false)
  const [location] = useLocation()
  const { isLoading } = usePanoramaTransition()
  const [text, setText] = useState(null)
  const lottie = useRef()

  const content = {
    '/': 'Click and drag to look around',
    '/gallery': 'Turn around for more hotspots',
  }

  useEffect(() => {
    if ((location === '/' && !welcomePopup) || (location === '/gallery' && !isLoading))
      setRender(true)
    if (location === '/' && !welcomePopup) setText(content['/'])
    else if (location === '/gallery' && !isLoading) setText(content['/gallery'])
  }, [welcomePopup, location, isLoading])

  const onAnimationEnd = () => {
    if ((location === '/' && !welcomePopup) || (location === '/gallery' && !isLoading))
      setTimeout(() => {
        setRender(false)
      }, 5000)
  }

  const options = {
    // loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <>
      {render && (
        <div
          className={css(
            styles.hintContainer,
            // welcomePopup ? styles.displayNone : render ? styles.fadeOutAnim : styles.fadeInAnim
            (location === '/' && !welcomePopup) || (location === '/gallery' && !isLoading)
              ? styles.fadeInAnim
              : styles.fadeOutAnim
          )}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={css(styles.lottieContainer)}>
            <Lottie options={options} ref={lottie} />
          </div>
          <p className={css(styles.text)}>{text}</p>
        </div>
      )}
    </>
  )
}

export default Hint

const styles = StyleSheet.create({
  hintContainer: {
    position: 'absolute',
    bottom: '5vh',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  lottieContainer: {
    height: '35vw',
    width: '35vw',
    marginBottom: '-30vh',
  },
  text: {
    fontSize: '3vh',
    color: '#ffffff',
  },
  displayNone: {
    display: 'none',
  },
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '1s',
  },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '0.5s',
  },
})
